import { IntegrationType } from 'constants/integrations';
import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { MetaNoBorder, TikTokSmall } from '@innovationdepartment/proxima-ui';
import { AdPlatformSelectionViewProps } from 'types/components/audiences';
import GetEarlyAccessModal from 'ui/Modals/GetEarlyAccessModal';
import AdPlatformSelectionView from './AdPlatformSelection.View';

const AdPlatformSelection = () => {
  const [showEarlyAccessModal, setShowEarlyAccessModal] = useState(false);
  const form = useForm();
  const formState = useFormState();

  const { integrationType } = formState.values;

  const platforms: AdPlatformSelectionViewProps['platforms'] = [
    { label: 'Meta', value: IntegrationType.Facebook, icon: <MetaNoBorder /> },
    {
      label: 'TikTok',
      value: IntegrationType.TikTok,
      icon: <TikTokSmall />,
      helperText: 'Get early access',
    },
  ];

  const onSelectPlaform = (value: IntegrationType) => {
    form.change('integrationType', value);
  };

  const requestedAccessToTikTok = () => {
    setShowEarlyAccessModal(true);
  };

  return (
    <>
      <AdPlatformSelectionView
        onSelectPlatform={onSelectPlaform}
        onTikTokOptionClick={requestedAccessToTikTok}
        selectedPlatform={integrationType}
        platforms={platforms}
      />
      <GetEarlyAccessModal
        show={showEarlyAccessModal}
        onClose={() => setShowEarlyAccessModal(false)}
      />
    </>
  );
};

export default AdPlatformSelection;
