import styled from '@emotion/styled';
import { Button, Modal, Text } from '@innovationdepartment/proxima-ui';
import FacebookLogin from 'components/FacebookLogin';
import { useBrandStore } from 'stores';
import ButtonGroup from 'ui/ButtonGroup';

const TextWrapper = styled.div`
  margin: 24px 0 56px;
  max-width: 382px;
`;

type NoAvailableAdAccountsDetectedModalProps = {
  show: boolean;
  onClose: () => void;
};

const NoAvailableAdAccountsDetectedModal = (props: NoAvailableAdAccountsDetectedModalProps) => {
  const { show, onClose } = props;
  const { brand } = useBrandStore();

  return (
    <Modal
      resizable
      noPadding={false}
      open={show}
      title="No ad account detected"
      onClose={onClose}
      allowCloseOnClickOutside
    >
      <TextWrapper>
        <Text variant="body1">
          In order to use Proxima audiences, please <strong>connect your Meta ad account</strong>.
        </Text>
      </TextWrapper>
      <ButtonGroup>
        <Button
          size="small"
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          color="secondary"
        />
        <FacebookLogin
          onSuccessRedirect={`/brand/${brand.brandId}/audiences`}
          brandId={brand.brandId}
          customButtonProps={{ label: 'Connect Meta', submit: true }}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default NoAvailableAdAccountsDetectedModal;
