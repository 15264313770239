import { LabelStatus, LabelStatusProps } from '@innovationdepartment/proxima-ui';
import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';

type StatusProps = {
  status: BrandIntegrationStatus;
  type: IntegrationType;
  required?: boolean;
};

const getLabelStatusProps = (status: BrandIntegrationStatus): LabelStatusProps | undefined => {
  switch (status) {
    case BrandIntegrationStatus.Connected:
      return { variant: 'success', label: 'Connected' };
    case BrandIntegrationStatus.Disconnected:
      return { variant: 'error', label: 'Disconnected' };
    case BrandIntegrationStatus.Incomplete:
      return { variant: 'warning', label: 'Incomplete' };
    case BrandIntegrationStatus.TermsRequired:
      return { variant: 'error', label: 'Terms not accepted' };
    case BrandIntegrationStatus.NeverConnected:
      return { variant: 'default', label: 'Not connected' };
    default:
      return undefined;
  }
};

const IntegrationsModalStatus = ({ status, type, required }: StatusProps) => {
  let labelStatusProps = getLabelStatusProps(status);

  if (required) {
    if (type === IntegrationType.Facebook && status === BrandIntegrationStatus.NeverConnected) {
      labelStatusProps = { variant: 'error', label: 'Required' };
    }
  }

  if (labelStatusProps === undefined) return null;

  return <LabelStatus {...labelStatusProps} />;
};

export default IntegrationsModalStatus;
