import { useEffect, useState } from 'react';
import {
  useFacebook,
  useIntegrations,
  useKlaviyo,
  useShopify,
  useProximaSDK,
  useShowSpinner,
} from 'hooks';
import IntegrationsView from './Integrations.View';
import getIntegrationsProps from './getIntegrationsProps';
import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';

import ShopifyIntegrationModal from 'ui/Modals/ShopifyIntegrationModal';
import FacebookIntegrationModal from 'ui/Modals/FacebookIntegrationModal';
import KlaviyoIntegrationModal from 'ui/Modals/KlaviyoIntegrationModal';
import CustomerListUploadModal from 'ui/Modals/CustomerListUploadModal';

import { useBrandStore } from 'stores';
import LoadingSpinner from 'ui/LoadingSpinner';

const Integrations = () => {
  const { getMetaStatus } = useFacebook();
  const { getShopifyStatus } = useShopify();
  const { getKlaviyoStatus } = useKlaviyo();
  const { loading, initializeIntegrations } = useIntegrations();
  const brandsApi = useProximaSDK('BrandsApi');

  const [loaded, setLoaded] = useState(false);
  const [showFBModal, setShowFBModal] = useState(false);
  const [showKlaviyoModal, setShowKlaviyoModal] = useState(false);
  const [showShopifyModal, setShowShopifyModal] = useState(false);
  const [showCustomerListUploadModal, setShowCustomerListUploadModal] = useState(false);
  const [isFileUploadAllowed, setIsFileUploadAllowed] = useState<boolean>(false);

  const getStatus = (type: IntegrationType): BrandIntegrationStatus | undefined => {
    if (type === IntegrationType.Facebook) return getMetaStatus();
    if (type === IntegrationType.Shopify) return getShopifyStatus();
    if (type === IntegrationType.Klaviyo) return getKlaviyoStatus();
    return undefined;
  };

  const { brand } = useBrandStore();

  useEffect(() => {
    const getFileUploadStatus = async () => {
      const response = await brandsApi.getBrandCustomerSettings(
        { brandId: brand.brandId },
        { skipErrorToaster: true }
      );

      if (response.status === 404) return;

      setIsFileUploadAllowed(response.data.isFileUploadAllowed);
    };

    getFileUploadStatus();
  }, [brand]);

  const onOpenCardClick = (type: IntegrationType) => {
    const showModal = {
      [IntegrationType.Facebook as IntegrationType]: () => setShowFBModal(true),
      [IntegrationType.Shopify as IntegrationType]: () => setShowShopifyModal(true),
      [IntegrationType.Klaviyo as IntegrationType]: () => setShowKlaviyoModal(true),
      [IntegrationType.ManualImport as IntegrationType]: () => setShowCustomerListUploadModal(true),
    };

    showModal[type]();
  };

  const integrations = getIntegrationsProps({
    loading: false,
    onClick: onOpenCardClick,
    getStatus,
    isFileUploadAllowed,
  });

  const onCloseModal = () => {
    setShowFBModal(false);
    setShowKlaviyoModal(false);
    setShowShopifyModal(false);
    setShowCustomerListUploadModal(false);
  };

  useEffect(() => {
    async function initIntegrations() {
      await initializeIntegrations();
      setLoaded(true);
    }

    initIntegrations();
  }, []);

  useShowSpinner({ show: loading });

  if (!loaded || loading) return <LoadingSpinner />;

  return (
    <>
      <IntegrationsView integrations={integrations} />
      <FacebookIntegrationModal onClose={onCloseModal} open={showFBModal} />
      <KlaviyoIntegrationModal onClose={onCloseModal} open={showKlaviyoModal} />
      <ShopifyIntegrationModal onClose={onCloseModal} open={showShopifyModal} />
      <CustomerListUploadModal onClose={onCloseModal} open={showCustomerListUploadModal} />
    </>
  );
};

export default Integrations;
