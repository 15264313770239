import { useFormState } from 'react-final-form';
import styled from '@emotion/styled';
import { Button, Text, Divider, SpaceDivider, colors } from '@innovationdepartment/proxima-ui';

import { UserProfileViewProps } from 'types/components/userProfile';
import UserProfileInputRow from './UserProfile.InputRow';

const ContentContainer = styled.div``;

const ChangePasswordClickWrapper = styled.div`
  display: grid;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  color: ${colors.blue70};
  transition: text-shadow 1s ease-in-out;

  &:hover * {
    text-shadow: 0 0 1px ${colors.blue70};
  }
`;

const PROFILE_INPUTS = [
  {
    name: 'firstName',
    label: 'First name',
    dividerComponent: <SpaceDivider y={2} />,
  },
  {
    name: 'lastName',
    label: 'Last name',
    dividerComponent: <Divider />,
  },
  {
    name: 'email',
    label: 'Email',
    dividerComponent: <Divider />,
    disabled: true,
  },
];

const ProfileForm = (props: UserProfileViewProps) => {
  const { submitDisabled, openChangePasswordModal } = props;
  const state = useFormState();
  const { valid, dirty } = state;

  // enable if:
  // 1. changes have been made (from previous submission)
  // 2. form is valid
  // 3. form is not submitting to api
  const disableSubmit = !dirty || !valid || submitDisabled;

  const passwordInput = {
    name: 'password',
    label: 'Password',
    alternateComponent: (
      <ChangePasswordClickWrapper onClick={openChangePasswordModal}>
        <Text variant="body1">Change password</Text>
      </ChangePasswordClickWrapper>
    ),
  };

  return (
    <>
      <ContentContainer>
        {PROFILE_INPUTS.map((input) => (
          <UserProfileInputRow key={input.name} input={input} />
        ))}
        <UserProfileInputRow input={passwordInput} />
        <Divider />
        <Button size="small" label="Save changes" submit disabled={disableSubmit} />
      </ContentContainer>
    </>
  );
};

export default ProfileForm;
