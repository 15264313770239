import LogoNavbar from 'ui/SetupLayout/LogoNavbar';
import { Outlet } from 'react-router-dom';

const TermsView = () => (
  <>
    <LogoNavbar backgroundColor="white" />
    <Outlet />
  </>
);
export default TermsView;
