import BuildPlanModalContent from './BuildPlanModalContent.Container';

type BuildPlanModalViewProps = {
  brandId: string;
};

const BuildPlanModalView = ({ brandId }: BuildPlanModalViewProps) => (
  <BuildPlanModalContent brandId={brandId} />
);

export default BuildPlanModalView;
