import { SpaceDivider, Text, IntegrationsFlowInformation } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import MainContentDescription from '../IntegrationsModal/LayoutComponents/MainContentDescription';

const StepsWrapper = styled.span`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  & :first-of-type {
    width: 60px;
    font-weight: 600;
  }

  & :last-of-type {
    font-weight: 400;
  }
`;

const SetupInstructionsContent = [
  ['Step 1', 'By clicking “Get started” you will be redirected to the Shopify App Store.'],
  ['Step 2', 'Install the Proxima app on your store.'],
  ['Step 3', 'You will be redirected back here after installing the app.'],
];

const content: { title: string; description: React.ReactNode }[] = [
  {
    title: 'Connect your Shopify store',
    description: (
      <>
        Granting access to your Shopify store enables Proxima to sync data related to your
        customers, orders, and more. This data fuels Proxima&apos;s algorithm to provide you with
        the highest caliber target audiences, customer insights, and performance analysis.
        <br />
        <br />
        Data privacy, compliance, and security are our top priority. Customer data is never sold or
        shared. Integrating with your store is important to the targeting calibration process that
        will power the lookalike algorithm.
      </>
    ),
  },
  {
    title: 'Setup instructions',
    description: SetupInstructionsContent.map(([step, description]) => (
      <StepsWrapper key={step}>
        <Text variant="body2" color="neutral60">
          {step}
        </Text>
        <Text variant="body2">{description}</Text>
        <SpaceDivider />
      </StepsWrapper>
    )),
  },
];

const GraphContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const ShopifyIntegrationModalMainView = (props: { isConnected: boolean }) => {
  const { isConnected } = props;
  const contentToRender = isConnected ? content.slice(0, 1) : content;
  return (
    <>
      {contentToRender.map(({ title, description }) => (
        <MainContentDescription key={title} title={title} description={description} />
      ))}
      <GraphContainer>
        <IntegrationsFlowInformation />
      </GraphContainer>
    </>
  );
};

export default ShopifyIntegrationModalMainView;
