import {
  LookalikeAudienceTableRowProps,
  AudienceTableCellRendererProps,
  LookalikeAudienceTableRowExtras,
} from 'types/components/audiences';
import {
  AudienceRowCellDateCreated,
  AudienceRowCellLookalikeAudience,
  AudienceRowCellPlatform,
  AudienceRowCellLookalikesStatus,
} from '../Layout';

const lookalikeAudiencesRowRenderer = (
  props: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps> &
    LookalikeAudienceTableRowExtras
) => {
  const { row, header } = props;

  if (!header) return null;

  if (header === 'lookalikeAudience') return <AudienceRowCellLookalikeAudience {...props} />;

  if (header === 'platform') return <AudienceRowCellPlatform {...props} />;

  if (header === 'displayStatus') return <AudienceRowCellLookalikesStatus {...props} />;

  if (header === 'dateCreated') return <AudienceRowCellDateCreated date={row?.createdAt} />;

  return null;
};

export default lookalikeAudiencesRowRenderer;
