import React from 'react';
import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import { BillingPlanSubscription, PlanStatus } from 'types/billing';

const BillingSectionWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.neutral20};
  padding: 16px 16px 32px;
`;

const BillingSectionTitleWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.neutral10};
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const BillingSectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusOrb = styled.div<{ status: BillingPlanSubscription['status'] }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  ${({ status }) => {
    if (status === PlanStatus.Active || status === PlanStatus.Trialing)
      return 'background: #00AD78;';
    return `background: ${colors.red50}`;
  }}
`;

const StatusWrapper = styled.div`
  align-items: center;
  border: 1px solid ${colors.neutral20};
  border-radius: 14px;
  display: flex;
  gap: 8px;
  height: 28px;
  padding: 6px 12px 6px 8px;
`;

const BillingSection: React.FC<{ title: string; status: BillingPlanSubscription['status'] }> = ({
  children,
  title,
  status,
}) => {
  const isActive = status === PlanStatus.Active || status === PlanStatus.Trialing;
  const statusOrbLabel = isActive ? 'Active' : 'Inactive';

  const statusOrb =
    status !== undefined ? (
      <StatusWrapper>
        <StatusOrb status={status} />
        <span>{statusOrbLabel}</span>
      </StatusWrapper>
    ) : (
      <></>
    );

  return (
    <BillingSectionWrapper>
      <BillingSectionTitleWrapper>
        <Text variant="subhead1">{title}</Text>
        {statusOrb}
      </BillingSectionTitleWrapper>
      <BillingSectionContentWrapper>{children}</BillingSectionContentWrapper>
    </BillingSectionWrapper>
  );
};

export default BillingSection;
