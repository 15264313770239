import { useShopify } from 'hooks';
import { useFormState } from 'react-final-form';
import { GenerateAudiencesForm } from 'types/components/audiences';
import AdTargetingEnhancementView from './AdTargetingEnhancement.View';
import { ShopifyAppRedirect } from 'types/components/shopify';

const AdTargetingEnhancement = () => {
  const { getShopifyStatus, setShopifyURLRedirect } = useShopify();
  const formState = useFormState<GenerateAudiencesForm>();

  // TODO(Jenky): this only works for Shopify, need to make this dynamic if asked for in the future
  const connectToShopify = () => {
    const { integrationType, lookalikeSize } = formState.values;

    const urlParams = new window.URLSearchParams();

    urlParams.append('generateAudiences', 'true');
    urlParams.append('integrationType', integrationType);
    urlParams.append('lookalikeSize', `${lookalikeSize}`);

    setShopifyURLRedirect({
      key: ShopifyAppRedirect.Audiences,
      params: `?${urlParams.toString()}`,
    });

    window.open(
      import.meta.env.VITE_SHOPIFY_APP_LISTING_URL || 'https://apps.shopify.com/',
      '_self'
    );
  };

  const shopifyStatus = getShopifyStatus();

  return <AdTargetingEnhancementView status={shopifyStatus} connectToShopify={connectToShopify} />;
};

export default AdTargetingEnhancement;
