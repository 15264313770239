import { PropsOf } from '@emotion/react';
import { AxiosError } from 'axios';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Klaviyo, Text, useToaster } from '@innovationdepartment/proxima-ui';

import { IntegrationType } from 'constants/integrations';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';
import ConnectKlaviyoAccountView from './ConnectKlaviyoAccount.View';
import DisconnectIntegrationModal from 'ui/Modals/DisconnectIntegrationModal';
import {
  useIntegrations,
  useKlaviyo,
  usePolling,
  useProximaSDK,
  useQuery,
  useShowSpinner,
} from 'hooks';
import { Integration, IntegrationStatus } from 'types/stores/integration-store';
import { useIntegrationsStore } from 'stores';

type KlaviyoFormSchema = {
  publicKey: string;
  privateKey: string;
};

const ConnectKlaviyoTitle = () => (
  <>
    <Text variant="overline1" color="neutral60">
      Integrate
    </Text>
    <Text variant="h6">Klaviyo</Text>
  </>
);

const POLLING_TIMEOUT = 1500;
const POLL_LIMIT = 10;

const ConnectKlaviyoAccount = () => {
  const navigate = useNavigate();
  const { showToaster } = useToaster();
  const { getQueryParams } = useQuery();
  const { brandId } = useParams<'brandId'>();
  const { getKlaviyoStatus } = useKlaviyo();
  const { pollUntilCondition } = usePolling();
  const { deleteIntegration, getAllIntegrationsByType } = useIntegrations();
  const [isLoading, setIsLoading] = useState(false);
  const klaviyoApi = useProximaSDK('KlaviyoIntegrationApi');
  const integrationsApi = useProximaSDK('IntegrationsApi');

  const { addIntegration } = useIntegrationsStore();

  const { redirect, origin } = getQueryParams<'redirect' | 'origin'>();
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const backToUrl = redirect ?? `/brand/${brandId}/integrations`;

  const loading = klaviyoApi.loading || isLoading;

  const klaviyoIntegrations = getAllIntegrationsByType(IntegrationType.Klaviyo);
  /* if any expired integrations, then show that as well */
  const [klaviyoIntegration] = orderBy(
    klaviyoIntegrations.filter(({ status }) =>
      [IntegrationStatus.Active, IntegrationStatus.Expired].includes(status)
    ),
    ['status'],
    ['asc']
  );

  // we load these values ConnectKlaviyoAccountConfiguration.Container.tsx
  const initialValues: KlaviyoFormSchema = {
    publicKey: '',
    privateKey: '',
  };

  type FormProps = PropsOf<typeof Form<KlaviyoFormSchema>>;
  type SchemaErrors = { [key in keyof KlaviyoFormSchema]?: string };
  const onValidate: FormProps['validate'] = (formValues: KlaviyoFormSchema) => {
    const errors: SchemaErrors = {};

    if (!formValues.publicKey) errors.publicKey = 'Public key is required';
    if (!formValues.privateKey) errors.privateKey = 'Private key is required';

    return errors;
  };

  const pollForNewKlaviyoIntegration = async () => {
    setIsLoading(true);
    let pollCount = 0;
    let stopPolling = false;

    /** fetch and load into integrations store if found */
    const fetchForIntegrations = async () => {
      let error = false;
      try {
        const { data } = await integrationsApi.getIntegrationsHistoryForBrand({
          brandId: brandId as string,
        });
        const newIntegration = data.find(
          (integration) =>
            (integration.type as string) === IntegrationType.Klaviyo &&
            integration.status === IntegrationStatus.Active
        );

        if (newIntegration) {
          addIntegration(newIntegration as Integration);
          stopPolling = true;
        }

        if (pollCount >= POLL_LIMIT) {
          stopPolling = true;
          error = true;
        }
        pollCount += 1;
      } catch (err) {
        /** */
      }

      if (error) {
        showToaster({
          message: 'Something happened while trying to connect your account',
          variant: 'error',
        });
      }
    };

    await pollUntilCondition({
      cb: fetchForIntegrations,
      condition: () => stopPolling,
      timeout: POLLING_TIMEOUT,
    })();

    setIsLoading(false);

    showToaster({
      message: 'Your account is connected',
      variant: 'success',
    });
  };

  const onSubmit: FormProps['onSubmit'] = async (formValues: KlaviyoFormSchema, formApi) => {
    const errors: SchemaErrors = {};

    if (!brandId) return errors;

    try {
      await klaviyoApi.createKlaviyoIntegration({
        brandId,
        createKlaviyoIntegrationInput: {
          apiKey: formValues.privateKey.trim(),
          klaviyoAccountId: formValues.publicKey.trim(),
        },
      });

      await pollForNewKlaviyoIntegration();

      /* lets reset form with new keys, that way we can track when changes have been made after submission */
      formApi.restart({
        publicKey: formValues.publicKey.trim(),
        privateKey: formValues.privateKey.trim(),
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        const { response } = err;
        const data = (response?.data ?? []) as [{ path: string; message: string }];

        data.forEach((error) => {
          if (error.path === 'apiKey') errors.privateKey = error.message;
          if (error.path === 'klaviyoAccountId') errors.publicKey = error.message;
        });
      }
    }

    return errors;
  };

  const onClose = async () => {
    let redirectTo = backToUrl;

    if (origin === 'onboarding') {
      redirectTo = `/brand/${brandId}/onboarding`;
    }

    navigate(redirectTo);
  };

  const closeDisconnectModal = () => setOpenDisconnect(false);

  const onDisconnectIntegration = async () => {
    if (klaviyoIntegration) {
      setIsLoading(true);
      try {
        await deleteIntegration(klaviyoIntegration.integrationId);
      } catch (err) {
        setIsLoading(false);
        closeDisconnectModal();
        throw err;
      }
    }
    setIsLoading(false);
    closeDisconnectModal();
  };

  const onLoadToken = async () => {
    if (!brandId) return initialValues.privateKey;

    if (klaviyoIntegration) {
      try {
        const { data } = await klaviyoApi.getMaskedKlaviyoToken(
          {
            brandId,
            integrationId: klaviyoIntegration.integrationId,
          },
          { skipErrorToaster: true }
        );
        return data.token ?? initialValues.privateKey;
      } catch (err) {
        if (klaviyoIntegration.status === IntegrationStatus.Expired) {
          /* when it enters here, it means account has been set as expired/not found with valid api key */
          return '*************************************';
        }
      }
    }

    return initialValues.privateKey;
  };

  useShowSpinner({ show: loading });

  return (
    <>
      <IntegrationsModal
        open
        full
        icon={<Klaviyo />}
        onClose={onClose}
        title={<ConnectKlaviyoTitle />}
      >
        <Form initialValues={initialValues} onSubmit={onSubmit} validate={onValidate}>
          <ConnectKlaviyoAccountView
            onLoadToken={onLoadToken}
            loading={loading}
            onClose={onClose}
            integration={klaviyoIntegration}
            onDisconnect={() => setOpenDisconnect(true)}
            status={getKlaviyoStatus()}
          />
        </Form>
      </IntegrationsModal>
      <DisconnectIntegrationModal
        type={IntegrationType.Klaviyo}
        close={closeDisconnectModal}
        confirm={onDisconnectIntegration}
        open={openDisconnect}
      />
    </>
  );
};

export default ConnectKlaviyoAccount;
