import { ROUTES_WITHOUT_NAVBAR, ROUTES_WITH_BLUEGREEN_BACKDROP } from 'components/App/constants';
import { useLocation } from 'react-router-dom';

const useBackgroundConditions = () => {
  const { pathname } = useLocation();
  const showBackdrop = ROUTES_WITH_BLUEGREEN_BACKDROP.includes(pathname);
  const skipNavbar =
    ROUTES_WITHOUT_NAVBAR.some(
      (route) =>
        pathname
          .split('/') // split the pathname into an array of paths
          .slice(1) // remove the first empty string (since all paths start with '/')
          .some((path) => path === route.slice(1)) // check if any of the paths match the route
    ) || ROUTES_WITHOUT_NAVBAR.includes(pathname); // fallback to checking if the pathname is in the list of routes;

  return {
    skipNavbar,
    showBackdrop,
  };
};

export default useBackgroundConditions;
