import Layout from '../Terms.Layout';

const UseView = () => (
  <Layout>
    <h2>Innovation Brands Corp.</h2>
    <h2>Terms of Use</h2>

    <p>Last Updated: July 14th, 2022</p>

    <p>
      Welcome to Proxima! Please read on to learn the rules and restrictions that govern your use of
      our website(s), products, services and applications, including without limitations our
      partnership marketing, sweepstakes, media transactions, and audience engagement tools and
      services offered through{' '}
      <a href="https://www.proxima.ai" target="_blank" rel="noreferrer">
        www.proxima.ai
      </a>{' '}
      (the &quot;Services&quot;). If you have any questions, comments or concerns regarding these
      terms or the Services, please contact us at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a>
    </p>

    <p>
      These Terms of Use (the &quot;Terms&quot;) are a binding contract between you and Innovation
      Brands Corp, its parent companies, successors and assigns (&quot;Proxima&quot;,
      &quot;Company&quot;, &quot;we&quot;, &quot;our&quot; and &quot;us&quot;). BY USING THE
      SERVICES, CLICKING ON THE &ldquo;I ACCEPT&rdquo; OR &ldquo;GET STARTED&rdquo; BUTTON OR OTHER
      MECHANISM PROVIDED, COMPLETING YOUR ACCOUNT REGISTRATION, AND/OR BROWSING THE WEBSITE, YOU
      REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS, (2) YOU ARE
      OF LEGAL AGE TO FORM A BINDING CONTRACT WITH PROXIMA, AND (3) YOU HAVE THE AUTHORITY TO ENTER
      INTO THE TERMS PERSONALLY OR ON BEHALF OF THE INDIVIDUAL NAMED AS THE USER AT THE TIME OF
      REGISTRATION, OR IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR
      ORGANIZATION (&ldquo;YOUR ORGANIZATION&rdquo;), YOU HAVE THE AUTHORITY TO BIND YOUR
      ORGANIZATION TO THE TERMS (AND, IN WHICH CASE, THE TERMS &ldquo;YOU&rdquo; AND
      &ldquo;YOUR&rdquo; OR &ldquo;CUSTOMER&rdquo; WILL REFER TO YOUR ORGANIZATION).{' '}
      <b>
        IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE
        SERVICES.
      </b>
    </p>

    <p>
      Your use of the Services in any way means that you agree to all of these Terms, and these
      Terms will remain in effect while you use the Services. These Terms include the provisions in
      this document, as well as those in the{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="/terms/copyright" target="_blank" rel="noreferrer">
        Copyright Dispute Policy
      </a>
    </p>

    <h3>A. Modification to these Terms</h3>

    <p>
      We are constantly trying to improve our Services, so these Terms may need to change along with
      the Services. We reserve the right to change the Terms at any time, but if we do, we will
      bring it to your attention by placing a notice on the{' '}
      <a href="https://www.proxima.ai" target="_blank" rel="noreferrer">
        www.proxima.ai
      </a>{' '}
      website, by sending you an email, and/or by some other means.
    </p>

    <p>
      If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that
      means you will no longer be able to use the Services. If you use the Services in any way after
      a change to the Terms is effective, that means you agree to all of the changes.
    </p>

    <p>
      Except for changes by us as described here, no other amendment or modification of these Terms
      will be effective unless in writing and signed by both you and us.
    </p>

    <h3>B. Privacy</h3>

    <p>
      We take the privacy of our users very seriously. For the current Privacy Policy, please click{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer">
        here
      </a>
    </p>

    <h3>C. Eligibility</h3>

    <p>
      In order to use the Services, you may be required to sign up for an account, and select a
      password and user name. You promise to provide us with true, complete, and up to date contact
      and registration information about yourself. You may not select as your username a name that
      you don&rsquo;t have the right to use, or another person&rsquo;s name with the intent to
      impersonate that person. You may not transfer your account to anyone else without our prior
      written permission. You are solely responsible for keeping your account name and password
      confidential and for protecting the security thereof. You will not share your account name or
      password with anyone, unless it is an authorized employee within your organization.
      You&rsquo;re responsible for any activity associated with your account. You&rsquo;ll
      immediately notify us of any unauthorized use of your account(s). We are not responsible for
      any losses due to stolen or hacked passwords. You acknowledge that we do not have access to
      your current password, and may only reset your password at your request.
    </p>

    <p>
      If you connect to a third-party account, such as Stripe or a social media network (each such
      account, a &ldquo;Third-Party Account&rdquo;), you understand that Proxima may access, make
      available and store (if applicable) any information, data, other materials, including, but not
      limited to, your name and email address as well as your subscribers&rsquo; information,
      including name, email address, purchase history, transaction value, product details, and
      shipping details that you have provided to and stored in your Third-Party Account
      (&ldquo;Third-Party Account Content&rdquo;). Unless otherwise specified in these Terms, all
      Third-Party Account Content shall be considered to be Your Content (as defined below) for all
      purposes of these Terms. Depending on the Third-Party Accounts you choose and subject to the
      privacy settings that you have set in such Third-Party Accounts, personally identifiable
      information that you post to your Third-Party Accounts may be available to us. Please note
      that if a Third-Party Account or associated service becomes unavailable or Proxima&rsquo;s
      access to such Third-Party Account is terminated by the third-party service provider, then
      Third-Party Account Content will no longer be available on and through the Services. You have
      the ability to disable the connection between us and your Third-Party Accounts at any time by
      adjusting your account settings on the applicable Third-Party Account. PLEASE NOTE THAT YOUR
      RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS
      IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND PROXIMA
      DISCLAIMS ANY LIABILITY FOR PERSONALLY IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT BY
      SUCH THIRD-PARTY SERVICE PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET IN
      SUCH THIRD-PARTY ACCOUNTS. Proxima makes no effort to review any Third-Party Account Content
      for any purpose, including but not limited to, for accuracy, legality or noninfringement, and
      Proxima is not responsible for any Third-Party Account Content.
    </p>

    <p>
      You represent and warrant that you are an individual of legal age to form a binding contract
      (or if not, you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use
      the Services and gotten your parent or guardian to agree to these Terms on your behalf). If
      you are agreeing to these Terms on behalf of a company or other entity, you represent and
      warrant that you have the authority to agree to and accept these Terms on that company or
      other entity&rsquo;s behalf and bind them to these Terms (in which case, the references to
      &ldquo;you&rdquo; and &ldquo;your&rdquo; in these Terms, except in this sentence, refer to
      that organization or entity).
    </p>

    <p>
      You represent and warrant that you will use the Services only in accordance with these Terms
      and in a manner that complies with any and all federal, state or local laws and regulations
      that apply to you. If your use of the Services is prohibited by applicable laws, then you
      aren&rsquo;t authorized to use the Services. We can&rsquo;t and won&rsquo;t be responsible for
      your use of the Services in any way that breaks the law.
    </p>

    <p>Your use of the Services is subject to the following additional restrictions:</p>

    <p>
      You represent, warrant, and agree that you will not contribute any Content or User Submission
      or otherwise use the Services or interact with the Services in a manner that:
    </p>

    <ol>
      <li>
        <p>
          Infringes or violates the intellectual property rights or any other rights of anyone else
          (including ours);
        </p>
      </li>

      <li>
        <p>
          Violates any law or regulation, including any applicable data protection and export
          control laws;
        </p>
      </li>

      <li>
        <p>
          Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
          otherwise objectionable;
        </p>
      </li>

      <li>
        <p>
          Jeopardizes the security of your account or anyone else&rsquo;s (such as allowing an
          unauthorized person to log in to the Services as you);
        </p>
      </li>

      <li>
        <p>
          Attempts, in any manner, to obtain the password, account, or other security information
          from any other user;
        </p>
      </li>

      <li>
        <p>
          Violates the security of any computer network, or cracks any passwords or security
          encryption codes;
        </p>
      </li>

      <li>
        <p>
          Runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services,
          or any processes that run or are activated while you are not logged into the Services, or
          that otherwise interfere with the proper working of the Services (including by placing an
          unreasonable load on the Services&rsquo; infrastructure);
        </p>
      </li>

      <li>
        <p>
          &ldquo;Crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or
          portion of or relating to the Services or Content (through use of manual or automated
          means);
        </p>
      </li>

      <li>
        <p>Copies or stores any significant portion of the Content;</p>
      </li>

      <li>
        <p>
          Decompiles, reverse engineers, or otherwise attempts to obtain the source code or
          underlying ideas or information of or relating to the Services.
        </p>
      </li>
    </ol>

    <p>
      By submitting any Content or personal information related to any subscriber or other person
      through the Services, including without limitations to operate a sweepstakes campaign
      (collectively, the &ldquo;Subscriber Information&rdquo;), you represent, warrant, and agree
      that: (y) any and all information has been collected in accordance with any applicable laws,
      rules or regulations, and (z) you have obtained all third-party licenses, consents and
      permissions needed for Proxima to use the Subscriber Information in accordance with the terms
      herein. You hereby indemnify and hold harmless Proxima, and all of its officers, directors,
      shareholders, employees and agents, with respect to any claim, suit or liability (including
      attorney&rsquo;s fees and related costs), arising from the collection, use and disclosure of
      such Subscriber Information.
    </p>

    <p>
      You further represent, warrant, and agree that when using the Services or any information
      collected through the Services to send text messages, email messages or other communications
      to third parties (collectively, &ldquo;Messages&rdquo;), you will: (i) comply with all
      applicable federal, state, local and foreign laws, rules and regulations governing such
      Messages, including, without limitation, the Telephone Consumer Protection Act (47 U.S.C.
      &sect; 227) and its implementing rules and regulations (47 C.F.R. &sect; 64.1200), the
      Telemarketing and Consumer Fraud and Abuse Prevention Act (15 U.S.C. &sect;&sect; 6101-6108),
      the Federal Trade Commission&rsquo;s Telemarketing Sales Rule (16 CFR &sect; 310), the
      CAN-SPAM Act of 2013 (15 U.S.C. &sect;&sect; 7701-7713), and state and local equivalents; (ii)
      send Messages only to individuals from whom you have obtained the legally required consent to
      do so. Further, for purposes of these Terms, you will be deemed to be the sender of all
      Messages delivered through the Services even if Proxima or its subcontractors perform the
      technical steps necessary to distribute such Messages on your behalf. You also agree that you
      will be solely liable and responsible for the Messages and Message content sent through the
      Services notwithstanding that Proxima may offer advice, guidance or templates relating
      thereto. Proxima may in its sole discretion refuse to distribute Messages where the content of
      the Message or the delivery of such Message to the recipient, appears to be unlawful in
      Proxima&rsquo;s reasonable judgment. Notwithstanding the foregoing, Proxima has no obligation
      to review the content of any Messages or recipient addresses.
    </p>

    <p>
      A violation of any of the foregoing is grounds for termination of your right to use or access
      the Services.
    </p>

    <h3>D. Payments and Billing</h3>

    <ol>
      <li>
        <p>
          <u>Paid Services.</u> Certain of our Services may be subject to payments now or in the
          future (the &ldquo;Paid Services&rdquo;). Please note that any payment terms presented to
          you in the process of using or signing up for a Paid Service are deemed part of this
          Agreement.
        </p>
      </li>

      <li>
        <p>
          <u>Billing.</u> We use a third-party payment processor (the &ldquo;Payment
          Processor&rdquo;) to bill you through a payment account linked to your account on the
          Services (your &ldquo;Billing Account&rdquo;) for use of the Paid Services. The processing
          of payments will be subject to the terms, conditions and privacy policies of the Payment
          Processor in addition to these Terms. We are not responsible for error by the Payment
          Processor. By choosing to use Paid Services, you agree to pay us, through the Payment
          Processor, all charges at the prices then in effect for any use of such Paid Services in
          accordance with the applicable payment terms and you authorize us, through the Payment
          Processor, to charge your chosen payment provider (your &ldquo;Payment Method&rdquo;). You
          agree to make payment using that selected Payment Method. We reserve the right to correct
          any errors or mistakes that it makes even if it has already requested or received payment.
        </p>
      </li>

      <li>
        <p>
          <u>Payment Method.</u> The terms of your payment will be based on your Payment Method and
          may be determined by agreements between you and the financial institution, credit card
          issuer or other provider of your chosen Payment Method. If we, through the Payment
          Processor, do not receive payment from you, you agree to pay all amounts due on your
          Billing Account upon demand.
        </p>
      </li>

      <li>
        <p>
          <u>Recurring Billing.</u> Some of the Paid Services may consist of an initial period, for
          which there is a one-time charge, followed by recurring period charges as agreed to by
          you. By choosing a recurring payment plan, you acknowledge that such Services have an
          initial and recurring payment feature and you accept responsibility for all recurring
          charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT
          FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS
          CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION. TO TERMINATE YOUR
          AUTHORIZATION, EMAIL <a href="mailto:support@proxima.ai">support@proxima.ai</a>. ONCE WE
          HAVE CONFIRMED RECEIPT OF YOUR TERMINATION, YOU WILL RECEIVE SERVICES FOR THE REMAINDER OF
          THE BILLING PERIOD, AND WILL RECEIVE NO FURTHER RECURRING CHARGES. CHANGES TO PAYMENT
          METHOD AND TERMINATIONS WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT.
        </p>
      </li>

      <li>
        <p>
          <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE
          INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP
          YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS,
          CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR
          OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF
          YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR
          USE OF YOUR USER NAME OR PASSWORD. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING
          INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES
          UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH
          ABOVE.
        </p>
      </li>

      <li>
        <p>
          <u>Change in Amount Authorized.</u> If the amount to be charged to your Billing Account
          varies from the amount you preauthorized (other than due to the imposition or change in
          the amount of state sales taxes), you have the right to receive, and we shall provide,
          notice of the amount to be charged and the date of the charge before the scheduled date of
          the transaction. Any agreement you have with your payment provider will govern your use of
          your Payment Method. You agree that we may accumulate charges incurred and submit them as
          one or more aggregate charges during or at the end of each billing cycle.
        </p>
      </li>

      <li>
        <p>
          <u>Auto-Renewal for Subscription Services.</u> Unless you opt out of auto-renewal, through
          email request to <a href="mailto:support@proxima.ai">support@proxima.ai</a>, any Paid
          Services you have signed up for will be automatically extended for successive renewal
          periods of the same duration as the subscription term originally selected, at the
          then-current non-promotional rate. To change or resign your Paid Services at any time,
          email notice to <a href="mailto:support@proxima.ai">support@proxima.ai</a>. If you
          terminate a Paid Service, you may use your subscription until the end of your then-current
          term; your subscription will not be renewed after your then-current term expires. However,
          you won&rsquo;t be eligible for a prorated refund of any portion of the subscription fee
          paid for the then-current subscription period.
        </p>
      </li>

      <li>
        <p>
          <u>Reaffirmation of Authorization.</u> Your non-termination or continued use of a Paid
          Service reaffirms that we are authorized to charge your Payment Method for that Paid
          Service. We may submit those charges for payment and you will be responsible for such
          charges. This does not waive our right to seek payment directly from you. Your charges may
          be payable in advance, in arrears, per usage, or as otherwise described when you initially
          selected to use the Paid Service.
        </p>
      </li>

      <li>
        <p>
          <u>Free Trials and Other Promotions.</u> Any free trial or other promotion that provides
          access to a Paid Service must be used within the specified time of the trial. You must
          stop using a Paid Service before the end of the trial period in order to avoid being
          charged for that Paid Service. If you cancel prior to the end of the trial period and are
          inadvertently charged for a Paid Service, please contact us at{' '}
          <a href="mailto:support@proxima.ai">support@proxima.ai</a>
        </p>
      </li>
    </ol>

    <h3>E. Rights and Licenses</h3>

    <p>
      The materials displayed or performed or available on or through the Services, including
      without limitation, text, graphics, data, articles, photos, images, illustrations, User
      Content, and so forth (all of the foregoing, the &ldquo;Content&rdquo;) are protected by
      copyright and/or other intellectual property laws. You promise to abide by all copyright
      notices, trademark rules, information, and restrictions contained in any Content you access
      through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish,
      broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit
      for any purpose any Content not owned by you, (i) without the prior consent of the owner of
      that Content or (ii) in a way that violates someone else&rsquo;s (including Proxima&rsquo;s)
      rights.
    </p>

    <p>
      You understand that Proxima owns the Services. You won&rsquo;t modify, publish, transmit,
      participate in the transfer or sale of, reproduce (except as expressly provided in this
      Section), create derivative works based on or otherwise exploit any of the Services.
    </p>

    <p>
      The Services may allow you to copy or download certain Content; please remember that just
      because this functionality exists, doesn&rsquo;t mean that all of the restrictions above
      don&rsquo;t apply &ndash; they do!
    </p>

    <ol>
      <li>
        <p>
          <u>Proprietary Rights Owned by You.</u> Anything you post, upload, share, store or
          otherwise provide through the Services is your &ldquo;User Content.&rdquo; You are
          responsible for all User Content you contribute, in any manner, to the Services, and you
          represent and warrant that you have all rights necessary to do so, in the manner in which
          you contribute it, and that all such User Content is in compliance with all applicable
          state, federal and local laws, rules and regulations. Please note that all of the
          following licenses are subject to our{' '}
          <a href="/terms/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          to the extent they relate to User Content that are also your personally identifiable
          information.
        </p>

        <p>
          If you collect or store Subscriber Information, you hereby grant to Proxima a worldwide,
          transferable, sublicensable, perpetual, irrevocable, non-exclusive, royalty-free license
          to: (a) use, share and otherwise process the Subscriber Information to provide the
          Services under the Terms, including to enable Proxima to prioritize marketing
          communications sent on behalf of customers by using insights that Proxima develops solely
          for internal purposes from subscriber data provided by participating Proxima customers;
          and (b) use the Subscriber Information to maintain and improve our products, software and
          services.
        </p>

        <p>
          In addition to the license rights to the Subscriber Information set forth above, for all
          User Content, you hereby grant Proxima a license to translate, modify (for technical
          purposes, for example making sure your content is viewable on an iPhone as well as a
          computer) and reproduce and otherwise act with respect to such User Content, in each case
          to enable us to operate the Services, as described in more detail below. This is a license
          only &ndash; your ownership in User Content is not affected.
        </p>

        <p>
          If you store User Content in your own personal Proxima account, in a manner that is not
          viewable by any other user except you (&ldquo;Private User Content&rdquo;), you grant
          Proxima the license above, as well as a license to display, perform and distribute your
          Private User Content for the sole purpose of making the Private User Content accessible to
          you and providing the Services necessary to do so.
        </p>

        <p>
          If you share User Content only in a manner that certain specified users can view (for
          example, a private message to one or more other users (&ldquo;Limited Audience User
          Content&rdquo;), then you grant Proxima the licenses above, as well as a license to
          display, perform and distribute your Limited Audience User Content for the sole purpose of
          making that Limited Audience User Content accessible to such other specified users, and
          providing the Services necessary to do so. Also, you grant such other specified users a
          license to access that Limited Audience User Content, and to use and exercise all rights
          in it, as permitted by the functionality of the Services.
        </p>

        <p>
          If you share User Content publicly on the Services and/or in a manner that more than just
          you or certain specified users can view, or if you provide us (in a direct email or
          otherwise) with any feedback, suggestions, improvements, enhancements and/or feature
          requests relating to the Services (each of the foregoing, &ldquo;Public User
          Content&rdquo;), then you grant Proxima the license above, as well as a license to
          display, perform and distribute your Public User Content for the purpose of making that
          Public User Content accessible to all Proxima users and providing the Services necessary
          to do so, as well as all other rights necessary to use and exercise all rights in that
          Public User Content in connection with the Services and/or otherwise in connection with
          Proxima&rsquo;s business for any purpose, provided that Proxima will try to notify you if
          it uses your Public User Content for any reason other than displaying it on the Services.
          Also, you grant all other users of the Services a license to access that Public User
          Content, and to use and exercise all rights in it, as permitted by the functionality of
          the Services.
        </p>

        <p>
          You agree that the licenses you grant are royalty-free, perpetual, sublicensable,
          irrevocable and worldwide, provided that when you delete your Proxima account, we will
          stop displaying your User Content (other than Public User Content, which may remain fully
          available) to other users (if applicable), but you understand and agree that it may not be
          possible to completely delete the content from Proxima&rsquo;s records, and that your User
          Content may remain viewable elsewhere to the extent that they were copied or stored by
          other users.
        </p>

        <p>
          Finally, you understand and agree that Proxima in performing the required technical steps
          to provide the Services to our users (including you), may need to make changes to your
          User Content to conform and adapt such User Content to the technical requirements of
          connection networks, devices, services or media, and the foregoing license includes the
          rights to do so.
        </p>
      </li>

      <li>
        <p>
          <u>Copyright infringement.</u> You may have heard of the Digital Millennium Copyright Act
          (the &ldquo;DMCA&rdquo;), as it relates to online service providers, like Proxima, being
          asked to remove material that allegedly violates someone&rsquo;s copyright. We respect
          others&rsquo; intellectual property rights, and we reserve the right to delete or disable
          Content alleged to be infringing and to terminate the accounts of repeat alleged
          infringers; to review our complete Copyright Dispute Policy and learn how to report
          potentially infringing content, click{' '}
          <a href="/terms/copyright" target="_blank" rel="noreferrer">
            here
          </a>
          . To learn more about the DMCA, click{' '}
          <a href="https://www.copyright.gov/legislation/dmca.pdf" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
      </li>

      <li>
        <p>
          <u>Responsibility for information and material available on the Services.</u> Any
          information or content publicly posted or privately transmitted through the Services is
          the sole responsibility of the person from whom such content originated, and you access
          all such information and content at your own risk, and we aren&rsquo;t liable for any
          errors or omissions in that information or content or for any damages or loss you might
          suffer in connection with it. We cannot control and have no duty to take any action
          regarding how you may interpret and use the Content or what actions you may take as a
          result of having been exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services. We can&rsquo;t
          guarantee the identity of any users with whom you interact in using the Services and are
          not responsible for which users gain access to the Services.
        </p>

        <p>
          You are responsible for all Content you contribute, in any manner, to the Services, and
          you represent and warrant you have all rights necessary to do so, in the manner in which
          you contribute it. You will keep all your registration information accurate and current.
          You are responsible for all your activity in connection with the Services.
        </p>
      </li>
    </ol>

    <h3>F. Third Party Websites and Services</h3>

    <p>
      The Services may contain links or connections to third party websites or services that are not
      owned or controlled by Proxima. When you access third party websites or use third party
      services, you accept that there are risks in doing so, and that Proxima is not responsible for
      such risks. We encourage you to be aware when you leave the Services and to read the terms and
      conditions and privacy policy of each third party website or service that you visit or
      utilize.
    </p>

    <p>
      Proxima has no control over, and assumes no responsibility for, the content, accuracy, privacy
      policies, or practices of or opinions expressed in any third party websites or by any third
      party that you interact with through the Services. In addition, Proxima will not and cannot
      monitor, verify, censor or edit the content of any third party site or service. By using the
      Services, you release and hold us harmless from any and all liability arising from your use of
      any third party website or service.
    </p>

    <p>
      Your interactions with organizations and/or individuals found on or through the Services,
      including payment and delivery of goods or services, and any other terms, conditions,
      warranties or representations associated with such dealings, are solely between you and such
      organizations and/or individuals. You should make whatever investigation you feel necessary or
      appropriate before proceeding with any online or offline transaction with any of these third
      parties. You agree that Proxima shall not be responsible or liable for any loss or damage of
      any sort incurred as the result of any such dealings.
    </p>

    <p>
      We are not responsible for the behavior of any advertisers, or linked third party websites
      available on or through the Services. The inclusion of any advertisement or hyperlink on the
      Services does not imply Proxima&rsquo;s endorsement or any association between Proxima and the
      advertised brand or operators of the website corresponding to that hyperlink. You further
      acknowledge and agree that Proxima shall not be responsible or liable, directly or indirectly,
      for any damage or loss caused or alleged to be caused by or in connection with the use of or
      reliance on any such content, goods or services available on or through any such hyperlinked
      website.
    </p>

    <h3>G. Disputes Between Users or With Third Parties.</h3>

    <p>
      If there is a dispute between participants on this site, or between users and any third party,
      you agree that Proxima is under no obligation to become involved. In the event that you have a
      dispute with one or more other users, you release Proxima, its officers, employees, agents and
      successors from claims, demands, and damages of every kind or nature, known or unknown,
      suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to
      such disputes and/or our Services. If you are a California resident, you shall and hereby do
      waive California Civil Code Section 1542, which says: &ldquo;A general release does not extend
      to claims which the creditor does not know or suspect to exist in his or her favor at the time
      of executing the release, which, if known by him or her must have materially affected his or
      her settlement with the debtor.&rdquo;
    </p>

    <h3>H. Changes of the Services.</h3>

    <p>
      We&rsquo;re always trying to improve the Services, so they may change over time. We may
      suspend or discontinue any part of the Services, or we may introduce new features or impose
      limits on certain features or restrict access to parts or all of the Services. We&rsquo;ll try
      to give you notice when we make a material change to the Services that would adversely affect
      you, but this isn&rsquo;t always practical. Similarly, we reserve the right to remove any
      Content from the Services at any time, for any reason (including, but not limited to, if
      someone alleges you contributed that Content in violation of these Terms), in our sole
      discretion, and without notice.
    </p>

    <h3>I. Termination.</h3>

    <p>
      You&rsquo;re free to stop using the Services at any time, by contacting us at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a>; please refer to our{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      , as well as the licenses above, to understand how we treat information you provide to us
      after you have stopped using our Services.
    </p>

    <p>
      Proxima is also free to terminate (or suspend access to) your use of the Services or your
      account, for any reason in our discretion, including your breach of these Terms. Proxima has
      the sole right to decide whether you are in violation of any of the restrictions set forth in
      these Terms.
    </p>

    <p>
      Account termination may result in destruction of any content associated with your account, so
      keep that in mind before you decide to terminate your account. We will try to provide advance
      notice to you prior to terminating your account so that you are able to retrieve any important
      User Content you may have stored in your account (to the extent allowed by law and these
      Terms), but we may not do so if we determine it would be impractical, illegal, not in the
      interest of someone&rsquo;s safety or security, or otherwise harmful to the rights or property
      of Proxima.
    </p>

    <p>
      If you have deleted your account by mistake, contact us immediately at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a> &ndash; we will try to help, but
      unfortunately, we can&rsquo;t promise that we can recover or restore anything.
    </p>

    <p>
      Provisions that, by their nature, should survive termination of these Terms shall survive
      termination. By way of example, all of the following will survive termination: any obligation
      you have to pay us or indemnify us, any limitations of our liability, any terms regarding
      ownership or intellectual property rights, and terms regarding disputes between us.
    </p>

    <h3>J. Limitation of Liability.</h3>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
      (INCLUDING WITHOUT LIMITATION TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL PROXIMA
      (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING
      DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS OR COMPUTER
      FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I)
      ONE HUNDRED DOLLARS ($100) OR (II) THE AMOUNTS PAID BY YOU TO PROXIMA IN CONNECTION WITH THE
      SERVICES IN THE ONE (1) MONTH PERIOD IMMEDIATELY PRECEDING THE APPLICABLE CLAIM, OR (C) ANY
      MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
      CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
    </p>

    <h3>K. No Warranties.</h3>

    <p>
      Neither Proxima nor its licensors or suppliers makes any representations or warranties
      concerning any content contained in or accessed through the Services, and we will not be
      responsible or liable for the accuracy, copyright compliance, legality or decency of material
      contained in or accessed through the Services. We (and our licensors and suppliers) make no
      representations or warranties regarding suggestions or recommendations of services or products
      offered or purchased through the Services. Products and services purchased or offered (whether
      or not following such recommendations and suggestions) through the Services are provided
      &ldquo;AS IS&rdquo; and without warranty of any kind from Proxima or others (unless, with
      respect to such others only, provided expressly and unambiguously in writing by a designated
      third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY PROXIMA (AND ITS
      LICENSORS AND SUPPLIERS) ON AN &ldquo;AS IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND,
      EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF TITLE,
      NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND/OR ANY WARRANTIES
      IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, OR THAT USE OF THE SERVICES WILL BE
      UNINTERRUPTED OR ERROR-FREE. IN ADDITION, PROXIMA DOES NOT WARRANT THAT: (I) THE SERVICES WILL
      BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE
      CORRECTED; OR (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT THE USE OF THE SERVICES
      AND CONTENT IS SOLELY AT YOUR OWN RISK. NO REPRESENTATION OR STATEMENT, INCLUDING WITHOUT
      LIMITATION, STATEMENTS REGARDING CAPACITY, SUITABILITY FOR USE OR PERFORMANCE OF THE SERVICES,
      NOT CONTAINED IN THESE TERMS WILL BE DEEMED TO BE A WARRANTY BY PROXIMA. SOME STATES DO NOT
      ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT
      APPLY TO YOU.
    </p>

    <h3>L. Indemnity.</h3>

    <p>
      You, at your own expense, will indemnify, defend, and hold harmless Proxima, its subsidiaries,
      affiliates and assigns, and its and their respective directors, officers, employees and agents
      from and against any third-party claim, loss, demand, cause of action, debt or liability,
      including attorneys&rsquo; fees, arising out of or related to (i) your actual or alleged
      breach of any of these Terms, and (ii) your actual or alleged use or misuse of any Proxima
      Services, including, without limitation, any allegation that you used the Proxima Services to
      send any Message in violation of any federal, state, local or other law, rule, or regulation,
      including but not limited to the Telephone Consumer Protection Act (47 U.S.C. &sect; 227) and
      its implementing regulations (47 C.F.R. &sect; 64.1200).
    </p>

    <h3>M. Assignment.</h3>

    <p>
      You may not assign, delegate or transfer these Terms or any of your rights or obligations
      under these Terms, or your Services account, in any way (by operation of law or otherwise)
      without Proxima&rsquo;s prior written consent. We may transfer, assign or delegate these Terms
      and our rights and obligations without consent.
    </p>

    <h3>N. Choice of Law; Arbitration.</h3>

    <p>
      These Terms are governed by and will be construed under the laws of the state of New York,
      without regard to the conflict of laws provisions thereof. Any dispute arising from or
      relating to the subject matter of these Terms shall be finally settled in New York County, New
      York, in English, in accordance with the Streamlined Arbitration Rules and Procedures of
      Judicial Arbitration and Mediation Services, Inc. (&ldquo;JAMS&rdquo;) then in effect, by one
      commercial arbitrator with substantial experience in resolving intellectual property and
      commercial contract disputes, who shall be selected from the appropriate list of JAMS
      arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator
      may be entered in any court of competent jurisdiction. Notwithstanding the foregoing
      obligation to arbitrate disputes, each party shall have the right to pursue injunctive or
      other equitable relief at any time, from any court of competent jurisdiction. For all purposes
      of these Terms, the parties consent to exclusive jurisdiction and venue in the state or
      federal courts located in, respectively, New York County, New York, or the Southern District
      of New York. Any arbitration under these Terms will take place on an individual basis: class
      arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING
      INTO THESE TERMS, YOU AND PROXIMA ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO
      PARTICIPATE IN A CLASS ACTION.
    </p>

    <h3>O. Miscellaneous.</h3>

    <p>
      You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and
      other governmental assessments associated with your activity in connection with the Services,
      provided that the Proxima may, in its sole discretion, do any of the foregoing on your behalf
      or for itself as it sees fit. The failure of either you or us to exercise, in any way, any
      right herein shall not be deemed a waiver of any further rights hereunder. If any provision of
      these Terms is found to be unenforceable or invalid, that provision will be limited or
      eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in
      full force and effect and enforceable. You and Proxima agree that these Terms are the complete
      and exclusive statement of the mutual understanding between you and Proxima, and that it
      supersedes and cancels all previous written and oral agreements, communications and other
      understandings relating to the subject matter of these Terms. You hereby acknowledge and agree
      that you are not an employee, agent, partner, or joint venture of Proxima, and you do not have
      any authority of any kind to bind Proxima in any respect whatsoever. You and Proxima agree
      there are no third party beneficiaries intended under these Terms.
    </p>
  </Layout>
);

export default UseView;
