import styled from '@emotion/styled';
import { Button, colors } from '@innovationdepartment/proxima-ui';
import { AudienceStatus, GeneratedAudiencesStatusProps } from 'types/components/audiences';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import GeneratedAudienceStatusInfoSteps from './GeneratedAudiencesStatus.InfoSteps';
import GeneratedAudiencesStatusHeader from './GeneratedAudiencesStatus.Header';
import { CampaignStatus } from 'types/components/audienceCampaign';

const ContentWrapper = styled.div`
  width: 100vw;
  max-width: 858px;
  margin: 40px auto 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ButtonSection = styled.div`
  // oh god, another custom button
  & > button {
    border-color: ${colors.neutral20};
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const GeneratedAudiencesStatusView = ({
  campaignStatus,
  status,
  onClose,
  stepType,
}: GeneratedAudiencesStatusProps) => (
  <ModalBaseChildrenWrapper
    submitButton={
      <ButtonSection>
        <Button variant="outlined" label="Done" color="secondary" onClick={onClose} />
      </ButtonSection>
    }
  >
    <ContentWrapper>
      <GeneratedAudiencesStatusHeader
        isProcessing={
          status === AudienceStatus.Processing || campaignStatus === CampaignStatus.Processing
        }
        stepType={stepType}
      />
      <GeneratedAudienceStatusInfoSteps stepType={stepType} />
    </ContentWrapper>
  </ModalBaseChildrenWrapper>
);

export default GeneratedAudiencesStatusView;
