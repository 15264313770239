import { useEffect } from 'react';

import { useBrandStore } from 'stores';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutRoute = () => {
  const { logout } = useAuth0();
  const { clearBrandStore } = useBrandStore();

  useEffect(() => {
    clearBrandStore();
    logout({ returnTo: window.location.origin });
  });

  return null;
};

export default LogoutRoute;
