import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';

const InstructionsContainer = styled.div`
  background-color: ${colors.neutral0};
  border: 1px solid ${colors.neutral10};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
`;

const NoMarginBulletedList = styled.ul`
  margin: 0;
`;

const CustomerListUploadModalInstructions = () => (
  <InstructionsContainer>
    <Text variant="body1Semibold">Instructions</Text>
    <Text variant="body2Medium">
      1. Export your customer list as a CSV with the following column:
    </Text>
    <NoMarginBulletedList>
      <li>
        <Text variant="body2Medium">Email Address</Text>
      </li>
    </NoMarginBulletedList>
    <Text variant="body2Medium">2. Hash it using SHA-256</Text>
    <Text variant="body2Medium">3. Upload the hashed CSV</Text>
  </InstructionsContainer>
);

export default CustomerListUploadModalInstructions;
