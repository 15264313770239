import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Button, LinkIcon, Text, colors } from '@innovationdepartment/proxima-ui';
import ButtonGroup from 'ui/ButtonGroup';
import { CustomTosStep } from 'types/components/audiencesCustomTos';
import LoadingSpinner from 'ui/LoadingSpinner';

const ContentWrapper = styled.div`
  max-width: 430px;

  & svg path {
    fill: ${colors.neutral100};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const BodyContent = styled.div`
  margin: 48px 0;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
`;

type CustomAudienceTermsViewProps = {
  onButtonClick: () => void;
  onClose: () => void;
  loading: boolean;
  step: CustomTosStep;
};

const CustomAudienceTermsNotAcceptedModalView = ({
  onButtonClick,
  onClose,
  loading,
  step,
}: CustomAudienceTermsViewProps) => (
  <ContentWrapper>
    <TitleWrapper>
      <Text variant="h6">Accept Meta&apos;s Custom Audiences Terms to get started</Text>
      <CloseIcon onClick={onClose} />
    </TitleWrapper>
    <BodyContent>
      <Text variant="body1">
        In order to add Proxima audiences to your ad account, please accept Meta&apos;s Custom
        Audiences Terms, then try again.
      </Text>
    </BodyContent>
    <ButtonGroup hPadding={12}>
      <Button
        size="small"
        disabled={loading}
        startIcon={loading && <LoadingSpinner />}
        variant={step === CustomTosStep.AcceptTerms ? 'outlined' : 'contained'}
        label={step === CustomTosStep.AcceptTerms ? 'Accept terms' : 'I accepted'}
        endIcon={step === CustomTosStep.AcceptTerms && <LinkIcon />}
        onClick={onButtonClick}
      />
    </ButtonGroup>
  </ContentWrapper>
);

export default CustomAudienceTermsNotAcceptedModalView;
