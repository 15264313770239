import { useBillingApi } from 'api';

const useBilling = () => {
  const { getStripeCustomerPortalUrl, loading } = useBillingApi();
  const navigateToCustomerPortal = async (brandId: string) => {
    const response = await getStripeCustomerPortalUrl(brandId);
    if (response!.error) return;
    const { url } = response.data;
    window.open(url, '_self');
  };

  return {
    navigateToCustomerPortal,
    loading,
  };
};

export default useBilling;
