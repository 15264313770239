import { Route, Routes } from 'react-router-dom';
import Terms from './Terms.Container';
import TermsUse from './Use/Use.Container';
import TermsPrivacy from './Privacy/Privacy.Container';
import TermsCopyright from './Copyright/Copyright.Container';

const TermsRoute = () => (
  <Routes>
    <Route path="" element={<Terms />}>
      <Route path="use" element={<TermsUse />} />
      <Route path="privacy" element={<TermsPrivacy />} />
      <Route path="copyright" element={<TermsCopyright />} />
    </Route>
  </Routes>
);

export default TermsRoute;
