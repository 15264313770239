import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { LookalikeSourcesAudiencesLookalikeProps } from 'types/components/audiences';
import ContentSection from 'ui/ContentSection';
import LookalikeSourcesAudiencesLookalike from 'ui/Modals/CreateNewAudiencesModal/Layout/LookalikeSources/LookalikeSourcesAudiences/LookalikeSourcesAudiences.Lookalike';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const LookalikeSizePreviewView = (props: LookalikeSourcesAudiencesLookalikeProps) => {
  const { galaxy, size } = props;

  return (
    <ContentSection>
      <ContentWrapper>
        <Text variant="body1Semibold" color="neutral100">
          Your lookalike
        </Text>
        <LookalikeSourcesAudiencesLookalike galaxy={galaxy} size={size} />
      </ContentWrapper>
    </ContentSection>
  );
};

export default LookalikeSizePreviewView;
