import { Link } from 'react-router-dom';
import {
  NavBar as ProximaNavBar,
  NavBarButton,
  ProximaLogo,
  NavbarMenu,
} from '@innovationdepartment/proxima-ui';

import { useUserStore } from 'stores';
import { IUser } from '@innovationdepartment/proxima-ui/dist/esm/types/user';
import { PropsOf } from '@emotion/react';

const logo = (
  <Link to="/brands">
    <ProximaLogo />
  </Link>
);

type NavbarTopButton = PropsOf<typeof NavBarButton>['button'] & { pathname: string };
type NavBarViewProps = {
  accountMenuItems: {
    label: string;
    onClick: () => void;
    pathname: string;
    trailingDivider?: boolean;
  }[];
  navbarTopButtons: NavbarTopButton[];
  navbarBottomButtons: PropsOf<typeof NavbarMenu>[];
};

const NavBarView = (props: NavBarViewProps) => {
  const { accountMenuItems, navbarTopButtons, navbarBottomButtons } = props;
  const { user } = useUserStore();

  return (
    <ProximaNavBar
      logo={logo}
      accountMenuItems={accountMenuItems}
      pathname={window.location.pathname}
      user={user as IUser}
      navbarMenuItems={navbarBottomButtons}
    >
      {navbarTopButtons.map((button) => {
        const isActive = window.location.pathname === button.pathname;
        return (
          <Link to={button.pathname} key={button.label}>
            <NavBarButton button={button} isActive={isActive} />
          </Link>
        );
      })}
    </ProximaNavBar>
  );
};

export default NavBarView;
