export type BillingContact = {
  contactId: string;
  email: string;
  createdAt: string;
  brandId: string;
};

export type CreateBillingContactSchema = {
  email: BillingContact['email'];
};

export type CreateResponseBillingContactSchema = {
  contactId: BillingContact['contactId'];
};

export type GetBillingContactsSchema = BillingContact[];

export type BillingViewProps = {
  contacts: BillingContact[];
  onContactsChange: (tags: string[]) => void;
  loading: boolean;
};

export enum BillingFrequency {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type PlanDescription = {
  monthly: string;
  yearly: string;
  freeTrial: React.ReactNode;
  pro: string;
  noPlan: React.ReactNode;
};

export enum Plan {
  FreeTrial = 'freeTrial',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type BillingPlanSubscription = {
  currentPeriodEnd: string;
  description: string;
  trialEndDate: string;
  status: PlanStatus | undefined;
  paymentFailed: boolean;
  plan: keyof PlanDescription | undefined;
  metadata?: {
    brandId: string;
    plan?: Plan;
  };
};

export type BillingPlanTypeSubscription = {
  plan: keyof PlanDescription | undefined;
  paymentFailed: boolean;
  trialEndedDate: string;
  currentPeriodEnd: string;
  customDescription: string;
  handleButtonClick: (choosePlan: boolean) => void;
  status: string | undefined;
};

export enum PlanStatus {
  Active = 'active',
  Incomplete = '  incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}
