import { useAuth0 } from '@auth0/auth0-react';
import { useHandleApi } from 'hooks';

export default function useAuth0Api() {
  const { isLoading } = useAuth0();
  const { loading, handleApi } = useHandleApi({ apiDomain: import.meta.env.VITE_AUTH0_API_URL });

  const getUserInfo = async () => {
    const response = await handleApi({ endpoint: '/userinfo' });
    return response;
  };

  return {
    getUserInfo,
    loading: loading || isLoading,
  };
}
