import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { HandleApiOpts, HandleApiRequest, IApiValidationError } from 'types/hooks/useHandleApi';

import useAccessToken from './useAccessToken';

const handleApiRequest: HandleApiRequest = async (opts) => {
  const { method = 'GET', url, accessToken, data, headers = {}, skipErrorToaster = false } = opts;
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

  const options = {
    url,
    method,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    data,
    skipErrorToaster,
  };
  try {
    return await axios.request(options);
  } catch (e: any) {
    const err: AxiosError<IApiValidationError | IApiValidationError[]> = e;
    const errorStatus = err.response?.status || 500;

    let errorData = err.response?.data;

    if (errorData && Array.isArray(errorData)) {
      [errorData] = errorData;
    }

    let errorMessage: string;
    errorMessage = errorData?.message || 'Unknown Error';

    // for validation error, grab the first one and set message
    if (errorStatus === 400) {
      errorMessage = errorData?.message || 'A validation error occurred';
    }

    return { error: errorMessage, status: errorStatus, url };
  }
};

function useHandleApi(opts: { apiDomain?: string } = {}) {
  const { apiDomain = import.meta.env.VITE_PROXIMA_API_URL } = opts;
  const { isLoading } = useAuth0();
  const { getAccessToken } = useAccessToken();
  const [loading, setLoading] = useState(false);

  const handleApi = async (options: HandleApiOpts) => {
    setLoading(true);

    const {
      method,
      data,
      endpoint,
      brandId,
      publicEndpoint,
      headers = {},
      skipErrorToaster = false,
    } = options;
    let accessToken;
    if (!publicEndpoint) {
      const response = await getAccessToken({ brandId });
      accessToken = response.accessToken;
    }
    const url = `${apiDomain}${endpoint}`;
    const response = await handleApiRequest({
      method,
      url,
      data,
      accessToken,
      headers,
      skipErrorToaster,
    });

    setLoading(false);
    return response;
  };

  return {
    handleApi,
    loading: loading || isLoading,
  };
}

export default useHandleApi;
