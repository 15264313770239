import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useShopify, useQuery } from 'hooks';
import { Spinner } from '@innovationdepartment/proxima-ui';
import { useNavigate } from 'react-router-dom';

const debouncer = debounce((cb) => cb(), 500);

const ShopifyGenerateOAuthUrl = () => {
  const navigate = useNavigate();
  const { getQueryParams } = useQuery();
  const { generateOAuthUrlWithValidation } = useShopify();

  const { session, hmac } = getQueryParams();

  useEffect(() => {
    debouncer(() => {
      // If we have a session query parameter, it means the user has visited our website through shopify app.
      // for now we just redirect to the homepage
      if (session || !hmac) navigate('/brands');
      else generateOAuthUrlWithValidation();
    });
  }, [session, hmac]);

  return <Spinner />;
};

export default ShopifyGenerateOAuthUrl;
