import { usePublicPage } from 'hooks';
import { useLocation } from 'react-router-dom';
import UserStoreLoader from './UserStoreLoader';
import RedirectLoader from './RedirectLoader';

const Loaders: React.FC = ({ children }) => {
  const location = useLocation();
  const { isRoutePublicPage } = usePublicPage();
  const isPublicPage = isRoutePublicPage(location.pathname);

  if (isPublicPage) return <>{children}</>;

  return (
    // order here matters, UserStoreLoader must be first
    <UserStoreLoader>
      <RedirectLoader>{children}</RedirectLoader>
    </UserStoreLoader>
  );
};

export default Loaders;
