interface IToasterMessage {
  message?: string;
  variant?: 'success' | 'warning' | 'info';
}

interface IToasterConfig {
  success: IToasterMessage;
  error: IToasterMessage;
}

const config: IToasterConfig = {
  success: {
    message: 'Changes saved.',
    variant: 'success',
  },
  error: {
    message: 'Error updating profile. Try again later.',
    variant: 'warning',
  },
};

export default config;
