import { AdManagerFullCycle } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import MainContentDescription from '../IntegrationsModal/LayoutComponents/MainContentDescription';

const content: { title: string; description: React.ReactNode }[] = [
  {
    title: 'Connect your Meta ad account',
    description: (
      <>
        Connect Meta to Proxima so that we can migrate audiences directly to your ad account and
        monitor performance to enhance the targeting algorithm. This integration powers your ads.
        Without it, we&apos;re on a rocket ship with no navigation.
        <br />
        <br />
        Proxima will never set any ads live. Ad account access is required to upload your custom
        audiences, create campaign and ad set shells, track performance, and turn off
        underperforming audiences.
      </>
    ),
  },
];

const GraphContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  * > svg {
    width: 100%;
  }
`;

const FacebookIntegrationModalMainView = () => (
  <>
    {content.map(({ title, description }) => (
      <MainContentDescription key={title} title={title} description={description} />
    ))}
    <GraphContainer>
      <AdManagerFullCycle />
    </GraphContainer>
  </>
);

export default FacebookIntegrationModalMainView;
