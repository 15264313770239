import { Route, Routes } from 'react-router-dom';
import { CallbacksRouter } from './Callbacks';
import Auth from './Auth.Container';
import AllSet from './AllSet';

const AuthRouter = () => (
  <Routes>
    <Route path="" element={<Auth />}>
      <Route path="callback/*" element={<CallbacksRouter />} />
      <Route path="all-set" element={<AllSet />} />
    </Route>
  </Routes>
);

export default AuthRouter;
