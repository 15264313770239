import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { ConnectKlaviyoAccountViewProps } from 'types/components/klaviyo';
import ConnectKlaviyoAccountInstructions from './ConnectKlaviyoAccount.Instructions';
import ConnectKlaviyoAccountTitle from './ConnectKlaviyoAccount.Title';
import ConnectKlaviyoAccountConfiguration from './ConnectKlaviyoAccount.Configuration';
import ConnectKlaviyoAccountInfoBanners from './ConnectKlaviyoAccount.InfoBanners';

const ContentWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InsideContent = styled.div`
  max-width: 640px;

  & > h5 {
    margin-bottom: 8px;
  }

  & > p {
    margin-bottom: 48px;
  }

  & > hr {
    margin-bottom: 16px;
  }

  & svg path {
    fill: ${colors.neutral100};
  }
`;

const ConnectKlaviyoAccountView = (props: ConnectKlaviyoAccountViewProps) => (
  <ContentWrapper>
    <InsideContent>
      <ConnectKlaviyoAccountTitle />
      <ConnectKlaviyoAccountInfoBanners {...props} />
      <ConnectKlaviyoAccountInstructions />
      <ConnectKlaviyoAccountConfiguration {...props} />
    </InsideContent>
  </ContentWrapper>
);

export default ConnectKlaviyoAccountView;
