import { useEffect, useState } from 'react';
import { useBillingApi } from 'api';
import { BillingPlanSubscription, PlanStatus } from 'types/billing';
import LoadingSpinner from 'ui/LoadingSpinner';
import BillingPlanView from './BillngPlan.View';
import BillingSection from '../Billing.Section';
import { useBilling } from 'hooks';
import { useParams } from 'react-router-dom';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';

const BillingPlan = () => {
  const { brandId: paramBrandId } = useParams();
  const { navigateToCustomerPortal, loading: navigating } = useBilling();
  const { getBrandSubscription, loading } = useBillingApi();
  const [subscription, setSubscription] = useState<BillingPlanSubscription>({
    description: '',
    trialEndDate: '',
    currentPeriodEnd: '',
    status: undefined,
    paymentFailed: false,
    plan: undefined,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const fetchSubscription = async (brandId: string) => {
    const response = await getBrandSubscription(brandId);

    if (response.error) return;

    const { description, trialEndDate, currentPeriodEnd, status, metadata } = response.data;

    const paymentFailed = status === PlanStatus.Unpaid || status === PlanStatus.IncompleteExpired;
    const { plan } = metadata;

    setSubscription({ description, trialEndDate, currentPeriodEnd, status, paymentFailed, plan });
  };

  const handleButtonClick = (choosePlan: boolean, brandId: string) => {
    if (choosePlan) setModalOpen(true);
    else navigateToCustomerPortal(brandId);
  };

  useEffect(() => {
    if (paramBrandId) fetchSubscription(paramBrandId);
  }, [paramBrandId]);

  if (loading || navigating) return <LoadingSpinner />;

  return (
    <BillingSection title="Your current plan" status={subscription.status}>
      <BillingPlanView
        status={subscription.status}
        plan={subscription.plan}
        paymentFailed={subscription.paymentFailed}
        trialEndedDate={subscription.trialEndDate}
        currentPeriodEnd={subscription.currentPeriodEnd}
        customDescription={subscription.description}
        handleButtonClick={(choosePlan: boolean) =>
          paramBrandId && handleButtonClick(choosePlan, paramBrandId)
        }
      />
      {paramBrandId && (
        <BuildPlanModal
          open={modalOpen}
          brandId={paramBrandId}
          onClose={() => setModalOpen(false)}
        />
      )}
    </BillingSection>
  );
};

export default BillingPlan;
