import styled from '@emotion/styled';
import ContentSection from 'ui/ContentSection';
import { LookalikeSourcesViewProps } from 'types/components/audiences';
import LookalikeSourcesHeader from './LookalikeSources.Header';
import LookalikeSourcesAudiences from './LookalikeSourcesAudiences';

import LookalikeSourcesDataSourceInfo from './LookalikeSourcesDataSourceInfo';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 24px;
`;

const LookalikeSourcesView = (props: LookalikeSourcesViewProps) => (
  <ContentSection>
    <ContentWrapper>
      <LookalikeSourcesHeader {...props} />
      <LookalikeSourcesDataSourceInfo />
      <LookalikeSourcesAudiences {...props} />
    </ContentWrapper>
  </ContentSection>
);

export default LookalikeSourcesView;
