import styled from '@emotion/styled';
import { FormField, Text, Tooltip, colors, InfoDark } from '@innovationdepartment/proxima-ui';

import { CampaignAttributeProps } from 'types/components/audienceCampaign';

const ContentWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
`;
const ContentSection = styled.div`
  width: 826px;
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  padding: 24px;
`;

const HeaderContentWrapper = styled.div`
  margin-bottom: 16px;
`;

const TextWrapper = styled.div`
  width: 381px;
  flex: 50%;
`;
const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  flex-wrap: wrap;
  display: flex;
`;
const TextWrapperWithBackground = styled.div`
  background-color: ${colors.neutral0};
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  padding: 12px;
`;

const LabelInfoIconWrapper = styled.div`
  width: 32px;
  color: ${colors.neutral100};
`;
const LabelWrapper = styled.div`
  width: 640px;
  margin-top: -4px;
`;

const ToolTipInfoIconWrapper = styled.div<{ widthVal: number; align: 'left' | 'right' | 'bottom' }>`
  width: ${({ widthVal }) => widthVal}%;
  text-align: ${({ align }) => align};
  margin-top: 2px;
`;

const ToolTipLabelWrapper = styled.div<{ widthVal: number; align: 'left' | 'right' }>`
  width: ${({ widthVal }) => widthVal}%;
  text-align: ${({ align }) => align};
`;

const ToolTipWrapper = styled.div<{ flex?: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const TextWrapperBudget = styled.div`
  width: 381px;
  flex: 5%;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.neutral10};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const CampaignAttributesView = ({
  campaignObjectiveOptions,
  campaignAttributionSettingOptions,
}: CampaignAttributeProps) => (
  <ContentSection>
    <ContentWrapper>
      <HeaderContentWrapper>
        <Text variant="body1Semibold" color="neutral100">
          Let’s create a campaign for your new audiences
        </Text>
      </HeaderContentWrapper>

      <Text variant="body2" color="neutral70">
        We’ll setup a campaign optimized for targeting your audiences. You can always change these
        settings in your ad account.
      </Text>

      <Divider />
      <TextWrapper>
        <Text variant="body2Semibold" color="neutral100">
          Campaign name
        </Text>
      </TextWrapper>
      <TextWrapper>
        <FormField name="name" type="input" fullWidth />
      </TextWrapper>
      <Divider />
      <FieldWrapper>
        <TextWrapper>
          <Text variant="body2Semibold" color="neutral100">
            Campaign objective
          </Text>
        </TextWrapper>
        <TextWrapper>
          <FormField
            name="objective"
            type="select"
            fullWidth
            options={campaignObjectiveOptions}
            disabled
          />
        </TextWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <TextWrapper>
          <Text variant="body2Semibold" color="neutral100">
            Attribution setting
          </Text>
        </TextWrapper>
        <TextWrapper>
          <FormField
            name="attributionSetting"
            type="select"
            fullWidth
            options={campaignAttributionSettingOptions}
            disabled
          />
        </TextWrapper>
      </FieldWrapper>
      <TextWrapper />
      <TextWrapper>
        <ToolTipWrapper>
          <ToolTipInfoIconWrapper widthVal={43} align="right">
            <Tooltip
              title={
                <div style={{ padding: 4 }}>
                  <Text variant="body3" color="white">
                    As Proxima Audiences are built on transactional data. We recommend campaign
                    objective setting to Sales. More options coming soon.
                  </Text>
                </div>
              }
              info
              arrow
              placement="bottom"
            />
          </ToolTipInfoIconWrapper>
          <ToolTipLabelWrapper widthVal={60} align="right">
            <Text variant="body2" color="neutral70">
              Why can’t I change these settings?
            </Text>
          </ToolTipLabelWrapper>
        </ToolTipWrapper>
      </TextWrapper>
      <Divider />
      <FieldWrapper>
        <TextWrapper>
          <ToolTipWrapper>
            <ToolTipLabelWrapper widthVal={52} align="left">
              <Text variant="body2Semibold" color="neutral100">
                What is your propsecting Cost Per Result?
              </Text>
            </ToolTipLabelWrapper>
            <ToolTipInfoIconWrapper widthVal={49} align="left">
              <Tooltip
                title={
                  <div style={{ padding: 4 }}>
                    <Text variant="body3" color="white">
                      This will help determine the optimal campaign structure.
                    </Text>
                  </div>
                }
                info
                arrow
                placement="bottom"
              />
            </ToolTipInfoIconWrapper>
          </ToolTipWrapper>
        </TextWrapper>

        <TextWrapperBudget>
          <div style={{ position: 'absolute', right: 241, marginTop: 8 }}>
            <Text variant="body2" color="neutral60">
              $
            </Text>
          </div>
          <FormField name="cpr" type="input" fullWidth />
        </TextWrapperBudget>
      </FieldWrapper>
      <FieldWrapper>
        <TextWrapper>
          <ToolTipWrapper>
            <ToolTipLabelWrapper widthVal={50} align="left">
              <Text variant="body2Semibold" color="neutral100">
                What is your available Daily Test Budget?
              </Text>
            </ToolTipLabelWrapper>
            <ToolTipInfoIconWrapper widthVal={50} align="left">
              <Tooltip
                title={
                  <div style={{ padding: 4 }}>
                    <Text variant="body3" color="white">
                      We recommend a testing budget of 10x your in-platform CPA. The larger the
                      budget, the faster the algorithm will learn.
                    </Text>
                  </div>
                }
                info
                arrow
                placement="bottom-end"
              />
            </ToolTipInfoIconWrapper>
          </ToolTipWrapper>
        </TextWrapper>
        <TextWrapperBudget>
          <div style={{ position: 'absolute', right: 241, marginTop: 8 }}>
            <Text variant="body2" color="neutral60">
              $
            </Text>
          </div>
          <FormField name="dailyBudget" type="input" fullWidth />
        </TextWrapperBudget>
      </FieldWrapper>
      <TextWrapperWithBackground>
        <LabelInfoIconWrapper>
          <InfoDark />
        </LabelInfoIconWrapper>
        <LabelWrapper>
          <Text variant="body2Semibold" color="neutral100">
            We will not launch your campaign
          </Text>
          <Text variant="body2" color="neutral70">
            Proxima will create a shell campaign in your ad account. We won’t set anything live.
          </Text>
        </LabelWrapper>
      </TextWrapperWithBackground>
    </ContentWrapper>
  </ContentSection>
);

export default CampaignAttributesView;
