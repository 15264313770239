import { useNavigate } from 'react-router-dom';
import { useToaster } from '@innovationdepartment/proxima-ui';
import useFacebookLoginForBiz from './useFacebookLoginForBiz';

type FacebookLoginProps = {
  /**
   * the path to redirect to after finishing facebook/ad account connection
   */
  redirect: string;
  /**
   * A callback to run if the login fails
   */
  onFail?: (response?: fb.StatusResponse) => void;
  /**
   * The brand ID to connect facebook to
   */
  brandId?: string;
};

const useConnectFacebookToProxima = ({ redirect, onFail, brandId }: FacebookLoginProps) => {
  const navigate = useNavigate();
  const { showToaster } = useToaster();
  const { onFacebookLoginClick } = useFacebookLoginForBiz();

  const onSuccess = (response: fb.StatusResponse) => {
    const searchParams = new URLSearchParams();

    const {
      authResponse: { userID, code },
    } = response;

    searchParams.append('code', code!);
    searchParams.append('facebookUserId', userID);

    if (brandId) searchParams.append('brandId', brandId);
    if (redirect) searchParams.append('redirect', redirect);

    navigate(`/auth/callback/meta?${searchParams.toString()}`);
  };

  const onFacebookLoginFail = (response: fb.StatusResponse) => {
    showToaster({ message: `Unable to connect to Meta`, variant: 'error' });
    onFail?.(response);
  };

  const onClick = onFacebookLoginClick((response) => {
    if (response.status === 'connected') {
      onSuccess(response);
    } else {
      onFacebookLoginFail(response);
    }
  });

  return { onClick, onFail };
};

export default useConnectFacebookToProxima;
