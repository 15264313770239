import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { usePublicPage, useQuery, useStorage } from 'hooks';

type AllowedQueryParams = 't_hash' | 'email_hash';

const Auth0UserAuth: React.FC = ({ children }) => {
  const location = useLocation();
  const { isRoutePublicPage } = usePublicPage();
  const { getQueryParams } = useQuery();
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const isPublicPage = isRoutePublicPage(location.pathname);
  const sessionStorage = useStorage();
  /* t_hash and email_hash must match variable names in Auth0 */
  const { email_hash: emailHash, t_hash: ticketHash } = getQueryParams<AllowedQueryParams>();

  /*
   * In order to redirect to auth0 login page with these params, we need to first log out the user.
   * This is because auth0 will not redirect to the login page if the user is already logged in.
   * That way, we can redirect and set these hashes as query params to use in Auth0 login page.
   */
  const containsHashes = Boolean(emailHash || ticketHash);
  const shouldLogUserOut = isAuthenticated && containsHashes;

  useEffect(() => {
    const skip = isLoading || isPublicPage || shouldLogUserOut;
    if (skip) return;
    if (isAuthenticated) return;

    const extraQueryParams = {};
    if (emailHash) Object.assign(extraQueryParams, { email_hash: emailHash });
    if (ticketHash) Object.assign(extraQueryParams, { t_hash: ticketHash });
    const loginWithRedirectParams: Parameters<typeof loginWithRedirect>[0] = {
      action: 'sign-in',
      ...extraQueryParams,
    };
    const currentAppRoute = `${location.pathname}${window.location.search}`;
    if (location.pathname !== '/') {
      sessionStorage.setItem('redirectTo', currentAppRoute, 1000 * 60 * 30); // just keep for 30 mins
    }
    loginWithRedirect(loginWithRedirectParams);
  }, [isLoading, isAuthenticated, location.pathname]);

  if (shouldLogUserOut) {
    logout({ returnTo: window.location.href });
  }

  if (containsHashes) return <></>;

  return <>{children}</>;
};

export default Auth0UserAuth;
