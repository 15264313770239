import { TagsInput, Text } from '@innovationdepartment/proxima-ui';
import { BillingViewProps } from 'types/billing';
import BillingSection from '../Billing.Section';

const BillingInvoiceRecipientsView = (props: BillingViewProps) => {
  const { contacts, onContactsChange } = props;

  const tags = contacts.map(({ email }) => email);

  return (
    <BillingSection title="Invoice recipients" status={undefined}>
      <Text variant="body2">
        We’ll send invoice copies to the emails below. This will take effect at the start of the
        next billing cycle.
      </Text>
      <TagsInput
        placeholder="Hit tab, comma or return to add multiple email addresses"
        tags={tags}
        onTagsChange={onContactsChange}
      />
    </BillingSection>
  );
};

export default BillingInvoiceRecipientsView;
