import styled from '@emotion/styled';
import { Text, CheckmarkBlue } from '@innovationdepartment/proxima-ui';

type CheckMarkLineViewProps = {
  text: string;
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const CheckMarkLineView = ({ text }: CheckMarkLineViewProps) => (
  <Wrapper>
    <CheckmarkBlue />
    <Text variant="body1">{text}</Text>
  </Wrapper>
);

export default CheckMarkLineView;
