import { AllowedColors, TableProps, colors } from '@innovationdepartment/proxima-ui';
import {
  AudienceTableCellRendererProps,
  LookalikeAudience,
  LookalikeAudienceStatus,
  LookalikeAudienceTableColumnHeader,
  LookalikeAudienceTableRowExtras,
  LookalikeAudienceTableRowProps,
  LookalikeAudiencesTableProps,
} from 'types/components/audiences';
import LookalikeAudiencesTableView from './LookalikeAudiencesTable.View';
import lookalikeAudiencesRowRenderer from './lookalikeAudiencesRowRenderer';

const LookalikeAudiencesTable = (props: LookalikeAudiencesTableProps) => {
  const { seedAudience, lookalikeAudiences, onRetryAudience } = props;
  const config: TableProps<LookalikeAudience>['config'] = {
    noDivider: true,
    columns: {
      order: [
        'lookalikeAudience',
        'platform',
        'displayStatus',
        'dateCreated',
      ] satisfies LookalikeAudienceTableColumnHeader[],
      lookalikeAudience: {
        label: 'Lookalike Audience',
      },
      platform: {
        label: 'Platform',
        width: 160,
      },
      displayStatus: {
        label: 'Status',
        width: 160,
      },
      dateCreated: {
        label: 'Date Created',
        width: 140,
        align: 'right',
      },
    },
    cellRenderer(row) {
      type RowRendererProps = AudienceTableCellRendererProps<LookalikeAudienceTableRowProps> &
        LookalikeAudienceTableRowExtras;
      return lookalikeAudiencesRowRenderer({
        ...row,
        onRetryAudience,
        seedAudience,
      } as RowRendererProps);
    },
    rowColor(row) {
      let color: AllowedColors | undefined;
      const isError = row.displayStatus === LookalikeAudienceStatus.Error;

      if (isError) color = colors.red0 as AllowedColors;

      return color;
    },
  };

  // TODO(Jenky): ask product about this
  const lookalikes = lookalikeAudiences.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    // (a, b) => a.size - b.size
  );

  return <LookalikeAudiencesTableView lookalikeAudiences={lookalikes} config={config} />;
};

export default LookalikeAudiencesTable;
