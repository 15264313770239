import { Auth0Provider } from '@auth0/auth0-react';
import { PropsOf } from '@emotion/react';

const AUTH_0_PROVIDER_PROPS: PropsOf<typeof Auth0Provider> = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN || '',
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
  redirectUri: window.location.origin,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

const Auth0ProviderWrapper: React.FC = ({ children }) => (
  <Auth0Provider {...AUTH_0_PROVIDER_PROPS}>{children}</Auth0Provider>
);

export default Auth0ProviderWrapper;
