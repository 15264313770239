// BrandSetup constants
export enum OrganizationType {
  Agency = 'agency',
  Brand = 'brand',
}

export const OrganizationOptions = [
  { value: 'brand', label: 'Brand' },
  { value: 'agency', label: 'Agency' },
];

// UserSetup constants
export const ToasterSuccess: any = {
  message: 'Profile updated successfully.',
  variant: 'success',
};

export const ToasterError: any = {
  message: 'Error saving profile. Try again later.',
  variant: 'warning',
};
