import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';

const GradientWrapper = styled.div`
  background: linear-gradient(to bottom right, #1ce8ab, #5091f3);
  border-radius: 4px;
  display: grid;
  place-items: center;
`;
const TextWrapper = styled.div`
  border-radius: 4px;
  padding: 6px 8px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const NewAudiencesReadyBadge = () => (
  <GradientWrapper>
    <TextWrapper>
      <Text color="green80" variant="tag1Medium">
        New audiences ready
      </Text>
    </TextWrapper>
  </GradientWrapper>
);

export default NewAudiencesReadyBadge;
