import {
  AUDIENCES_BUDGETING_GUIDE_URL,
  AUDIENCES_METHODOLOGIES_GUIDE_URL,
  AUDIENCES_ZOOM_WITH_EXPERT_URL,
} from 'constants/urls';
import styled from '@emotion/styled';
import {
  Button,
  Methodologies,
  PersonalGuidance,
  Text,
  colors,
} from '@innovationdepartment/proxima-ui';
import ProximaLink from 'ui/ProximaLink';
import { ModalStep } from 'types/components/audiences';

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  gap: 8px;
`;

const stepTimelineLine = `
  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    height: calc(100% - 20px - 8px);
    border-left: 2px solid ${colors.neutral10};
    left: -27px;
    top: 28px;
  }
`;

const stepTimelineNumber = `
  &::before {
    content: counter(section);
    position: absolute;
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    background-color: ${colors.neutral10};
    border-radius: 50%;
    left: -36px;
    top: 0;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    color: ${colors.neutral80};
  }
`;

const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  counter-increment: section;
  min-height: 44px;
  max-width: 540px;

  // oh god, another custom button, on top of the previous custom button
  & button {
    padding: 8px;
    & > span {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  ${stepTimelineLine}

  ${stepTimelineNumber}
`;

const MethodologyStep = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 12px 20px;
  border: 1px solid ${colors.neutral10};
  border-radius: 4px;

  & > :nth-child(2) {
    flex: 1;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO(Jenky): To add missing links
const GeneratedAudienceStatusInfoSteps = ({ stepType }: { stepType: ModalStep }) => {
  const onGuideClick = () => window.open(AUDIENCES_METHODOLOGIES_GUIDE_URL, '_blank');
  const onZoomWithExpertClick = () => window.open(AUDIENCES_ZOOM_WITH_EXPERT_URL, '_blank');

  const setStepOneHeader = () => {
    if (stepType === ModalStep.Audience) {
      return 'Create a new prospecting campaign with an ad set for each Proxima audience';
    }
    if (stepType === ModalStep.Campaign) {
      return 'To get the most out of your new audiences, review our best practices:';
    }
    return '';
  };

  const setStepTwoHeader = () => {
    if (stepType === ModalStep.Audience) {
      return 'Use your top-performing prospecting creative and set each ad set’s daily budget to 2x your in-platform CPA';
    }
    if (stepType === ModalStep.Campaign) {
      return 'Duplicate in your top-performing creative';
    }
    return '';
  };

  return (
    <StepsWrapper>
      <Step>
        <Text variant="body2Semibold">{setStepOneHeader()}</Text>
        {stepType === ModalStep.Audience && (
          <Text variant="body2">
            To get the most out of your new audiences, review our best practices:
          </Text>
        )}
        <MethodologyStep>
          <Methodologies />
          <Text variant="body2Semibold">Proxima audiences testing methodologies</Text>
          <Button variant="outlined" label="View guide" onClick={onGuideClick} />
        </MethodologyStep>
        <MethodologyStep>
          <PersonalGuidance />
          <TextWrapper>
            <Text variant="body2Semibold">Get personalized guidance</Text>
            <Text variant="body3Semibold" color="green60">
              30% better results
            </Text>
          </TextWrapper>
          <Button variant="outlined" label="Zoom with an expert" onClick={onZoomWithExpertClick} />
        </MethodologyStep>
      </Step>
      <Step>
        <Text variant="body2Semibold">{setStepTwoHeader()}</Text>
        {stepType === ModalStep.Audience && (
          <Text variant="body2">
            Review&nbsp;
            <ProximaLink to={AUDIENCES_BUDGETING_GUIDE_URL}>our budgeting guide</ProximaLink>
            &nbsp;for additional recommendations.
          </Text>
        )}
      </Step>
      <Step>
        <Text variant="body2Semibold">
          Wait 4 days before scaling your best performing audiences
        </Text>
      </Step>
      <Step>
        <Text variant="body2Semibold">
          New audiences are available every Monday and can be generated as needed
        </Text>
      </Step>
    </StepsWrapper>
  );
};

export default GeneratedAudienceStatusInfoSteps;
