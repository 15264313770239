import { IntegrationType } from 'constants/integrations';
import { useState } from 'react';
import { PropsOf } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import ShopifyIntegrationModal from 'ui/Modals/ShopifyIntegrationModal';
import FacebookIntegrationModal from 'ui/Modals/FacebookIntegrationModal';
import { useIntegrationsStore } from 'stores';
import { useIntegrations } from 'hooks';
import { IntegrationStatus } from 'types/stores/integration-store';
import IntegrationDisconnectionWarningBannerView from './IntegrationDisconnectionWarningBanner.View';

type ViewBannerProps = PropsOf<typeof IntegrationDisconnectionWarningBannerView>;
type IntegrationDisconnectBannerProps = Partial<Omit<ViewBannerProps, 'onClick'>>;

const IntegrationModals = {
  [IntegrationType.Shopify]: ShopifyIntegrationModal,
  [IntegrationType.Facebook]: FacebookIntegrationModal,
};

// TODO(Jenky): logic here only works for Facebook, might need to change to include Shopify
const HOME_ROUTE = '/';
const ROUTES_TO_SKIP = ['/setup', '/auth/callback'];

const IntegrationDisconnectionWarningBanner = ({
  footer = true,
  type = IntegrationType.Facebook,
}: IntegrationDisconnectBannerProps) => {
  const location = useLocation();
  const { integrations } = useIntegrationsStore();
  const { getAllIntegrationsByType } = useIntegrations();

  const [open, setOpen] = useState<boolean>(false);
  const onActionClick = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const shouldSkipBanner = () =>
    location.pathname === HOME_ROUTE ||
    ROUTES_TO_SKIP.some((path) => location.pathname.includes(path));
  const fbIntegrations = getAllIntegrationsByType(IntegrationType.Facebook, true);
  const hasNeverIntegrated = integrations.length === 0;
  const hasNeverIntegratedFb = fbIntegrations.length === 0;
  const hasActiveFBIntegration = fbIntegrations.some(
    (integration) => integration.status === IntegrationStatus.Active
  );

  // dont show on specific routes regardless of the status of the integration
  if (shouldSkipBanner()) return null;

  // if no integrations, it means brand is new, so don't show the banner
  if (hasNeverIntegrated) return null;

  // if brand has manually disconnected or no fb integrations associated to it, don't show the banner
  if (hasNeverIntegratedFb) return null;

  // if has healthy fb integration, don't show the banner
  if (hasActiveFBIntegration) return null;

  const IntegrationModal = IntegrationModals[type];

  return (
    <>
      <IntegrationDisconnectionWarningBannerView
        type={type}
        footer={footer}
        onClick={onActionClick}
      />
      <IntegrationModal open={open} onClose={onCloseModal} />
    </>
  );
};

export default IntegrationDisconnectionWarningBanner;
