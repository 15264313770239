import {
  AudienceTableCellRendererProps,
  SeedAudienceTableRowProps,
  SeedAudienceTableRowExtraFunctions,
} from 'types/components/audiences';
import {
  AudienceRowCellActions,
  AudienceRowCellDateCreated,
  AudienceRowCellSeedAudience,
  AudienceRowCellStatus,
} from 'components/Audiences/Layout';

const seedAudiencesRowRenderer = (
  props: AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
    SeedAudienceTableRowExtraFunctions
) => {
  const { row, header } = props;

  if (!header) return null;

  if (header === 'seedAudience') return <AudienceRowCellSeedAudience {...props} />;

  if (header === 'displayStatus') return <AudienceRowCellStatus {...props} />;

  if (header === 'dateCreated') return <AudienceRowCellDateCreated date={row?.createdAt} />;

  if (header === 'actions') return <AudienceRowCellActions {...props} />;

  return <div />;
};

export default seedAudiencesRowRenderer;
