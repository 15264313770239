import { useNavigate } from 'react-router-dom';
import { Form } from '@innovationdepartment/proxima-ui';

import { useBrandStore, useUserStore } from 'stores';

import * as analytics from 'lib/analytics';

import validateUrl from 'utils/validations/validateUrl';
import BrandSetupView from './BrandSetup.View';
import { OrganizationType } from '../constants';
import useBrandSetup from './useBrandSetup';

interface IBrandSetupProps {
  // eslint-disable-next-line react/require-default-props
  pollInterval?: number; // allows to override in unit tests
}

type BrandSetupKeys = 'brandName' | 'organizationType' | 'category' | 'website';
type ErrorDefinitions = { [key in BrandSetupKeys]?: string };

const URL_MAX_CHARACTERS_ALLOWED = 253;

const BrandSetup = ({ pollInterval = 250 }: IBrandSetupProps) => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const { hasBrandStoreInitialized } = useBrandStore();

  const { loading, onCreateBrand, categories } = useBrandSetup({ pollInterval });

  const initialValues: { [key in BrandSetupKeys]: string | undefined } = {
    brandName: '',
    website: '',
    organizationType: 'brand',
    category: undefined, // set as undefined to allow placeholder to show,
  };

  const validate = async (formData: typeof initialValues): Promise<ErrorDefinitions> => {
    const errors: { [key: string]: string } = {};
    if (!formData.brandName?.length) errors.brandName = 'Required';
    if (formData.organizationType !== 'brand') errors.organizationType = 'Invalid';
    if (!formData.category) errors.category = 'Required';
    if (validateUrl(formData.website ?? '')) errors.website = 'Invalid URL format';
    if (!formData.website?.length) errors.website = 'Required';
    if ((formData.website?.length ?? 0) > URL_MAX_CHARACTERS_ALLOWED)
      errors.website = 'URL too long';
    if (formData.organizationType === OrganizationType.Agency) {
      analytics.trackActionEvent({
        action: 'Clicked',
        userId: user!.userId,
        area: 'SignupFlow',
        location: 'BrandSetupModal',
        element: 'AgencyButton',
      });
    }

    return errors;
  };

  const onSubmit = async (formData: typeof initialValues) =>
    onCreateBrand({
      brandName: formData.brandName,
      category: formData.category,
      website: formData.website,
    });

  const onCloseBtnClick = hasBrandStoreInitialized() ? () => navigate(-1) : undefined;

  if (loading) return null;

  return (
    <Form validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
      <BrandSetupView onCloseBtnClick={onCloseBtnClick} categories={categories} />
    </Form>
  );
};

export default BrandSetup;
