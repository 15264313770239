import styled from '@emotion/styled';
import { Text, FormField, colors } from '@innovationdepartment/proxima-ui';

import { UserProfileInputRowProps } from 'types/components/userProfile';

const InputRow = styled.div<{ input: UserProfileInputRowProps }>`
  display: grid;
  grid-template-columns: 180px 326px;
  grid-column-gap: 12px;

  & input {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.neutral100};
    font-weight: 500;
  }

  & > p {
    line-height: 32px;
  }

  // this overrides default styling of input since one of them is disabled
  // but they all look the same regardless of the input state
  ${({ input }) => {
    if (input.disabled) {
      return `
        & label {
          z-index: 2 !important;
        }
        & fieldset {
          border-color: ${colors.neutral60} !important;
          z-index: 1 !important;
          background-color: transparent !important;
        }
      `;
    }
    return '';
  }})
`;

const ProfileInputRow = ({ input }: { input: UserProfileInputRowProps }) => (
  <>
    <InputRow input={input}>
      <Text variant="body1" color="neutral60">
        {input.label}
      </Text>
      {input.alternateComponent || (
        <FormField
          label={input.label}
          type="input"
          name={input.name}
          format={input.format}
          placeholder={input.label}
          disabled={input.disabled}
          fullWidth
        />
      )}
    </InputRow>
    {input.dividerComponent}
  </>
);

export default ProfileInputRow;
