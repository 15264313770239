import { IntegrationType } from 'constants/integrations';
import { Form } from '@innovationdepartment/proxima-ui';
import { useEffect, useState } from 'react';
import { useAudiencesApi } from 'api';
import {
  CreateNewLookalikeModalProps,
  LookalikeSize,
  SeedAudience,
} from 'types/components/audiences';
import ModalBase from 'ui/ModalBase';
import CreateNewLookalikeModalView from './CreateNewLookalikeModal.View';

const CreateNewLookalikeModal = (props: CreateNewLookalikeModalProps) => {
  const { seedAudience, onCreateNewLookalikeAudience, onClose } = props;
  const [sizes, setSizes] = useState<LookalikeSize[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seedAudienceToUse, setSeedAudienceToUse] = useState<SeedAudience | undefined>(
    seedAudience
  );
  const { loading, getCurrentLookalikeAudienceSizes } = useAudiencesApi();

  const initialValues = {
    lookalikeSize: undefined as number | undefined,
    integrationType: IntegrationType.Facebook,
  };

  const onSubmit = async (formValues: typeof initialValues) => {
    if (!seedAudience) return;

    setIsSubmitting(true);
    onCreateNewLookalikeAudience({
      size: formValues.lookalikeSize ?? 0, // unlikely to happen, but TS complains otherwise
      integrationType: formValues.integrationType,
    });
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.lookalikeSize) errors.lookalikeSize = 'Please select a lookalike size';

    return errors;
  };

  useEffect(() => {
    const fetchSizes = async () => {
      if (!seedAudience) return;

      const sizeResponse = await getCurrentLookalikeAudienceSizes(seedAudience);

      if (sizeResponse.error) return;

      setSizes(sizeResponse.data);
    };

    if (seedAudience) fetchSizes();
  }, [seedAudience]);

  useEffect(() => {
    // handles not to disappear the data when modal is closing
    // as seedAudience is being used as the prop to show the modal
    if (seedAudience) {
      setSeedAudienceToUse(seedAudience);
      setIsSubmitting(false);
    }
  }, [seedAudience]);

  const show = !!seedAudience;

  return (
    <ModalBase
      open={show}
      header={{ padding: 'small' }}
      title="Generate new lookalike"
      onClose={onClose}
    >
      <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        <CreateNewLookalikeModalView
          isSubmitting={isSubmitting}
          onClose={onClose}
          loading={loading}
          sizes={sizes}
          seedAudience={seedAudienceToUse}
        />
      </Form>
    </ModalBase>
  );
};

export default CreateNewLookalikeModal;
