/* https://github.com/DefinitelyTyped/DefinitelyTyped#what-are-declaration-files-and-how-do-i-get-them */
/// <reference types="facebook-js-sdk" />
import { FB_SDK_VERSION } from 'constants/defaults';
import { useEffect } from 'react';

type FacebookLoginResponse = (response: fb.StatusResponse) => void;

/* entire hook has been mocked on 'setupTests.ts' */
const useFacebookLoginForBiz = () => {
  const onFacebookLoginClick = (onResponse: FacebookLoginResponse) => async () => {
    window.FB.getLoginStatus((response) => {
      /* if trying to connect from same tab after skipping steps in FB oauth dialog, this handles error where its connected in sdk */
      if (response.status === 'connected') {
        window.FB.logout();
      }

      window.FB.login(onResponse, {
        config_id: import.meta.env.VITE_FACEBOOK_AUTH_CONFIG_ID || '',
        response_type: 'code',
        override_default_response_type: true,
      });
    });
  };

  useEffect(() => {
    /* https://developers.facebook.com/docs/javascript/quickstart/ */
    /* https://developers.facebook.com/docs/facebook-business-extension/fbe/get-started/business-login/#biz_login_via_sdk */
    const scriptEl = document.createElement('script');
    scriptEl.src = 'https://connect.facebook.net/en_US/sdk.js';
    scriptEl.async = true;
    scriptEl.defer = true;
    scriptEl.crossOrigin = 'anonymous';

    scriptEl.onload = () => {
      window.FB.init({
        appId: import.meta.env.VITE_FACEBOOK_APP_ID || '',
        version: FB_SDK_VERSION,
        xfbml: true,
      });
    };

    document.body.append(scriptEl);

    return () => {
      scriptEl.remove();
    };
  }, []);

  return {
    onFacebookLoginClick,
  };
};

export default useFacebookLoginForBiz;
