import {
  AUDIENCES_QUICK_START_GUIDE_URL,
  AUDIENCES_VIDEO_GUIDE_URL,
  AUDIENCES_ZOOM_WITH_EXPERT_URL,
} from 'constants/urls';
import React from 'react';
import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  Text,
  Video,
  colors,
  Add as AddIcon,
  Phone as PhoneIcon,
  Knowledge as KnowledgeIcon,
} from '@innovationdepartment/proxima-ui';
import { AudiencesRecommendedActionsProps } from 'types/components/audiences';

const Container = styled.div`
  margin-top: -12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const NewAudiencesBadgeWrapper = styled.div`
  top: -8px;
  left: -8px;
  position: absolute;
  border-radius: 4px;
  background: linear-gradient(90deg, #1ce8ab 0%, #cdf0f4 100%);
  padding: 0 6px;
`;

const ButtonWrapper = styled.div<{ alignCenter: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${({ alignCenter }) => {
    if (alignCenter) {
      return `
        & path {
          fill: ${colors.white};
        }
        align-items: center;
        jusfity-content: center;
      `;
    }

    return `
      height: 100%;
      align-items: flex-start;
      text-align: initial;
    `;
  }}
`;

// TODO(Jenky): Move to UI library ???
const NewAudiencesBadge = () => (
  <NewAudiencesBadgeWrapper>
    <Text color="bigBangBlack" variant="overline1">
      New audiences ready
    </Text>
  </NewAudiencesBadgeWrapper>
);

const RecommendedActionButton: React.FC<{
  alignCenter?: boolean;
  onClick: () => void;
}> = ({ children, onClick, alignCenter = false }) => (
  <Button variant={alignCenter ? 'contained' : 'outlined'} size="large" onClick={onClick} label="">
    <ButtonWrapper alignCenter={alignCenter}>{children}</ButtonWrapper>
  </Button>
);

const AudiencesRecommendedActions: React.FC<AudiencesRecommendedActionsProps> = ({
  showNewGalaxiesBadge,
  showCreateModal,
}) => {
  const actions = [
    {
      isBannerButton: true,
      icon: <AddIcon />,
      title: 'Generate new audiences',
      onClick: () => showCreateModal(),
      alignCenter: true,
      textProps: {
        color: 'white' as PropsOf<typeof Text>['color'],
      },
    },
    {
      icon: <KnowledgeIcon />,
      title: 'Quickstart guide',
      subText: 'Get up and running with your audiences.',
      onClick: () => window.open(AUDIENCES_QUICK_START_GUIDE_URL, '_blank'),
    },
    {
      icon: <Video />,
      title: 'Video guide',
      subText: 'Campaign setup and testing methodologies.',
      onClick: () => window.open(AUDIENCES_VIDEO_GUIDE_URL, '_blank'),
    },
    {
      icon: <PhoneIcon />,
      title: 'Zoom with an expert',
      subText: 'Personalized support to improve ad performance.',
      onClick: () => window.open(AUDIENCES_ZOOM_WITH_EXPERT_URL, '_blank'),
    },
  ];

  return (
    <Container>
      <Text variant="subhead1">Recommended actions</Text>
      <ActionsWrapper>
        {actions.map(
          ({ title, isBannerButton, subText, icon, textProps, alignCenter, onClick }) => {
            const showBadge = isBannerButton && showNewGalaxiesBadge;
            return (
              <RecommendedActionButton alignCenter={alignCenter} onClick={onClick} key={title}>
                {showBadge && <NewAudiencesBadge />}
                {icon}
                <Text variant="body2Semibold" color="neutral80" {...textProps}>
                  {title}
                </Text>
                {subText && (
                  <Text variant="body2" color="neutral60">
                    {subText}
                  </Text>
                )}
              </RecommendedActionButton>
            );
          }
        )}
      </ActionsWrapper>
    </Container>
  );
};

export default AudiencesRecommendedActions;
