import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import { KLAVIYO_API_KEYS_URL } from 'constants/urls';

const InstructionsWrapper = styled.div`
  border-radius: 8px;
  background-color: ${colors.neutral0};
  border: 1px solid ${colors.neutral10};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 640px;
  margin-bottom: 32px;
`;

const InstructionsList = styled.ul`
  list-style-type: number;
  list-style-position: outside;
  padding: 0 24px;
  margin: 0;

  /*
    forces list items bullet points to be on Manrope (and defaults) style
    default behavior is the default browser font family
  */
  font-family: Manrope, Helvetica, Arial, sans-serif;
`;

const InstructionsExternaLink = styled.a`
  cursor: pointer;
  color: ${colors.blue70};

  &:visited {
    color: ${colors.blue70};
  }

  &:is(:hover, :focus, :link) {
    color: ${colors.blue70};
  }

  text-decoration: none;
`;

const INSTRUCTIONS = [
  <>
    To find your Klaviyo API Keys, navigate to&nbsp;
    <InstructionsExternaLink href={KLAVIYO_API_KEYS_URL} target="_blank" rel="noreferrer noopener">
      Settings &gt; Account &gt; API Keys
    </InstructionsExternaLink>
    &nbsp;in Klaviyo
  </>,
  'Copy your Public API Key (also known as Site ID) and paste it below where indicated',
  'Create a Private API Key in Klaviyo, name it “Proxima”, grant Full access to all scopes, and paste it below where indicated',
  'Click Connect',
];

const ConnectKlaviyoAccountInstructions = () => (
  <InstructionsWrapper>
    <Text color="neutral90" variant="body1Semibold">
      Instructions
    </Text>
    <InstructionsList>
      {INSTRUCTIONS.map((instruction, index) => (
        <li key={`${index.toString()}`}>
          <Text color="neutral90" variant="body2Medium">
            {instruction}
          </Text>
        </li>
      ))}
    </InstructionsList>
  </InstructionsWrapper>
);

export default ConnectKlaviyoAccountInstructions;
