import { IntegrationType } from 'constants/integrations';
import { useEffect, useState } from 'react';
import { Form, useToaster } from '@innovationdepartment/proxima-ui';
import { useAdAccountStore } from 'stores';
import {
  GenerateNewAudiencesProps,
  CreateSeedAudienceSchema,
  GenerateAudiencesForm,
  AudiencesQueryStringKeys,
  AudienceStatus,
} from 'types/components/audiences';
import { useQuery } from 'hooks';
import GenerateNewAudiencesView from './GenerateNewAudiences.View';

const DEFAULT_LOOKALIKE_SIZE = 0.03;

const GenerateNewAudiences = (props: GenerateNewAudiencesProps) => {
  const { onCreateNewSeedAudiences, requestAdAccount, onStatusChange } = props;
  const [loading, setLoading] = useState(false);
  const { adAccount } = useAdAccountStore();
  const { showToaster } = useToaster();
  const { getQueryParams } = useQuery();

  const params = getQueryParams<AudiencesQueryStringKeys>();

  const initialValues: GenerateAudiencesForm = {
    galaxies: [],
    lookalikeSize: Number(
      params.lookalikeSize ?? DEFAULT_LOOKALIKE_SIZE
    ).toString() as unknown as number,
    integrationType: IntegrationType.Facebook,
  };

  const onSubmit = async (formData: typeof initialValues) => {
    if (!adAccount) return;

    // TODO(Jenky): maybe might want to move this to CreateNewAudiencesModal.Container.tsx
    // all logic from here to end

    setLoading(true);
    const data: CreateSeedAudienceSchema = {
      galaxyIds: formData.galaxies.map((galaxy) => galaxy.id),
      integrations: [
        {
          adAccountId: adAccount.accountId,
          integrationType: formData.integrationType,
          size: formData.lookalikeSize ?? 0.01, // unlikely to get here without validation but TS complains
        },
      ],
      seedAudienceType: 'selfService',
    };

    const response = await onCreateNewSeedAudiences(data);

    if (!response) return;

    setLoading(false);

    // on success, set processing status
    if (!response.error) onStatusChange(AudienceStatus.Processing);
    // else, show error on modal, enable back the form
    else showToaster({ message: response.error, variant: 'error' });
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.lookalikeSize) errors.lookalikeSize = 'Please select a lookalike size';

    return errors;
  };

  // only do this when this form is shown
  useEffect(() => {
    requestAdAccount();
  }, []);

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <GenerateNewAudiencesView loading={loading} />
    </Form>
  );
};

export default GenerateNewAudiences;
