import styled from '@emotion/styled';
import { Text, Tooltip } from '@innovationdepartment/proxima-ui';
import { LookalikeSourcesViewProps } from 'types/components/audiences';
import NewAudiencesReadyBadge from './NewAudiencesReadyBadge';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 16px;

  & > svg {
    margin-left: 8px;
  }
`;

const TooltipWrapper = styled.div`
  padding: 4px;
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const BadgeSpacer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const LookalikeSourcesAudiencesHeader = ({ newGalaxies }: LookalikeSourcesViewProps) => (
  <HeaderWrapper>
    <Text variant="body1Semibold" color="neutral100">
      New lookalike audiences
    </Text>
    <Tooltip
      info
      arrow
      placement="bottom"
      title={
        <TooltipWrapper>
          <Text variant="body3">
            We will generate 5 high-value lookalikes for prospecting and send them to your ad
            account.
          </Text>
          <Text variant="body3">
            New audiences can be generated weekly, as the underlying data set is updated every
            Tuesday.
          </Text>
        </TooltipWrapper>
      }
    />
    {newGalaxies && (
      <BadgeSpacer>
        <NewAudiencesReadyBadge />
      </BadgeSpacer>
    )}
  </HeaderWrapper>
);

export default LookalikeSourcesAudiencesHeader;
