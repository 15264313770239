import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import ProtectedRoute from 'Router/ProtectedRoute';
import Debug from 'components/Debug';
import NotFound from 'components/NotFound';
import BrandList from 'components/BrandList';

// Sub Routes
import SetupRouter from 'components/Setup';
import EmailRouter from 'components/Email';
import AccountRouter from 'components/Account';
import SettingsRouter from 'components/Settings';
import TermsRouter from 'components/Terms';
import AuthRouter from 'components/Auth';
import AudiencesRouter from 'components/Audiences';
import Onboarding from 'components/Onboarding';
import Integrations from 'components/Integrations';
import Logout from './LogoutRoute';
import RouterWrapper from './RouterWrapper';
import BrandLoader from './BrandLoader';
import AdAccountSelection from 'components/AdAccountSelection';
import KlaviyoRouter from 'components/Klaviyo';

const Router = () => (
  <RouterWrapper>
    <Routes>
      {/* ---- PRIVATE ROUTES ---- */}
      <Route element={<ProtectedRoute component={Outlet} />}>
        <Route index element={<BrandList />} />
        <Route path="brands" element={<BrandList />} />
        <Route path="brand">
          {/* Routes that need brand info */}
          <Route path=":brandId" element={<BrandLoader />}>
            <Route path="integrations" element={<Integrations />} />
            <Route path="onboarding" element={<Onboarding />} />
            <Route path="audiences/*" element={<AudiencesRouter />} />
            <Route path="settings/*" element={<SettingsRouter />} />
            <Route path="klaviyo/*" element={<KlaviyoRouter />} />
            <Route path="meta">
              <Route path="ad-account" element={<AdAccountSelection />} />
              <Route index element={<Navigate to="ad-account" />} />
            </Route>
            {/* make audiences default when landed inside nested routes */}
            <Route index element={<Navigate to="audiences" />} />
          </Route>
          <Route index element={<Navigate to="/brands" />} />
        </Route>

        <Route path="account/*" element={<AccountRouter />} />
        <Route path="setup/*" element={<SetupRouter />} />
        <Route path="email/*" element={<EmailRouter />} />
        <Route path="debug" element={<Debug />} />
      </Route>

      {/* ---- PUBLIC ROUTES ---- */}
      <Route path="auth/*" element={<AuthRouter />} />
      {/* AUX ROUTE TO LOG USER OUT */}
      <Route path="terms/*" element={<TermsRouter />} />
      <Route path="logout" element={<Logout />} />

      {/* FALLBACK ROUTE */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </RouterWrapper>
);

export default Router;
