import { useEffect, useState } from 'react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useAudiencesApi } from 'api';
import { FlavorCategory } from 'types/components/audiences';
import BrandProfileView from './BrandProfile.View';
import EditBrandProfileModal from 'ui/Modals/EditBrandProfileModal';
import { useShowSpinner } from 'hooks';

const BrandProfile = () => {
  const { getBrandFlavorCategory } = useAudiencesApi();
  const { getAvailableFlavorCategories, updateBrandAudienceSettings, loading } = useAudiencesApi();
  const [flavorCategory, setFlavorCategory] = useState<FlavorCategory>();
  const [flavorCategoryOptions, setFlavorCategoryOptions] = useState([]);
  const [brandProfileModalOpen, setBrandProfileModalOpen] = useState<boolean>(false);
  const { showToaster } = useToaster();

  useShowSpinner({ show: loading });

  const toggleBrandProfileModal = (open: boolean = true) => {
    setBrandProfileModalOpen(open);
  };

  const onUpdateBrandCategory = async (newFlavorCategory: FlavorCategory) => {
    await updateBrandAudienceSettings({ flavorCategoryId: newFlavorCategory.categoryId! });

    setFlavorCategory(newFlavorCategory);

    const flavorCategoryMessage = `Brand flavor category updated to ${newFlavorCategory.categoryName}.`;
    showToaster({ message: flavorCategoryMessage, variant: 'success' });
  };

  useEffect(() => {
    const fetchCategory = async () => {
      const flavor = await getBrandFlavorCategory();
      if (!flavor) return;

      setFlavorCategory(flavor);
    };

    const fetchFlavorCategories = async () => {
      const response = await getAvailableFlavorCategories();
      if (!response) return;

      const flavorCategories = response.data;

      const flavorCategoriesOptions = flavorCategories.map(
        (flavorCategoryOption: FlavorCategory) => ({
          label: flavorCategoryOption.name,
          value: flavorCategoryOption.id,
        })
      );

      setFlavorCategoryOptions(flavorCategoriesOptions);
    };

    fetchFlavorCategories();

    fetchCategory();
  }, []);

  return (
    <>
      <BrandProfileView
        flavorCategory={flavorCategory}
        onShowBrandProfileModal={() => toggleBrandProfileModal(true)}
      />
      <EditBrandProfileModal
        open={brandProfileModalOpen}
        currentFlavorCategory={flavorCategory!}
        flavorCategoryOptions={flavorCategoryOptions}
        onClose={() => toggleBrandProfileModal(false)}
        onUpdateBrandCategory={onUpdateBrandCategory}
      />
    </>
  );
};

export default BrandProfile;
