import { useEffect, useRef, useState } from 'react';
import {
  AudienceStatus,
  CreateNewAudiencesModalProps,
  ModalStep,
} from 'types/components/audiences';
import { CampaignStatus } from 'types/components/audienceCampaign';
import CreateNewAudiencesModalView from './CreateNewAudiencesModal.View';

const CreateNewAudiencesModal: React.FC<CreateNewAudiencesModalProps> = (props) => {
  const { status, campaignStatus, stepType, onClose, show } = props;
  const [currentStatus, setCurrentStatus] = useState<AudienceStatus>(status);
  const [currentCampaignStatus, setCurrentCampaignStatus] =
    useState<CampaignStatus>(campaignStatus);
  const [showSkipCampaignModal, setShowSkipCampaignModal] = useState(false);
  const [currentStepType, setCurrentStepType] = useState<ModalStep>(stepType);
  const [currentFeedbackStep, setCurrentFeedbackStep] = useState<ModalStep>(stepType);
  const timeoutRef = useRef<number>(-1);

  if (stepType === ModalStep.Audience && show && currentFeedbackStep === ModalStep.None) {
    setCurrentFeedbackStep(ModalStep.Audience);
  }

  if (stepType === ModalStep.Campaign && show && currentFeedbackStep === ModalStep.None) {
    setCurrentFeedbackStep(ModalStep.Campaign);
  }

  const setModalTitle = () => {
    if (currentStepType === ModalStep.Audience) return 'Generate new audiences';
    if (currentStepType === ModalStep.Campaign) return 'Campaign Setup';
    return '';
  };

  const setModalSubTitle = () => {
    if (currentStepType === ModalStep.Audience && currentStatus === AudienceStatus.New)
      return 'Step 1 of 2';
    if (currentStepType === ModalStep.Campaign && currentCampaignStatus === CampaignStatus.New)
      return 'Step 2 of 2';
    return '';
  };

  const showAudienceStep =
    currentStepType === ModalStep.Audience && currentStatus === AudienceStatus.New;

  const showCampaignStep =
    currentStepType === ModalStep.Campaign && currentCampaignStatus === CampaignStatus.New;

  const showFeedbackStep =
    (currentStepType === ModalStep.Audience &&
      (currentStatus === AudienceStatus.Ready || currentStatus === AudienceStatus.Processing)) ||
    (currentStepType === ModalStep.Campaign &&
      (currentCampaignStatus === CampaignStatus.Ready ||
        currentCampaignStatus === CampaignStatus.Processing));

  const onCampaignModalClose = () => {
    setShowSkipCampaignModal(true);
  };

  const onHandleClose = () => {
    if (currentStepType === ModalStep.Campaign && currentCampaignStatus === CampaignStatus.New) {
      onCampaignModalClose();
    } else {
      if (currentStepType !== ModalStep.None) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
          setCurrentFeedbackStep(ModalStep.None);
        }, 250);
      }
      onClose();
    }
  };

  const onSkipModal = () => {
    setCurrentStepType(ModalStep.Audience);
    setCurrentCampaignStatus(CampaignStatus.None);
    setShowSkipCampaignModal(false);
    setCurrentFeedbackStep(ModalStep.Audience);
  };

  const onAudienceStatusChange = (audienceStatus: AudienceStatus) => {
    if (audienceStatus === AudienceStatus.Processing) {
      setCurrentStatus(audienceStatus);
      setCurrentStepType(ModalStep.Campaign);
    }
    if (audienceStatus === AudienceStatus.Ready) {
      setCurrentStatus(audienceStatus);
      setCurrentStepType(ModalStep.Audience);
    }
  };

  const onCampaignStatusChange = (modifiedCampaignStatus: CampaignStatus) => {
    setCurrentCampaignStatus(modifiedCampaignStatus);
    setCurrentFeedbackStep(ModalStep.Campaign);
  };

  useEffect(() => {
    setCurrentStatus(status);
    setCurrentCampaignStatus(campaignStatus);
    setCurrentStepType(stepType);
    setCurrentFeedbackStep(stepType);
  }, [status, campaignStatus, stepType]);

  return (
    <CreateNewAudiencesModalView
      {...props}
      onStatusChange={onAudienceStatusChange}
      onCampaignStatusChange={onCampaignStatusChange}
      onFormSkipClick={() => setShowSkipCampaignModal(true)}
      status={currentStatus}
      campaignStatus={currentCampaignStatus}
      showSkipCampaignModal={showSkipCampaignModal}
      onSkipModalClose={() => {
        setShowSkipCampaignModal(false);
      }}
      title={setModalTitle()}
      subtitle={setModalSubTitle()}
      showAudienceStep={showAudienceStep}
      showCampaignStep={showCampaignStep}
      showFeedbackStep={showFeedbackStep}
      currentFeedbackStep={currentFeedbackStep}
      onHandleClose={onHandleClose}
      onSkipModal={onSkipModal}
    />
  );
};

export default CreateNewAudiencesModal;
