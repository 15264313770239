import { Chip, Text } from '@innovationdepartment/proxima-ui';
import SideContentItemWrapper from '../SideContentItemWrapper';

const SideContentCategories = ({ categories }: { categories: string[] }) => (
  <SideContentItemWrapper flow="column">
    <Text variant="body1Semibold" color="neutral70">
      Categories
    </Text>
    <SideContentItemWrapper>
      {categories.map((category) => (
        <Chip key={category} label={category} size="small" variant="outlined" />
      ))}
    </SideContentItemWrapper>
  </SideContentItemWrapper>
);

export default SideContentCategories;
