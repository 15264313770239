import { FACEBOOK_SCOPE_PERMISSIONS } from 'constants/defaults';
import { useHandleApi } from 'hooks';
import { useParams } from 'react-router-dom';
import { useAdAccountStore } from 'stores';
import { AdAccount, AdAccountStatus } from 'types/stores/ad-account-store';

type SaveAdAccountProps = {
  brandId: string;
  adAccountId: string;
  data: {
    status: AdAccountStatus;
  };
};

const useFacebookApi = () => {
  const { loading, handleApi } = useHandleApi();
  const adAccountStore = useAdAccountStore();
  const { brandId: paramBrandId } = useParams();

  const areCustomAudienceTosAccepted = async () => {
    const { adAccount } = adAccountStore;

    if (!adAccount) return undefined;

    const response = await handleApi({
      endpoint: `/brand/${paramBrandId}/fb/adAccount/${adAccount.accountId}/tos`,
      method: 'POST',
    });

    return response.data.acceptedTos as Boolean;
  };

  const arePermissionsGranted = async ({
    accessToken,
    facebookUserId,
  }: {
    accessToken: string;
    facebookUserId: string;
  }) => {
    const response = await handleApi({
      endpoint: `/integration/fb/permissions`,
      method: 'POST',
      data: {
        accessToken,
        facebookUserId,
        scope: FACEBOOK_SCOPE_PERMISSIONS,
      },
    });

    if (response.error) {
      throw new Error(response.error);
    }

    return response.data.hasAllRequiredPermissions as Boolean;
  };

  const refreshAdAccounts = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/fb/adAccount/refresh`, brandId, method: 'POST' });

  const getAdAccounts = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/fb/adAccount`, brandId, method: 'GET' });

  const getActiveAdAccount = async (brandId: string) => {
    const response = await getAdAccounts(brandId);

    if (response.error) {
      throw new Error(response.error);
    }

    adAccountStore.updateAdAccountsList(response.data || []);

    const activeAdAccount = ((response.data as AdAccount[] | '') || []).find(
      (adAccount) => adAccount.status === AdAccountStatus.Active
    );

    return activeAdAccount;
  };

  const saveAdAccount = async ({ brandId, adAccountId, data }: SaveAdAccountProps) =>
    handleApi({
      endpoint: `/brand/${brandId}/fb/adAccount/${adAccountId}`,
      brandId,
      method: 'PATCH',
      data,
    });

  return {
    loading,
    areCustomAudienceTosAccepted,
    arePermissionsGranted,
    getAdAccounts,
    saveAdAccount,
    refreshAdAccounts,
    getActiveAdAccount,
  };
};

export default useFacebookApi;
