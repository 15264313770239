import { AxiosResponse } from 'axios';
import { MoreMenu } from '@innovationdepartment/proxima-ui';
import { useBrandStore } from 'stores';

import { useProximaSDK } from 'hooks';
import { AllowedColors } from '@innovationdepartment/proxima-ui/dist/esm/types/colors';
import { IToasterMessage } from '../toasterConfig';

interface IMenuItem {
  label: string;
  disabled?: boolean;
  value: string | number;
  textColor?: AllowedColors;
}

interface IUsersActionMenuProps {
  menuProps: {
    showActions: boolean;
    userId: string;
    verified: boolean;
    disabled: boolean;
    fetchBrandUsers: () => void;
    setLoading: (loading: boolean) => void;
    onClose?: () => void;
    showToaster: (args: IToasterMessage) => void;
  };
}

const UsersActionMenu = ({ menuProps }: IUsersActionMenuProps) => {
  const brandsApi = useProximaSDK('BrandsApi');
  const {
    onClose,
    userId,
    /* verified, */ disabled,
    fetchBrandUsers,
    setLoading,
    showToaster,
    showActions,
  } = menuProps;

  const { brand } = useBrandStore();

  const removeItem: IMenuItem = {
    disabled,
    label: 'Remove',
    value: 'remove',
    textColor: 'red60',
  };

  // TODO: Resend invite menu item to be added in later version
  // const resendInviteItem: IMenuItem = { label: 'Resend invite', value: 'resend' };

  const items = [removeItem];
  // TODO: Resend invite menu item to be added in later version
  // if (!verified) items = [resendInviteItem, removeItem];

  const resendInvite = () => {
    setLoading(true);
    /* TODO(Jenky): Send invitation here. */
    setLoading(false);
  };

  const removeUser = async () => {
    setLoading(true);
    let response: AxiosResponse<void, any>;

    try {
      response = await brandsApi.deleteUserFromBrand({ brandId: brand.brandId, userId });
    } catch (e) {
      setLoading(false);
      return;
    }

    setLoading(false);

    if (response.status === 204) {
      await fetchBrandUsers();
      showToaster({ message: 'User removed successfully.', variant: 'success' });
    }
  };

  const actionsByValue: { [key: string]: () => void } = {
    resend: resendInvite,
    remove: removeUser,
  };

  const handleClick = (value: any) => {
    const action = actionsByValue[value];
    if (action) action();
  };

  return <MoreMenu hide={!showActions} onClose={onClose} items={items} onClick={handleClick} />;
};

export default UsersActionMenu;
