import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { useBackgroundConditions } from 'hooks';
import IntegrationDisconnectionWarningBanner from 'ui/IntegrationDisconnectionWarningBanner';
import TrialEndedContainer from 'ui/TrialEnded';
import LockedBrandBanner from 'ui/LockedBrandBanner';
import { backgroundSvgBubbles } from './constants';

const Container = styled.div<{ showCurve: boolean; showBackdrop: boolean }>`
  flex: 1;
  overflow: auto;
  position: relative;
  border-radius: ${({ showCurve }) => (showCurve ? '16px 0 0' : '0px')};
  ${(props) => {
    if (props.showBackdrop) {
      return `
        background-image: url("${backgroundSvgBubbles}");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left top;
      `;
    }

    return `background-color: ${colors.neutral5};`;
  }}
`;

const RouterWrapperView: React.FC = ({ children }) => {
  const { skipNavbar, showBackdrop } = useBackgroundConditions();

  return (
    <Container showCurve={!skipNavbar} showBackdrop={showBackdrop}>
      {!skipNavbar && <LockedBrandBanner />}
      {!skipNavbar && <TrialEndedContainer />}
      {children}
      {!skipNavbar && <IntegrationDisconnectionWarningBanner />}
    </Container>
  );
};

export default RouterWrapperView;
