import { Routes, Route, Navigate } from 'react-router-dom';

import UserProfile from 'components/UserProfile';
import Account from './Account.Container';

const AccountRouter = () => (
  <Routes>
    <Route element={<Account />}>
      <Route path="general" element={<UserProfile />} />
      <Route index element={<Navigate to="general" />} />
    </Route>
  </Routes>
);

export default AccountRouter;
