import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import { Link } from 'react-router-dom';

type AvailableColors = 'default' | 'gray';
const StyledLink = styled(Text)<{ linkColor?: AvailableColors }>`
  color: ${({ linkColor: c }) => (c === 'gray' ? colors.neutral60 : colors.blue70)};
  transition: color 0.1s ease-in-out;
  display: inline;

  &:hover {
    color: ${({ linkColor: c }) => (c === 'gray' ? colors.neutral70 : colors.blue80)} !important;
  }
`;

const StyledAnchor = styled.a`
  &,
  &:is(:visited, :active) {
    color: ${colors.neutral80};
  }
`;

const ProximaLink: React.FC<{ to: string | `/${string}`; color?: AvailableColors }> = ({
  children,
  color,
  to,
}) => {
  const isExternal = !to.startsWith('/');

  let content = children;

  if (typeof children === 'string') {
    content = (
      <StyledLink linkColor={color} variant="body2Link">
        {children}
      </StyledLink>
    );
  }

  if (isExternal) {
    return (
      <StyledAnchor href={to} target="_blank" rel="noreferrer">
        {content}
      </StyledAnchor>
    );
  }

  return <Link to={to}>{content}</Link>;
};

export default ProximaLink;
