import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

import { Header } from 'ui/Header';

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: ${colors.legacy.white};
  padding: 0 32px 32px 32px;
  gap: 32px;
`;

type HeaderProps = PropsOf<typeof Header>;

type SettingsSectionLayoutProps = HeaderProps;

const SettingsSectionLayout: React.FC<SettingsSectionLayoutProps> = (props) => {
  const { title, button, children, titleAppendText } = props;

  return (
    <Layout>
      <Header title={title} titleAppendText={titleAppendText} button={button} />
      {children}
    </Layout>
  );
};

export default SettingsSectionLayout;
