import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import IncludedSection from './Layout/IncludedSection.View';
import PlanTypeBox from './Layout/PlanTypeBox.View';

type MonthlyPlanViewProps = {
  onSubmit: () => void;
  loading: boolean;
};

const PlanInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PercentageWrapper = styled.div`
  display: flex;
  gap: 4px;

  h3 {
    line-height: 36px;
  }

  span {
    margin-top: auto;
  }
`;

const options = [
  'Only pay for the audiences you use',
  'Monthly billing',
  'No commitment',
  'Email support',
  'Unlimited seats',
];

const MonthlyPlanView = ({ onSubmit, loading }: MonthlyPlanViewProps) => (
  <>
    <PlanTypeBox text="Starter" />
    <PlanInformationWrapper>
      <PercentageWrapper>
        <Text variant="h3">10%</Text>
        <Text variant="body1Semibold">of spend</Text>
      </PercentageWrapper>
      <Text variant="body2" color="neutral50">
        Fee only applies to spend on Proxima audiences
      </Text>
    </PlanInformationWrapper>
    <IncludedSection
      buttonDisabled={loading}
      onSubmit={onSubmit}
      buttonText={loading ? 'Loading...' : 'Continue'}
      options={options}
    />
  </>
);

export default MonthlyPlanView;
