import YearlyPlanView from './YearlyPlan.View';
import { useBillingApi } from 'api';
import { useBilling } from 'hooks';
import { SubscriptionType } from 'types/components/subscriptions';

type YearlyPlanProps = {
  brandId: string;
};

const YearlyPlan = ({ brandId }: YearlyPlanProps) => {
  const { loading, updateSubscription } = useBillingApi();
  const { loading: navigationLoading, navigateToCustomerPortal } = useBilling();

  const onSubmit = async () => {
    if (brandId) {
      await updateSubscription(brandId, SubscriptionType.Yearly);
      await navigateToCustomerPortal(brandId);
    }
  };
  return <YearlyPlanView onSubmit={onSubmit} loading={loading || navigationLoading} />;
};

export default YearlyPlan;
