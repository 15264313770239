import React, { useCallback, useMemo, useState } from 'react';
import { Spinner } from '@innovationdepartment/proxima-ui';
import { ISpinnerContext } from 'types/context/spinnerContext';

export const SpinnerWrapperContext = React.createContext<ISpinnerContext>({} as any);

const SpinnerWrapperProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const showSpinner = useCallback((show: boolean = false) => setLoading(show), []);

  const value = useMemo(() => ({ showSpinner }), []);

  return (
    <SpinnerWrapperContext.Provider value={value}>
      <Spinner loading={loading} />
      {children}
    </SpinnerWrapperContext.Provider>
  );
};

export default SpinnerWrapperProvider;
