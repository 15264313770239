import MonthlyPlanView from './MonthlyPlan.View';
import { SubscriptionType } from 'types/components/subscriptions';
import { useBillingApi } from 'api';
import { useBilling } from 'hooks';

type MonthlyPlanProps = {
  brandId: string;
};

const MonthlyPlan = ({ brandId }: MonthlyPlanProps) => {
  const { loading, updateSubscription } = useBillingApi();
  const { loading: navigationLoading, navigateToCustomerPortal } = useBilling();

  const onSubmit = async () => {
    await updateSubscription(brandId, SubscriptionType.Monthly);
    await navigateToCustomerPortal(brandId);
  };

  return (
    <MonthlyPlanView key={brandId} onSubmit={onSubmit} loading={loading || navigationLoading} />
  );
};

export default MonthlyPlan;
