import { LookalikeSizeProps } from 'types/components/audiences';
import LookalikeSizeView from './LookalikeSize.View';

const INTERVAL = 0.01;
const RECOMMENDED_VALUE = 0.03;
const MAX_AVAILABLE_SIZE_OPTIONS = 20;
const availableOptions = [...Array(MAX_AVAILABLE_SIZE_OPTIONS)].map(
  (_, val) => (val + 1) * INTERVAL
);

const LookalikeSize = (props: LookalikeSizeProps) => {
  const { loading, disabled, sizes = [] } = props;

  const options = availableOptions.map((option) => {
    const optionDisabled = sizes.includes(option);
    const recommended = option === RECOMMENDED_VALUE;
    const label = Number.parseInt(`${option * 100}`, 10);
    const showRecommended = recommended && !optionDisabled && !sizes.length;

    let sideLabel = '';
    if (showRecommended) sideLabel = ' (Recommended)';
    if (optionDisabled) sideLabel = ' - Already generated';

    return {
      disabled: optionDisabled,
      value: `${option}`,
      label: `${label}%${sideLabel}`,
    };
  });

  return <LookalikeSizeView options={options} loading={loading} disabled={disabled} />;
};

export default LookalikeSize;
