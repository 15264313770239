import { Dispatch, SetStateAction } from 'react';
import { SwitchWithText, colors } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import YearlyPlanView from './YearlyPlan.Container';
import MonthlyPlan from './MonthlyPlan.Container';
import { BillingFrequency } from 'types/billing';

type ToggleStandardPlansViewProps = {
  billingFrequency: BillingFrequency;
  setBillingFrequency: Dispatch<SetStateAction<BillingFrequency>>;
  brandId: string;
};

const BillingFrequencyContainer = styled.div`
  border: 1px solid ${colors.neutral10};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const BillingFrequencySwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const renderBillingFrequencySwitch = (
  setBillingFrequency: ToggleStandardPlansViewProps['setBillingFrequency']
) => (
  <BillingFrequencySwitchContainer>
    <SwitchWithText
      onChange={(value) => setBillingFrequency(value as unknown as BillingFrequency)}
      selectedColors={{ selected: colors.bigBangBlack, unselected: colors.neutral30 }}
      leftSwitch={{ text: 'Monthly', value: 'monthly' }}
      rightSwitch={{ text: 'Yearly', value: 'yearly' }}
    />
  </BillingFrequencySwitchContainer>
);

const renderBillingContent = (billingFrequency: BillingFrequency, brandId: string) =>
  billingFrequency === BillingFrequency.Monthly ? (
    <MonthlyPlan brandId={brandId} />
  ) : (
    <YearlyPlanView brandId={brandId} />
  );

const ToggleStandardPlansView = ({
  billingFrequency,
  setBillingFrequency,
  brandId,
}: ToggleStandardPlansViewProps) => (
  <>
    {renderBillingFrequencySwitch(setBillingFrequency)}
    <BillingFrequencyContainer>
      {renderBillingContent(billingFrequency, brandId)}
    </BillingFrequencyContainer>
  </>
);

export default ToggleStandardPlansView;
