import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { AudienceRowCellErrorStatusCell } from 'types/components/audiences';
import { status as statusUtils } from './utils';

const StatusBadge = styled.div`
  align-self: flex-start;
  padding: 0 6px;
  border-radius: 10px;
  background-color: ${statusUtils.color.Error};
`;

const RetryWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  & span {
    line-height: unset;
  }
`;

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

const RetrySvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#E1E7E7" />
    <g clipPath="url(#clip0_1952_130888)">
      <path
        d="M11.2667 9.06591H15.1667C15.2551 9.06591 15.3399 9.03079 15.4024 8.96828C15.465 8.90577 15.5001 8.82098 15.5001 8.73258V4.83258C15.5001 4.74417 15.465 4.65939 15.4024 4.59687C15.3399 4.53436 15.2551 4.49924 15.1667 4.49924H14.5001C14.4117 4.49924 14.3269 4.53436 14.2644 4.59687C14.2019 4.65939 14.1667 4.74417 14.1667 4.83258V6.35924C13.7077 5.84815 13.1642 5.4197 12.5601 5.09258C11.7696 4.68437 10.8895 4.48041 10.0001 4.49924C9.27604 4.48872 8.55745 4.62575 7.88809 4.90197C7.21873 5.1782 6.61264 5.58783 6.10674 6.10591C5.58866 6.61181 5.17903 7.2179 4.9028 7.88726C4.62658 8.55661 4.48955 9.27521 4.50007 9.99924C4.48955 10.7233 4.62658 11.4419 4.9028 12.1112C5.17903 12.7806 5.58866 13.3867 6.10674 13.8926C6.61264 14.4107 7.21873 14.8203 7.88809 15.0965C8.55745 15.3727 9.27604 15.5098 10.0001 15.4992C11.2014 15.506 12.372 15.1197 13.3334 14.3992C14.1798 13.7797 14.8208 12.9204 15.1734 11.9326C15.1905 11.8821 15.1952 11.8283 15.1871 11.7756C15.1791 11.7229 15.1585 11.6729 15.1272 11.6298C15.0958 11.5867 15.0546 11.5518 15.007 11.5279C14.9594 11.504 14.9067 11.4919 14.8534 11.4926H14.1534C14.0883 11.4924 14.0247 11.5121 13.9711 11.5491C13.9175 11.586 13.8765 11.6384 13.8534 11.6992C13.5524 12.3899 13.0625 12.9815 12.4401 13.4059C11.725 13.9057 10.8725 14.1713 10.0001 14.1659C9.45116 14.1722 8.90654 14.0687 8.39819 13.8615C7.88984 13.6543 7.42802 13.3476 7.03986 12.9595C6.6517 12.5713 6.34502 12.1095 6.13784 11.6011C5.93066 11.0928 5.82715 10.5482 5.83341 9.99924C5.82715 9.45033 5.93066 8.90571 6.13784 8.39736C6.34502 7.88901 6.6517 7.42719 7.03986 7.03903C7.42802 6.65087 7.88984 6.34419 8.39819 6.13701C8.90654 5.92983 9.45116 5.82632 10.0001 5.83258C10.7728 5.81683 11.534 6.02272 12.1934 6.42591C12.7415 6.77381 13.2151 7.227 13.5867 7.75924H11.2667C11.1783 7.75924 11.0936 7.79436 11.031 7.85687C10.9685 7.91939 10.9334 8.00417 10.9334 8.09258V8.75924C10.9401 8.84295 10.9782 8.92103 11.04 8.97789C11.1018 9.03474 11.1828 9.06618 11.2667 9.06591V9.06591Z"
        fill="#0056D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1952_130888">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const AudienceRowCellErrorStatus = ({ onRetry }: AudienceRowCellErrorStatusCell) => (
  <HorizontalWrapper>
    <StatusBadge>
      <Text variant="tag2Medium" color="neutral80">
        Error
      </Text>
    </StatusBadge>
    <RetryWrapper onClick={onRetry}>
      <RetrySvg />
      <Text variant="tag2Medium" color="blue70">
        Retry
      </Text>
    </RetryWrapper>
  </HorizontalWrapper>
);

export default AudienceRowCellErrorStatus;
