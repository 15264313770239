import { useEffect, useState } from 'react';
import { CustomTosStep } from 'types/components/audiencesCustomTos';
import { useProximaSDK } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { FACEBOOK_CUSTOM_AUDIENCE_TERMS_OF_SERVICE_URL } from 'constants/urls';
import { useToaster } from '@innovationdepartment/proxima-ui';

import * as analyticsLib from 'lib/analytics';
import { useBrandStore, useUserStore } from 'stores';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_USER_ID_NAMESPACE } from 'constants/defaults';

type UseCheckMetaCustomTosDefaultProps = {
  analytics: {
    location: string;
    area: string;
  };
  redirectTo?: string;
  adAccountId?: string;
  type: string;
};

type UseCheckMetaCustomTosProps =
  | (UseCheckMetaCustomTosDefaultProps & { type: 'page' })
  | (UseCheckMetaCustomTosDefaultProps & { type: 'modal'; show: boolean });

/*
  this hook is used to follow the logic for check custom audiences terms of services for Meta

  the logic on how to use this hook is as follows:
   1. first use 'onAcceptTermsClick' to open the terms of services in a new tab
      this will set the step to 'UserClickedAccepted', meaning the user was taken to the terms of services
      for them to accept
   2. second, use 'onTermsAlreadyAcceptedClick' to check if the user has accepted the terms of services
      by requesting the backend to check if the user has accepted the terms
   3. if user accepted, then redirect to the next page if 'redirectTo' is provided, else return true
   4. if user has not accepted, then show the toaster message and set the step back to 'AcceptTerms', and return false
   5. every action is tracked using the analytics lib

  since this logic was copy/paste in various places, I think this will be idea to reuse at a future point if needed
*/
const useCheckMetaCustomTos = (props: UseCheckMetaCustomTosProps) => {
  const { analytics, redirectTo, adAccountId, type } = props;
  const { user: storeUser } = useUserStore();
  const { user } = useAuth0();
  const { brandId: paramsBrandId } = useParams();
  const { brand } = useBrandStore();
  const { showToaster } = useToaster();
  const navigate = useNavigate();

  const [step, setStep] = useState<CustomTosStep>(CustomTosStep.AcceptTerms);
  const [loading, setLoading] = useState<boolean>(false);

  const userId = storeUser?.userId ?? user![AUTH0_USER_ID_NAMESPACE];
  const brandId = paramsBrandId ?? brand?.brandId;

  const fbIntegrationApi = useProximaSDK('FbIntegrationApi', { brandId });

  /* when clicked on "do it later" button */
  const onSkipClick = () => {
    analyticsLib.trackActionEvent({
      action: 'clicked',
      userId,
      brandId,
      area: analytics.area,
      location: analytics.location,
      element: 'SkipButton', // "DoItLaterButton" instead?
    });
  };

  /* when user acknowledged terms were accepted, yet our records say otherwise */
  const onTermsNotYetAccepted = () => {
    /* TODO(Jenky): 1/3/2024 - removed until further notice */
    // analyticsLib.trackActionEvent({
    //   action: 'clicked on already accepted, terms not yet accepted',
    //   userId,
    //   brandId,
    //   area: analytics.area,
    //   location: analytics.location,
    //   element: 'AlreadyAcceptedButton',
    // });
  };

  const onAcceptTermsClick = async () => {
    const query = adAccountId ? `?act=${adAccountId.replace('act_', '')}` : '';
    window.open(`${FACEBOOK_CUSTOM_AUDIENCE_TERMS_OF_SERVICE_URL}${query}`, '_blank');

    setTimeout(() => {
      setStep(CustomTosStep.UserClickedAccepted);
    }, 1000);
  };

  const onTermsAlreadyAcceptedClick = async () => {
    if (!adAccountId) return false;

    if (!brandId) return false;

    setLoading(true);

    try {
      const response = await fbIntegrationApi.checkAdAccountTos({
        adAccountId,
        brandId,
      });

      if (response?.data?.acceptedTos) {
        if (redirectTo) navigate(redirectTo);
        return response.data.acceptedTos;
      }

      onTermsNotYetAccepted();
    } catch (err) {
      /* */
    }

    showToaster({ variant: 'warning', message: 'There was an error. Please try again.' });
    setLoading(false);
    setStep(CustomTosStep.AcceptTerms);
    return false;
  };

  useEffect(() => {
    if (userId && adAccountId) {
      if (type === 'page') {
        /* for future use */
      }
      if (type === 'modal' && props.show) {
        analyticsLib.trackActionEvent({
          userId,
          brandId,
          location: analytics.location,
          area: analytics.area,
          action: 'modal shown',
        });
      }
    }
  }, [userId, adAccountId, type]);

  return {
    loading,
    step,

    onTermsAlreadyAcceptedClick,
    onAcceptTermsClick,
    onSkipClick,
  };
};

export default useCheckMetaCustomTos;
