import { LookalikeSourcesViewProps } from 'types/components/audiences';
import LookalikeSourcesAudiencesHeader from './LookalikeSourcesAudiences.Header';
import LookalikeSourcesAudiencesLookalikes from './LookalikeSourcesAudiences.Lookalikes';

const LookalikeSourcesAudiencesView = (props: LookalikeSourcesViewProps) => (
  <>
    <LookalikeSourcesAudiencesHeader {...props} />
    <LookalikeSourcesAudiencesLookalikes {...props} />
  </>
);

export default LookalikeSourcesAudiencesView;
