import styled from '@emotion/styled';
import SettingsLayout from 'ui/SettingsSectionLayout';
import LoggedInAsBrandHeader from 'ui/LoggedInAsBrandHeader';
import { Brand } from 'types/stores/brand-store';
import BrandProfile from './BrandProfile';
import BrandInfo from './BrandInfo';

const GeneralViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  width: 100%;
  overflow-y: auto;
`;

type GeneralBrandSettingsViewProps = {
  brand: Brand;
};

const GeneralBrandSettingsView = ({ brand }: GeneralBrandSettingsViewProps) => {
  const titleAppendText = brand.name ? <LoggedInAsBrandHeader /> : undefined;

  return (
    <SettingsLayout title="General" titleAppendText={titleAppendText}>
      <GeneralViewWrapper>
        <BrandInfo />
        <BrandProfile />
      </GeneralViewWrapper>
    </SettingsLayout>
  );
};

export default GeneralBrandSettingsView;
