import { useBrandStore } from 'stores';

import BrandInfoView from './BrandInfo.View';

const BrandInfo = () => {
  const { brand } = useBrandStore();

  return <BrandInfoView brandName={brand.name} brandWebsite={brand.domain} />;
};

export default BrandInfo;
