import * as proximaSdk from '@innovationdepartment/proxima-sdk-axios';
import { useBrandStore } from 'stores';
import { useEffect, useState } from 'react';
import useAccessToken from './useAccessToken';

type ExtractApiMembers<Module> = {
  [Name in keyof Module]: Name extends `${string}Api` ? Name : never;
}[keyof Module];

type SDKApiName = ExtractApiMembers<typeof proximaSdk>;
type SDKOptions = {
  brandId?: string;
  ignoreCache?: boolean;
};

type EndpointInstance<T extends SDKApiName> = InstanceType<(typeof proximaSdk)[T]> & {
  loading: boolean;
};

const { Configuration } = proximaSdk;

const shouldIgnoreCacheToRetrieveToken = (
  brandId: string | undefined,
  storeBrandId: string | undefined
) => {
  /* if already stored brand id and different from the one being passed on, return true */
  if (storeBrandId) {
    /* this means user is switching between brands */
    if (brandId !== undefined) return brandId !== storeBrandId;
    /* dont reset cache once in the app, this is a normal sdk request */
    if (brandId === undefined) return false;
  }

  /* this is a new brand id to set in the token */
  if (brandId !== undefined) return true;

  /* default value - rely on already cached token (if any) */
  return false;
};

const useProximaSDK = <T extends SDKApiName>(
  apiName: T,
  opts: SDKOptions = {}
): EndpointInstance<T> => {
  const { brand } = useBrandStore();
  const [brandId, setBrandId] = useState<string | undefined>(brand.brandId || undefined);
  const { getAccessToken } = useAccessToken();

  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const proxy = new Proxy(
    {},
    {
      get(_, prop) {
        if (prop === 'loading') {
          // if any element of this sdk instance is loading, return true
          return Object.values(loading).some((value) => value);
        }

        const ignoreCache =
          opts.ignoreCache ?? shouldIgnoreCacheToRetrieveToken(opts.brandId, brandId);

        return async (...props: any[]) => {
          const brandIdToUse = opts.brandId ?? brandId;

          const { accessToken } = await getAccessToken({
            brandId: brandIdToUse,
            refresh: ignoreCache,
          });

          // create proxima sdk configuration
          const config = new Configuration({
            accessToken,
            baseOptions: { headers: { Accept: 'application/json' } },
            basePath: import.meta.env.VITE_PROXIMA_API_URL,
          });

          // create proxima sdk api instance
          const apiInstance = new proximaSdk[apiName](config);

          // call proxima sdk api method
          const method = apiInstance[prop as keyof {}];

          try {
            setLoading((prev) => ({ ...prev, [prop]: true }));

            // return proxima sdk api method result
            // use unknown to avoid typescript error
            // use .call to allow passing in the api instance as the this context
            const result = await (method as unknown as Function).call(
              apiInstance,
              ...(props satisfies any[])
            );
            setLoading((prev) => ({ ...prev, [prop]: false }));
            return result;
          } catch (error) {
            setLoading((prev) => ({ ...prev, [prop]: false }));
            throw error;
          }
        };
      },
    }
  );

  /* only clear cache when brandId changes */
  useEffect(() => {
    if (opts.brandId !== brandId) {
      setBrandId(opts.brandId || undefined);
    }
  }, [opts]);

  // return proxima sdk api instance
  // make typescript happy by casting to the correct type called by the api name
  return proxy as EndpointInstance<T>;
};

export default useProximaSDK;
