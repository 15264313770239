import { AnalyticsBrowser, Context } from '@segment/analytics-next';
import z from 'zod';

type Primitive = boolean | string | number;

type UserEvent = {
  userId: string;
  traits: Record<string, Primitive> & { action: string };
};

type ActionEvent = {
  action: string;
  userId: string;
  fromUserId: string;
  brandId?: string;
  area?: string;
  location?: string;
  element?: string;
};

type ActionEventArgs = Omit<ActionEvent, 'fromUserId'> & { fromUserId?: string };
type PageVisitArgs = Omit<ActionEventArgs, 'action'>;

const EventSchema = z.object({
  detail: z.object({
    action: z.string().min(1),
    actionByUserId: z.string(),
    brandId: z.string().optional(),
    // hopefully zod will support iso dates in the future
    // https://github.com/colinhacks/zod/issues/126
    createdAt: z.string(),
    userId: z.string(),
    area: z.string().optional(),
    location: z.string().optional(),
    element: z.string().optional(),
    // Only change this value if this zod schema changes
    schema: z.literal('v1.2'),
  }),
  source: z.literal('proxima.web'),
});

type SchemaType = z.infer<typeof EventSchema>;

const generateSchema = (eventInfo: ActionEvent): SchemaType => {
  const eventSchema: SchemaType = {
    detail: {
      action: eventInfo.action,
      actionByUserId: eventInfo.fromUserId,
      brandId: eventInfo.brandId,
      createdAt: new Date().toISOString(),
      userId: eventInfo.userId,
      area: eventInfo.area,
      location: eventInfo.location,
      element: eventInfo.element,
      schema: 'v1.2',
    },
    source: 'proxima.web',
  };

  // Validates schema
  EventSchema.parse(eventSchema);

  return eventSchema;
};

// https://github.com/segmentio/analytics-next/#-using-as-an-npm-package
let analytics: AnalyticsBrowser;
export const initialize = () => {
  analytics = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENTIO_WRITE_KEY! });
};

// https://segment.com/docs/connections/spec/track/
export const trackActionEvent = (eventInfo: ActionEventArgs): Promise<Context> => {
  const event: ActionEvent = { ...eventInfo, fromUserId: eventInfo.fromUserId ?? eventInfo.userId };
  const properties = generateSchema(event);
  return analytics.track(eventInfo.action, properties);
};

// https://segment.com/docs/connections/spec/identify/
export const trackIdentify = (eventInfo: UserEvent): Promise<Context> =>
  analytics.identify(eventInfo.userId, { ...eventInfo.traits });

export const trackPageVisit = (eventInfo: PageVisitArgs): Promise<Context> => {
  const action = 'Page visited';
  const event: ActionEvent = {
    ...eventInfo,
    fromUserId: eventInfo.fromUserId ?? eventInfo.userId,
    action,
  };
  const properties = generateSchema(event);
  return analytics.page(action, properties);
};
