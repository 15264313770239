import styled from '@emotion/styled';
import SettingsSectionLayout from 'ui/SettingsSectionLayout';
import LoggedInAsBrandHeader from 'ui/LoggedInAsBrandHeader';
import { Brand } from 'types/stores/brand-store';
import { BillingInvoiceRecipients, BillingPlan } from './Layout';

const BillingViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  width: 100%;
  overflow-y: auto;
`;

type BillingViewProps = {
  brand: Brand;
};

const BillingView = ({ brand }: BillingViewProps) => {
  const titleAppendText = brand.name ? <LoggedInAsBrandHeader /> : undefined;
  return (
    <SettingsSectionLayout title="Billing" titleAppendText={titleAppendText}>
      <BillingViewWrapper>
        <BillingPlan />
        <BillingInvoiceRecipients brand={brand} />
      </BillingViewWrapper>
    </SettingsSectionLayout>
  );
};

export default BillingView;
