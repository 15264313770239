import IMask from 'imask';

export default function formatPhoneNumber(phoneNumber: string = ''): string {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const mask = new IMask.MaskedPattern({ mask: '(000) 000-0000' });
  const formattedPhone = cleanedPhoneNumber.startsWith('1')
    ? cleanedPhoneNumber.slice(1)
    : cleanedPhoneNumber;
  return mask.resolve(formattedPhone);
}
