import { KlaviyoGalaxy } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import MainContentDescription from '../IntegrationsModal/LayoutComponents/MainContentDescription';

const TITLE = 'Connect your Klaviyo account';
const DESCRIPTION = (
  <>
    Connect Klaviyo to Proxima for deep customer insights.
    <br />
    <br />
    Analyze your owned marketing leads against your top and bottom customer cohorts.
    <br />
    <br />
    Leverage these insights to power advanced targeting models for prospecting and remarketing
    campaigns.
  </>
);

const GraphContainer = styled.div`
  height: calc(100% - 3px); /* graphic is a bit off by 3px */
`;

const FacebookIntegrationModalMainView = () => (
  <>
    <MainContentDescription title={TITLE} description={DESCRIPTION} />
    <GraphContainer>
      <KlaviyoGalaxy />
    </GraphContainer>
  </>
);

export default FacebookIntegrationModalMainView;
