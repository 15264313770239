import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { KLAVIYO_PRIVACY_POLICY_URL, KLAVIYO_TERMS_OF_SERVICE_URL } from 'constants/urls';
import { Button } from '@innovationdepartment/proxima-ui';
import { KlaviyoIntegrationModalProps } from 'types/components/integrations';
import SideContentContainer from '../IntegrationsModal/LayoutComponents/SideContentContainer';
import SideContentTermsAndPrivacy from '../IntegrationsModal/LayoutComponents/SideContentTermsAndPrivacy';
import SideContentCategories from '../IntegrationsModal/LayoutComponents/SideContentCategories';
import IntegrationsModalStatus from '../IntegrationsModal/LayoutComponents/IntegrationsModalStatus';
import styled from '@emotion/styled';

const CATEGORIES = ['Email', 'SMS'];

/* TODO(Jenky): Confirm with Clint */
const LINKS = {
  terms: KLAVIYO_TERMS_OF_SERVICE_URL,
  privacy: KLAVIYO_PRIVACY_POLICY_URL,
};

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const KlaviyoIntegrationModalSideContent = (props: {
  status: KlaviyoIntegrationModalProps['status'];
  onManageClick: KlaviyoIntegrationModalProps['onManageClick'];
}) => {
  const { status, onManageClick } = props;
  const neverConnected = status === BrandIntegrationStatus.NeverConnected;
  const isConnected = status === BrandIntegrationStatus.Connected;

  const buttonLabel = neverConnected ? 'Get started' : 'Manage';

  const topContent: React.ReactNode = (
    <ConnectWrapper>
      <IntegrationsModalStatus type={IntegrationType.Klaviyo} status={status} />
      <Button
        variant={isConnected ? 'outlined' : 'contained'}
        onClick={onManageClick}
        label={buttonLabel}
      />
    </ConnectWrapper>
  );

  return (
    <SideContentContainer>
      {topContent}
      <SideContentCategories categories={CATEGORIES} />
      <SideContentTermsAndPrivacy {...LINKS} />
    </SideContentContainer>
  );
};

export default KlaviyoIntegrationModalSideContent;
