import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { useState } from 'react';
import { useIntegrationApi, useOAuth } from 'api';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useBrandStore } from 'stores';
import { Integration } from 'types/stores/integration-store';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useIntegrations, useStorage } from 'hooks';
import { ProximaApiResponseCode } from 'types/hooks/useHandleApi';
import { ShopifyAppRedirect } from 'types/components/shopify';

const useShopify = () => {
  const { getQueryParams } = useQuery();
  const { getActiveIntegrations, getAllIntegrationsByType } = useIntegrations();
  const navigate = useNavigate();
  const { brandId: paramsBrandId } = useParams<{ brandId?: string }>();
  const { brand } = useBrandStore();
  const { showToaster } = useToaster();
  const OAuth = useOAuth();
  const [pollCount, setPollCount] = useState(0);
  const integrationApi = useIntegrationApi();
  const storage = useStorage(window.localStorage);

  const { brandId: queryBrandId } = getQueryParams<{ brandId: string }>();

  const brandId = paramsBrandId ?? brand?.brandId ?? queryBrandId;

  const getShopifyStatus = () => {
    const shopifyIntegrations = getAllIntegrationsByType(IntegrationType.Shopify);
    const neverConnected = shopifyIntegrations.length === 0;
    if (neverConnected) return BrandIntegrationStatus.NeverConnected;
    const [hasActiveIntegration] = getActiveIntegrations(IntegrationType.Shopify);
    if (hasActiveIntegration) return BrandIntegrationStatus.Connected;
    return BrandIntegrationStatus.Disconnected;
  };

  const pollForIntegration = () => {
    const interval = setInterval(async () => {
      setPollCount(pollCount + 1);
      const result = await integrationApi.getIntegrations(brandId);
      const shopifyIntegration = result.data.find(
        (integration: Integration) => integration.type === IntegrationType.Shopify
      );
      if (shopifyIntegration) {
        clearInterval(interval);
        showToaster({
          variant: 'success',
          message: 'Shopify successfully added',
        });
        setTimeout(() => {
          navigate('/auth/all-set');
        }, 2000);
      }

      // Once we reach a poll count of 30 (should equate to ~30 seconds), throw an error
      if (pollCount >= 30) {
        clearInterval(interval);
        showToaster({ variant: 'warning', message: 'Unable to process shopify integration' });
      }
    }, 1000);
  };

  const extractOAuthParameters = () => {
    const { code, hmac, shop, host, timestamp } = getQueryParams();

    if (!hmac || !shop || !timestamp || !host) {
      showToaster({ message: 'Invalid oauth url', variant: 'warning' });
      return null;
    }

    return {
      code,
      hmac,
      shop,
      host,
      timestamp,
    };
  };

  const setShopifyAccessToken = async () => {
    const data = extractOAuthParameters();
    if (data) {
      const result = await OAuth.createIntegration(brandId, data);
      if (result.status === ProximaApiResponseCode.NoContent) {
        pollForIntegration();
      } else {
        // Request to set access token failed
        navigate(`/auth/callback/shopify/choose-brand${window.location.search}`, { replace: true });
      }
    }
  };

  const generateOAuthUrlWithValidation = async () => {
    const data = extractOAuthParameters();
    if (data) {
      const result = await OAuth.generateShopify0AuthUrlFromAppStore({
        ...data,
        callbackPath: `${window.location.origin}/auth/callback/shopify`,
      });
      if (result.error) {
        showToaster({ message: 'Unable to generate oauth url', variant: 'error' });
      } else {
        window.location.href = result.data.url;
      }
    }
  };

  /* helper function for getShopifyURLRedirect & setShopifyURLRedirect functions */
  const getStringPrefix = (key: ShopifyAppRedirect, brandIdToUse: string | undefined) => {
    if (key === ShopifyAppRedirect.Signup) return '';
    if (brandIdToUse) return `${brandIdToUse}-`;
    return '';
  };

  /**
   * @description gets the redirect url for shopify
   * @param {Object} param0
   * @param {ShopifyAppRedirect} param0.key the key to get the redirect url for
   * @param {boolean} param0.remove whether to remove the key after retrieving the value
   * @param {string} param0.brandId brand id to force the redirect url to (optional if key is ShopifyAppRedirect.Signup)
   * @returns {string | undefined}
   */
  type GetShopifyURLRedirectProps =
    | {
        key?: ShopifyAppRedirect;
        remove?: boolean;
        brandId?: never;
      }
    | {
        key?: ShopifyAppRedirect.Signup;
        remove?: boolean;
        brandId?: string;
      };
  const getShopifyURLRedirect = ({
    key,
    remove = false,
    brandId: brandIdToAttach,
  }: GetShopifyURLRedirectProps) => {
    const skip = Boolean(key) && !brandId && key !== ShopifyAppRedirect.Signup;
    if (skip) return undefined;

    const getKeys = (stringKey: ShopifyAppRedirect) => {
      const prefix = getStringPrefix(stringKey, brandId);
      const pathKey = `${prefix}shopify-${stringKey}-redirect-location`;
      const paramsKey = `${prefix}shopify-${stringKey}-redirect-params`;
      return { pathKey, paramsKey };
    };

    const getUrlFromKey = (redirectKey: ShopifyAppRedirect, deleteKey = remove) => {
      const { pathKey, paramsKey } = getKeys(redirectKey);
      const path = storage.getItem(pathKey, deleteKey);
      const params = storage.getItem(paramsKey, deleteKey);
      if (!path) return undefined;
      return `${path}${params}`;
    };

    if (key) {
      let redirectPath = getUrlFromKey(key);
      /* include brandId into the path to return */
      if (redirectPath && brandIdToAttach !== undefined) {
        const [path, params] = redirectPath.split('?');
        const queryString = new URLSearchParams(params);
        queryString.set('brandId', brandIdToAttach);
        redirectPath = `${path}?${queryString.toString()}`;
      }
      return redirectPath;
    }

    const url = Object.values(ShopifyAppRedirect).reduce(
      (redirectUrl /* accumulator */, redirectKey /* next key */) => {
        const path = getUrlFromKey(redirectKey, true); // remove the key after getting the value
        if (redirectUrl) return redirectUrl;
        return path;
      },
      undefined as string | undefined
    );

    return url as string | undefined;
  };

  /**
   * @description sets the redirect url for shopify
   * @param {Object} param0
   * @param {ShopifyAppRedirect} param0.key the key to set the redirect url for
   * @param {string} param0.path the path to set the redirect url to
   * @param {string} param0.brandId brand id to force the redirect url to
   * @param {string} param0.params the query params to set the redirect url to
   * @param {number} param0.expireInMs the time in milliseconds to expire the redirect url
   */
  const setShopifyURLRedirect = ({
    key,
    params = window.location.search as `?${string}`,
    path = window.location.pathname,
    brandId: brandIdToUse = brandId,
    expireInMs = 1000 * 60 * 30, // 30 minutes
  }: {
    key: ShopifyAppRedirect;
    path?: string;
    brandId?: string;
    params?: `?${string}`;
    expireInMs?: number;
  }) => {
    if (ShopifyAppRedirect.Signup !== key && !brandIdToUse) return;

    const urlParams = new window.URLSearchParams(params);
    const prefix = getStringPrefix(key, brandIdToUse);
    const pathKey = `${prefix}shopify-${key}-redirect-location`;
    const paramsKey = `${prefix}shopify-${key}-redirect-params`;
    storage.setItem(pathKey, path, expireInMs);
    storage.setItem(paramsKey, urlParams.toString() ? `?${urlParams.toString()}` : '', expireInMs);
  };

  return {
    loading: integrationApi.loading,
    getShopifyURLRedirect,
    setShopifyURLRedirect,
    generateOAuthUrlWithValidation,
    extractOAuthParameters,
    setShopifyAccessToken,
    getShopifyStatus,
  };
};

export default useShopify;
