import styled from '@emotion/styled';
import { MagicStar, Text, Tooltip } from '@innovationdepartment/proxima-ui';

const ContentTileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AdTargetingEnhancementHeader = () => (
  <ContentTileWrapper>
    <MagicStar />
    <Text variant="body1Semibold" color="neutral100">
      Ad targeting enhancements
    </Text>
    <Tooltip
      title={
        <div style={{ padding: 4 }}>
          <Text variant="body3" color="white">
            Connecting your Shopify store enables the targeting algorithm to continually learn,
            providing you with the highest caliber audiences and customer insights.
          </Text>
        </div>
      }
      info
      arrow
      placement="bottom"
    />
  </ContentTileWrapper>
);

export default AdTargetingEnhancementHeader;
