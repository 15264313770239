import styled from '@emotion/styled';
import { Text, Button } from '@innovationdepartment/proxima-ui';
import ButtonGroup from 'ui/ButtonGroup';

type LogoutToChangePasswordProps = {
  onLogout: () => void;
  onClose: () => void;
};

const Content = styled.div`
  display: flex;
  width: 382px;
  flex-direction: column;
  gap: 16px;
`;

const TextWrapper = styled.div`
  padding: 24px 0;
  & * {
    font-weight: 400;
    letter-spacing: -0.005em;
  }
`;

const LogoutToChangePasswordView = ({ onLogout, onClose }: LogoutToChangePasswordProps) => (
  <Content>
    <TextWrapper>
      <Text variant="body1">
        To change your password, please log out and select &ldquo;Forgot your password?&rdquo; on
        the login screen.
      </Text>
    </TextWrapper>
    <ButtonGroup>
      <Button size="small" label="Cancel" onClick={onClose} variant="outlined" />
      <Button size="small" label="Log out" onClick={onLogout} />
    </ButtonGroup>
  </Content>
);

export default LogoutToChangePasswordView;
