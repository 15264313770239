import { Text, Modal } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import BuildPlanView from './BuildPlanModal.View';

type BuildPlanModalProps = {
  open: boolean;
  onClose: () => void;
  brandId: string;
};

const ModalTitleWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const BuildPlanTitle = styled.div`
  h4 {
    background: linear-gradient(45deg, #58ebc3, #5dade9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 16px;
  }
`;

const ModalTitle = () => (
  <ModalTitleWrapper>
    <BuildPlanTitle>
      <Text variant="h4">Build your plan</Text>
    </BuildPlanTitle>
    <Text color="neutral70" variant="body2">
      Maximize your ad performance with AI-powered targeting.
    </Text>
  </ModalTitleWrapper>
);

const BuildPlanModal = ({ open, onClose, brandId }: BuildPlanModalProps) => (
  <Modal title={<ModalTitle />} open={open} onClose={onClose}>
    <BuildPlanView brandId={brandId} />
  </Modal>
);

export default BuildPlanModal;
