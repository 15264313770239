import styled from '@emotion/styled';
import { Button, colors, Loading, Text, Upload } from '@innovationdepartment/proxima-ui';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useRef, useState } from 'react';
import { BannerMessage } from 'types/brandCustomerSettings';

const SmallWhiteUploadIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }

  * > path {
    fill: ${colors.white};
  }
`;

const UploadCsvContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`;

const UploadCsvHeader = styled.div`
  display: flex;
  height: 52px;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SecureUploadText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ShortDivider = styled.hr`
  margin: 0 20px;
  border-width: 0;
  border-style: solid;
  border-color: ${colors.neutral10};
  border-bottom-width: thin;
  align-self: stretch;
  height: auto;
`;

const UploadCsvContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  width: 100%;
`;

const UploadButton = styled.label`
  display: flex;
  justify-content: end;

  > button {
    padding: 8px 12px;
  }
`;

type CustomerListUploadModalFileUploadProps = {
  handleCustomerListUpload: (fileUploaded: File) => Promise<BannerMessage | undefined>;
  onBannerMessageChanged: (messageResponse: BannerMessage | undefined) => void;
};

const CustomerListUploadModalFileUpload = (props: CustomerListUploadModalFileUploadProps) => {
  const { handleCustomerListUpload, onBannerMessageChanged } = props;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    const uploadButtonRef = hiddenFileInput.current;
    if (uploadButtonRef !== undefined) uploadButtonRef!.click();

    onBannerMessageChanged(undefined);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files![0];

    setIsUploading(true);
    const messageResponse = await handleCustomerListUpload(fileUploaded);
    setIsUploading(false);

    onBannerMessageChanged(messageResponse);
  };

  return (
    <UploadCsvContainer>
      <UploadCsvHeader>
        <Text variant="h6" isTermina={false}>
          Upload CSV File
        </Text>
        <SecureUploadText variant="body3Semibold">
          <LockOutlinedIcon fontSize="small" />
          Secure Upload
        </SecureUploadText>
      </UploadCsvHeader>
      <ShortDivider />
      <UploadCsvContent>
        <Text variant="body2">Maximum file size accepted 50 MB</Text>
        <input
          id="customer-csv-upload-file"
          type="file"
          hidden
          onChange={handleChange}
          ref={hiddenFileInput}
        />
        <UploadButton htmlFor="customer-csv-upload-file">
          <Button
            startIcon={
              isUploading ? (
                <Loading />
              ) : (
                <SmallWhiteUploadIcon>
                  <Upload />
                </SmallWhiteUploadIcon>
              )
            }
            onClick={handleClick}
            label={isUploading ? 'Uploading' : 'Upload CSV'}
            size="small"
            disabled={isUploading}
          />
        </UploadButton>
      </UploadCsvContent>
    </UploadCsvContainer>
  );
};

export default CustomerListUploadModalFileUpload;
