import styled from '@emotion/styled';
import { Button, HelpCircled, Text } from '@innovationdepartment/proxima-ui';
import { AUDIENCES_QUICK_START_GUIDE_URL } from 'constants/urls';

const OnboardingTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const OnboardingHeader = () => (
  <OnboardingTitleWrapper>
    <div>
      <Text variant="h5">Integrations</Text>
      <Text variant="body2Medium" color="neutral70">
        Easily connect external apps and platforms with your Proxima account to build custom
        audiences and generate insights.
      </Text>
    </div>
    <Button
      variant="text"
      label="Help center"
      onClick={() => window.open(AUDIENCES_QUICK_START_GUIDE_URL, '_blank')}
      endIcon={<HelpCircled />}
    />
  </OnboardingTitleWrapper>
);

export default OnboardingHeader;
