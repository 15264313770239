import { useToaster } from '@innovationdepartment/proxima-ui';
import { useEffect, useState } from 'react';
import { useBillingApi } from 'api';
import { BillingContact } from 'types/billing';
import BillingInvoiceRecipientsView from './BillingInvoiceRecipients.View';
import { Brand } from 'types/stores/brand-store';

const BillingInvoiceRecipients = ({ brand }: { brand: Brand }) => {
  const { loading, createBillingContact, deleteBillingContact, getBillingContacts } =
    useBillingApi();
  const { brandId } = brand;
  const [contacts, setContacts] = useState<BillingContact[]>([]);
  const { showToaster } = useToaster();

  const fetchContacts = async () => {
    const response = await getBillingContacts(brandId);
    if (response.error) return;
    setContacts(response.data);
  };

  const onContactsChange = async (tags: string[]) => {
    const determineAction = () => {
      const contactsLength = contacts.length;
      const tagsLength = tags.length;

      if (contactsLength > tagsLength) return 'delete';
      if (contactsLength < tagsLength) return 'create';
      return null;
    };

    const action = determineAction();

    if (action === 'create' && brandId) {
      if (!tags.length) return;
      const emailToCreate = tags.find((tag) => !contacts.map(({ email }) => email).includes(tag));
      if (!emailToCreate) {
        showToaster({ message: 'Recipient already exists.', variant: 'error' });
        return;
      }
      const newContactId = `new-contact-id-${Math.random()}`;
      const newContact = {
        email: emailToCreate,
        contactId: newContactId,
        brandId,
        createdAt: new Date().toISOString(),
      };
      setContacts([...contacts, newContact]);
      const response = await createBillingContact({ email: emailToCreate }, brandId);

      if (response.error) {
        setContacts((recipients) => recipients.filter(({ email }) => email !== emailToCreate));
        return;
      }

      showToaster({ message: 'Recipient added.', variant: 'success' });
      setContacts((recipients) =>
        recipients.map((contact) =>
          contact.contactId === newContactId
            ? { ...newContact, contactId: response.data.contactId }
            : contact
        )
      );
    }

    if (action === 'delete') {
      const contact = contacts.find(({ email }) => !tags.includes(email));
      if (!contact || !brandId) return;
      setContacts(contacts.filter(({ email }) => email !== contact.email));
      const response = await deleteBillingContact({
        contactId: contact.contactId,
        brandId,
      });

      if (response.error) {
        setContacts(contacts);
        return;
      }

      showToaster({ message: 'Recipient removed.', variant: 'success' });
    }
  };

  useEffect(() => {
    if (brandId) fetchContacts();
  }, [brand]);

  return (
    <BillingInvoiceRecipientsView
      loading={loading}
      contacts={contacts}
      onContactsChange={onContactsChange}
    />
  );
};

export default BillingInvoiceRecipients;
