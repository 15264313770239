import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

const ContentTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const AdPlatformSelectionHeader = () => (
  <ContentTextWrapper>
    <Text variant="body1Semibold" color="neutral100">
      Ad platform
    </Text>
    <Text variant="body2" color="neutral100">
      We’ll deliver the lookalikes directly to your ad account.
    </Text>
  </ContentTextWrapper>
);

export default AdPlatformSelectionHeader;
