import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShopifyAppRedirect } from 'types/components/shopify';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_USER_ID_NAMESPACE } from 'constants/defaults';
import { useProximaSDK, useShopify, useStorage } from 'hooks';

const RedirectLoader: React.FC = ({ children }) => {
  const location = useLocation();
  const { getShopifyURLRedirect } = useShopify();
  const userApi = useProximaSDK('UsersApi');
  const navigate = useNavigate();
  const sessionStorage = useStorage();
  const [ready, setReady] = useState(false);

  const { user } = useAuth0();

  const checkForRedirect = async () => {
    /* if user is logged out when pasting an url and is prompted to log in, this takes them back to pasted url  */
    const redirectTo = sessionStorage.getItem('redirectTo', true);
    if (redirectTo) {
      navigate(redirectTo, { replace: true });
    } else if (user) {
      const userId = user[AUTH0_USER_ID_NAMESPACE];
      /* this covers the case when installing app from Shopify and user logs in */
      const checkIfRedirectFromShopifyAfterLogin = async () => {
        const isSetupLocation = location.pathname.startsWith('/setup');
        const isEmailVerify = location.pathname === '/email/verify';

        /* skip if setup in middle of setup */
        if (isSetupLocation || isEmailVerify) return;

        /* check if Signup key exists */
        let shopifyRedirectUrl = getShopifyURLRedirect({ key: ShopifyAppRedirect.Signup });

        if (!shopifyRedirectUrl) return;

        const checkIfUserHasBrandAccess = async () => {
          try {
            const apiResponse = await userApi.getAllBrandsForUser({ userId });
            return apiResponse.data.length > 0;
          } catch (err) {
            /* */
          }
          return false;
        };

        const hasBrandAccess = await checkIfUserHasBrandAccess();
        /* if user has no access to any brand, skip redirect */
        if (!hasBrandAccess) return;

        /* remove Signup key after use */
        shopifyRedirectUrl = getShopifyURLRedirect({
          key: ShopifyAppRedirect.Signup,
          remove: true,
        });

        if (!shopifyRedirectUrl) return;

        navigate(shopifyRedirectUrl, { replace: true });
      };

      checkIfRedirectFromShopifyAfterLogin();
    }

    setReady(true);
  };

  /* must always be last useEffect */
  useEffect(() => {
    checkForRedirect();
  }, [user, location.pathname]);

  if (!ready) return <></>;

  return <>{children}</>;
};

export default RedirectLoader;
