const EMAIL_USER_BODY_MAX_LENGTH = 20;

export default (email: string) => {
  const emailParts = email!.split('@');

  // ex. account@domain.com
  // emailParts[0] = account
  // emailParts[1] = domain.com
  if (emailParts[0].length > EMAIL_USER_BODY_MAX_LENGTH) {
    emailParts[0] = `${emailParts[0].slice(0, EMAIL_USER_BODY_MAX_LENGTH)}...`;
  }

  return `${emailParts[0]}@${emailParts[1]}`;
};
