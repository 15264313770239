import { SeedAudiencesTableViewProps } from 'types/components/audiences';
import styled from '@emotion/styled';
import { Table, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 1px;
  flex: 1;

  * > :nth-child(2) {
    align-self: stretch;
  }
`;

const SeedAudiencesTableView = (props: SeedAudiencesTableViewProps) => {
  const { seedAudiences, config } = props;

  return (
    <Container>
      <Text variant="subhead1">Manage your audiences</Text>
      <Table data={seedAudiences} config={config} />
    </Container>
  );
};

export default SeedAudiencesTableView;
