import { create } from 'zustand';
import pick from 'lodash/pick';

import {
  AdAccount,
  AdAccountStatus,
  AdAccountStore,
  allowedKeys,
} from 'types/stores/ad-account-store';

const useAdAccountStore = create<AdAccountStore>((set, get) => ({
  adAccount: null,
  properties: {},
  clear: () => set({ adAccount: null, adAccountsList: [] }),
  update: (adAccount: Partial<AdAccount>) =>
    set((prevState) => {
      const { properties: newProperties = {}, adPixels: newAdPixels = [] } = adAccount;
      const { properties: oldProperties = {}, adPixels: oldAdPixels = [] } =
        prevState.adAccount ?? {};

      const properties = { ...oldProperties, ...newProperties };
      const adPixels = [...oldAdPixels, ...newAdPixels];
      const newAdAccount = {
        ...(prevState.adAccount ?? {}),
        ...pick(adAccount, allowedKeys),
        properties,
        adPixels,
      } as AdAccount;

      return { adAccount: newAdAccount };
    }),
  // list values
  adAccountsList: [],
  getActiveAdAccountFromList: () =>
    get().adAccountsList.find((adAccount) => adAccount.status === AdAccountStatus.Active),
  updateAdAccountsList: (adAccountsList: AdAccount[]) => set({ adAccountsList }),
}));

export default useAdAccountStore;
