import styled from '@emotion/styled';
import { Button, Modal, Text } from '@innovationdepartment/proxima-ui';

import { SkipCampaignModalViewProps } from 'types/components/audienceCampaign';
import ButtonGroup from 'ui/ButtonGroup';

const TextWrapper = styled.div`
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 422px;
`;

const SkipCampaignModal = (props: SkipCampaignModalViewProps) => {
  const { showSkipCampaignModal, onSkipModalClick, onSkipModalClose } = props;

  return (
    <Modal
      resizable
      noPadding={false}
      open={showSkipCampaignModal}
      title="Skip campaign setup?"
      onClose={onSkipModalClose}
      allowCloseOnClickOutside
    >
      <TextWrapper>
        <Text variant="body1" color="neutral100">
          By skipping this step, we will not create a campaign optimized for your new audiences.
        </Text>
        <Text variant="body1" color="neutral100">
          Your audiences will still be generated and delivered to your ad account.
        </Text>
      </TextWrapper>
      <ButtonGroup>
        <Button
          size="small"
          label="Skip"
          onClick={onSkipModalClick}
          variant="outlined"
          color="secondary"
        />
        <Button size="small" label="Go back to campaign setup" onClick={onSkipModalClose} />
      </ButtonGroup>
    </Modal>
  );
};

export default SkipCampaignModal;
