import { BrandIntegrationStatus } from 'constants/integrations';
import styled from '@emotion/styled';
import { Button, MagicStar, Shopify, Text } from '@innovationdepartment/proxima-ui';
import { AdEnhancementViewProps } from 'types/components/audiences';

const EnhancementItemWrapper = styled.div<{ column?: boolean; flex?: boolean }>`
  display: flex;
  gap: ${({ column }) => (column ? 0 : 16)}px;
  flex-flow: ${({ column }) => (column ? 'column' : 'row')};
  flex: ${({ flex }) => (flex ? 1 : 0)};
`;

const EnhancementItemSection = styled.div`
  align-self: center;
`;

const AdTargetingEnhancementShopify = ({ status, connectToShopify }: AdEnhancementViewProps) => {
  const isConnected = status === BrandIntegrationStatus.Connected;

  return (
    <EnhancementItemWrapper>
      <Shopify />
      <EnhancementItemWrapper column flex>
        <Text variant="body2Semibold" color="neutral100">
          Shopify
        </Text>
        {isConnected ? (
          <Text variant="body2Semibold" color="green60">
            Connected
          </Text>
        ) : (
          <Text variant="body2" color="neutral100">
            Leverage your store&#39;s first-party data to continuously improve the targeting
            algorithm.
          </Text>
        )}
      </EnhancementItemWrapper>
      {!isConnected && (
        <EnhancementItemSection>
          <Button
            variant="outlined"
            onClick={connectToShopify}
            label="Connect Shopify"
            startIcon={<MagicStar />}
          />
        </EnhancementItemSection>
      )}
    </EnhancementItemWrapper>
  );
};

export default AdTargetingEnhancementShopify;
