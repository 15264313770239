import styled from '@emotion/styled';
import { Button, ProximaLogoGradient, colors } from '@innovationdepartment/proxima-ui';

const OnboardingNavbarWrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 16px 48px;
  background-color: ${colors.bigBangBlack};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

type OnboardingNavbarProps = {
  skip: boolean;
  onSkip: () => void;
  onContinue: () => void;
};

const OnboardingNavbar = (props: OnboardingNavbarProps) => {
  const { skip = true, onSkip, onContinue } = props;

  return (
    <OnboardingNavbarWrapper>
      <ProximaLogoGradient />
      <ButtonWrapper>
        {skip && (
          <Button
            label="Skip for now"
            size="medium"
            onClick={onSkip}
            overrideColors={{ textColor: 'neutral20' }}
          />
        )}
        {!skip && <Button label="Continue" color="secondary" size="medium" onClick={onContinue} />}
      </ButtonWrapper>
    </OnboardingNavbarWrapper>
  );
};

export default OnboardingNavbar;
