import { useState } from 'react';
import ToggleStandardPlansView from './ToggleStandardPlans.View';
import { BillingFrequency } from 'types/billing';

type ToggleStandardPlansProps = {
  brandId: string;
};

const ToggleStandardPlans = ({ brandId }: ToggleStandardPlansProps) => {
  const [billingFrequency, setBillingFrequency] = useState<BillingFrequency>(
    BillingFrequency.Monthly
  );

  return (
    <ToggleStandardPlansView
      brandId={brandId}
      billingFrequency={billingFrequency}
      setBillingFrequency={setBillingFrequency}
    />
  );
};

export default ToggleStandardPlans;
