import { IntegrationType } from 'constants/integrations';
import { useState } from 'react';
import { useAdAccountStore } from 'stores';
import { useFacebook, useIntegrations, useShowSpinner } from 'hooks';
import { FacebookIntegrationModalProps } from 'types/components/integrations';
import DisconnectIntegrationModal from 'ui/Modals/DisconnectIntegrationModal';
import FacebookIntegrationModalView from './FacebookIntegrationModal.View';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const FacebookIntegrationModal = (
  props: Pick<FacebookIntegrationModalProps, 'open' | 'onClose'>
) => {
  const navigate = useNavigate();
  const { open, onClose } = props;
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const { deleteIntegration, getActiveIntegrations, loading } = useIntegrations();
  const { getMetaStatus } = useFacebook();
  const { brandId } = useParams<{ brandId: string }>();
  const { pathname } = useLocation();
  const adAccountStore = useAdAccountStore();

  useShowSpinner({ show: loading });

  const { adAccount } = adAccountStore;
  const [facebookIntegration] = getActiveIntegrations(IntegrationType.Facebook);

  const onDisconnectClick = () => setDisconnectModalOpen(true);

  const onAcceptTermsClick = () => {
    if (!adAccount) return;
    const searchParams = new URLSearchParams({
      adAccount: adAccount.accountId,
      redirect: pathname,
      origin: 'integrations',
    });
    navigate(`/brand/${brandId}/audiences/custom-tos?${searchParams.toString()}`);
  };

  const onDisconnectConfirm = async () => {
    if (facebookIntegration) {
      setDisconnectModalOpen(false);
      await deleteIntegration(facebookIntegration.integrationId);
      adAccountStore.clear();
    }
  };

  const onConnectAdAccountClick = () => {
    navigate(`/brand/${brandId}/meta/ad-account?redirect=${pathname}`);
  };

  const fbIntegrationStatus = getMetaStatus();

  return (
    <>
      <FacebookIntegrationModalView
        brandId={brandId as string}
        status={fbIntegrationStatus}
        onConnectAdAccountClick={onConnectAdAccountClick}
        onDisconnectClick={onDisconnectClick}
        onAcceptTermsClick={onAcceptTermsClick}
        adAccount={adAccount}
        loading={loading}
        open={open}
        onClose={onClose}
      />
      <DisconnectIntegrationModal
        type={IntegrationType.Facebook}
        open={disconnectModalOpen}
        close={() => setDisconnectModalOpen(false)}
        confirm={onDisconnectConfirm}
      />
    </>
  );
};

export default FacebookIntegrationModal;
