import styled from '@emotion/styled';
import { colors, Text, SatelliteConnectionNoDetected } from '@innovationdepartment/proxima-ui';

const NoAdsBox = styled.div`
  height: 420px;
  border-radius: 2px;
  border: 2px solid ${colors.legacy.mistDarker};
  background-color: ${colors.legacy.mistLightest};
  display: grid;
  gap: 16px;
  place-content: center;
`;

const NoAds = () => (
  <NoAdsBox>
    <Text variant="body1Semibold" color="slateLightest">
      No ad accounts detected
    </Text>
    <SatelliteConnectionNoDetected />
  </NoAdsBox>
);

export default NoAds;
