import { Outlet } from 'react-router-dom';

import SetupLayout from 'ui/SetupLayout';

const SetupView = () => (
  <SetupLayout>
    <Outlet />
  </SetupLayout>
);

export default SetupView;
