import { SkipCampaignModalProps } from 'types/components/audienceCampaign';
import SkipCampaignModalView from './SkipCampaignModal.View';

const SkipCampaignModal = (props: SkipCampaignModalProps) => {
  const { showSkipCampaignModal, onSkipModal, onSkipModalClose } = props;

  return (
    <SkipCampaignModalView
      onSkipModalClick={onSkipModal}
      showSkipCampaignModal={showSkipCampaignModal}
      onSkipModalClose={onSkipModalClose}
    />
  );
};

export default SkipCampaignModal;
