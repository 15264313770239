import { BrandIntegrationStatus } from 'constants/integrations';
import { Shopify } from '@innovationdepartment/proxima-ui';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';
import { ShopifyIntegrationModalProps } from 'types/components/integrations';
import ShopifyIntegrationModalSideContent from './ShopifyIntegrationModal.SideContent';
import ShopifyIntegrationModalMainView from './ShopifyIntegrationModal.MainContent';

const ShopifyIntegrationModalView: React.FC<ShopifyIntegrationModalProps> = (props) => {
  const { status, loading, open, onClose, onDisconnectClick, onGetStartedClick, storeName } = props;
  const isConnected = status === BrandIntegrationStatus.Connected;
  return (
    <IntegrationsModal
      open={open}
      icon={<Shopify />}
      title="Shopify"
      onClose={onClose}
      sideContent={
        <ShopifyIntegrationModalSideContent
          status={status}
          onDisconnectClick={onDisconnectClick}
          onGetStartedClick={onGetStartedClick}
          storeName={storeName}
          loading={loading}
        />
      }
    >
      <ShopifyIntegrationModalMainView isConnected={isConnected} />
    </IntegrationsModal>
  );
};

export default ShopifyIntegrationModalView;
