import { useLocation, useNavigate } from 'react-router-dom';
import SettingsView from './Settings.View';

const MENU_ITEMS: { label: string; pathname: string }[] = [
  { label: 'General', pathname: 'general' },
  { label: 'Users', pathname: 'users' },
  { label: 'Billing', pathname: 'billing' },
];

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const onRouteChange = (path: string) => navigate(path);

  return <SettingsView onNavigate={onRouteChange} pathname={pathname} menuItems={MENU_ITEMS} />;
};

export default Settings;
