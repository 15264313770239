import { useHandleApi } from 'hooks';

export default function useUserApi() {
  const { loading, handleApi } = useHandleApi();

  const sendVerificationEmail = async (id: string) => {
    const endpoint = `/user/${id}/verify-email`;
    return handleApi({ endpoint, method: 'POST' });
  };

  const getUserBrandsHistory = async (id: string) => {
    const endpoint = `/user/${id}/brand/history`;
    return handleApi({ endpoint, method: 'GET' });
  };

  return {
    loading,
    getUserBrandsHistory,
    sendVerificationEmail,
  };
}
