import { ButtonProps, Button } from '@innovationdepartment/proxima-ui';
import { useConnectFacebookToProxima } from 'hooks';

type FacebookLoginProps = {
  onSuccessRedirect: string;
  brandId?: string;
  customButtonProps?: ButtonProps;
};

const FacebookLogin = ({ onSuccessRedirect, brandId, customButtonProps }: FacebookLoginProps) => {
  const { onClick } = useConnectFacebookToProxima({ redirect: onSuccessRedirect, brandId });

  if (customButtonProps) return <Button {...customButtonProps} submit={false} onClick={onClick} />;

  return <Button fullWidth label="Connect" onClick={onClick} size="medium" />;
};

export default FacebookLogin;
