import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useShopify } from 'hooks';
import { Spinner } from '@innovationdepartment/proxima-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShopifyAppRedirect } from 'types/components/shopify';

const debouncer = debounce((cb) => cb(), 500);

const ShopifyOAuthSuccessCallback = (props: { pickBrand?: boolean }) => {
  const { pickBrand } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();
  const { brand } = useBrandStore();
  const { setShopifyAccessToken, setShopifyURLRedirect } = useShopify();

  useEffect(() => {
    debouncer(() => {
      if (isLoading) return;

      if (!brand || !isAuthenticated) {
        setShopifyURLRedirect({
          key: ShopifyAppRedirect.Signup,
          expireInMs: 1000 * 60 * 60 * 3, // 3 hours
        });

        navigate('/auth/all-set');
        return;
      }

      if (pickBrand) {
        navigate(`/auth/callback/shopify/choose-brand${location.search}`);
      } else {
        setShopifyAccessToken();
      }
    });
  }, [isAuthenticated, isLoading, brand]);

  return <Spinner />;
};

export default ShopifyOAuthSuccessCallback;
