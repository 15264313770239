import { useState } from 'react';
import { AdPixelProps, Option } from 'types/components/audienceCampaign';
import { useAdManager } from 'hooks';

import AdPixelView from './AdPixel.View';
import { useForm } from 'react-final-form';

const AdPixelContainer = (props: AdPixelProps) => {
  const { options } = props;
  const [currentOptions, setCurrentOptions] = useState<Option[]>(options);
  const [loading, setLoading] = useState(false);
  const { refreshAdPixels, getActiveAdPixelsByCreatedDate } = useAdManager();
  const form = useForm();

  const adPixelsOptions = currentOptions.map((option: Option) => {
    const { label, value } = option;
    return {
      value,
      label: `${label} (ID:${value})`,
    };
  });

  const onRefreshClick = async () => {
    setLoading(true);
    const response = await refreshAdPixels();
    const adPixelOptions = getActiveAdPixelsByCreatedDate(response);
    setCurrentOptions(adPixelOptions);
    form.change('adPixel', adPixelOptions[0]?.value ?? undefined);
    setLoading(false);
  };

  return (
    <AdPixelView options={adPixelsOptions} loading={loading} onRefreshClick={onRefreshClick} />
  );
};

export default AdPixelContainer;
