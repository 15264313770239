import { useHandleApi } from 'hooks';
import { CreateIntegrationApiProps } from 'types/components/integrations';

const useIntegrationApi = () => {
  const { loading, handleApi } = useHandleApi();

  const getIntegrations = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/integration`, brandId });

  const createIntegration = async (brandId: string, data: CreateIntegrationApiProps) =>
    handleApi({ endpoint: `/brand/${brandId}/integration`, brandId, method: 'POST', data });

  const deleteIntegration = async (brandId: string, integrationId: string) =>
    handleApi({
      endpoint: `/brand/${brandId}/integration/${integrationId}`,
      method: 'DELETE',
      brandId,
    });

  const getAdAccountProperties = async (brandId: string, adAccountId: string) =>
    handleApi({
      endpoint: `/brand/${brandId}/fb/adAccount/${adAccountId}/properties`,
    });

  return {
    getAdAccountProperties,
    deleteIntegration,
    getIntegrations,
    createIntegration,
    loading,
  };
};

export default useIntegrationApi;
