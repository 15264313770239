import styled from '@emotion/styled';
import { Avatar, Text, colors } from '@innovationdepartment/proxima-ui';

const CellContainer = styled.div`
  cursor: pointer;
`;

const BrandNameCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    color: ${colors.legacy.tealDarker};
  }
`;

const FontWeightWrapper = styled.span`
  & * {
    font-weight: 400;
  }
`;

interface IBrandRowRender {
  header: string;
  cell: string;
  row: { name: string; brandId: string };
}

const brandRowRender = (props: IBrandRowRender) => {
  const { header, cell } = props;

  if (header === 'name') {
    const [first, last] = cell.substring(0, 2).split(' ');
    return (
      <CellContainer>
        <BrandNameCell>
          <Avatar firstName={first} lastName={last} variant="small" />
          <FontWeightWrapper>
            <Text color="slateDarker" variant="table1Link">
              <Capitalize>{cell}</Capitalize>
            </Text>
          </FontWeightWrapper>
        </BrandNameCell>
      </CellContainer>
    );
  }

  return (
    <CellContainer>
      <FontWeightWrapper>
        <Text variant="table1">
          {new Date(cell).toLocaleDateString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
      </FontWeightWrapper>
    </CellContainer>
  );
};

export default brandRowRender;
