import styled from '@emotion/styled';
import { Banner, colors } from '@innovationdepartment/proxima-ui';
import { ConnectKlaviyoAccountViewProps } from 'types/components/klaviyo';
import { BrandIntegrationStatus } from 'constants/integrations';

const BannersWrapper = styled.div<{ disconnected: boolean }>`
  margin-bottom: 16px;

  & svg path {
    fill: ${colors.red60};
  }
`;

const ConnectKlaviyoAccountInfoBanners = (props: ConnectKlaviyoAccountViewProps) => {
  const { status } = props;
  const isDisconnected = status === BrandIntegrationStatus.Disconnected;

  if (!isDisconnected) return null;

  return (
    <BannersWrapper disconnected={isDisconnected}>
      <Banner
        title="Your account has been disconnected"
        body="Please update to reconnect"
        type="error"
      />
    </BannersWrapper>
  );
};

export default ConnectKlaviyoAccountInfoBanners;
