// TODO(Jenky): Most of the code disabled in favor of showing another view. This is a temporary solution.
// import { useState, useEffect } from 'react';
import { Modal } from '@innovationdepartment/proxima-ui';

// TODO(Jenky): Disabled!
// import ChangePasswordModalView from './ChangePasswordModal.View';
import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import LogoutToChangePassword from './LogoutToChangePassword';

interface IChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal = ({ open, onClose }: IChangePasswordModalProps) => {
  // const [submitDisabled, setSubmitDisabled] = useState(true);
  const { logout } = useAuth0();
  const { clearBrandStore } = useBrandStore();

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
    clearBrandStore();
  };
  // useEffect(() => {
  //   let timeoutId: ReturnType<typeof setTimeout>;
  //   if (open) {
  //     timeoutId = setTimeout(() => setSubmitDisabled(true), 100);
  //   }
  //   return () => clearTimeout(timeoutId);
  // }, [open]);

  // const handleChange = (values: { [key: string]: string }) => {
  //   // TODO: Will be updated in future once we decide how to handle password changes
  //   setSubmitDisabled(!values.valid);
  // };

  // const handleSubmit = (values: { [key: string]: string }) => {
  //   // TODO: Will be updated in future once we decide how to handle password changes
  //   // eslint-disable-next-line no-console
  //   console.log('Clicked submit', values);
  //   setSubmitDisabled(true);
  // };

  return (
    <Modal
      // noPadding={false}
      open={open}
      title="Change password"
      onClose={onClose}
      allowCloseOnClickOutside
      resizable
    >
      {/* TODO(Jenky): Disabled! */}
      {/* <ChangePasswordModalView
        submitDisabled={submitDisabled}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleLogout={logout}
        onClose={onClose}
      /> */}
      <LogoutToChangePassword onLogout={onLogout} onClose={onClose} />
    </Modal>
  );
};

export default ChangePasswordModal;
