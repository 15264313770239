import { Route, Routes } from 'react-router-dom';

import Auth0ProviderWrapper from '../Auth0Provider';
import AuthRedirect from '../AuthRedirect';
import App from '../App';

/**
 * @description This component is a router that handles the certain pre-app authentication conditions.
 */
const Auth0Router = () => (
  <Routes>
    <Route path="/sign-up" element={<AuthRedirect action="sign-up" />} />
    <Route path="/login" element={<AuthRedirect action="login" />} />
    <Route path="/logout" element={<AuthRedirect isLogout />} />

    {/* redirects to main app if non-prior routes are visited */}
    <Route
      path="*"
      element={
        <Auth0ProviderWrapper>
          <App />
        </Auth0ProviderWrapper>
      }
    />
  </Routes>
);

export default Auth0Router;
