import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import { IAccessToken, IAccessTokenArgs } from 'types/hooks/useAccessToken';

const namespace = 'https://dojomojo.com/jwt';

export default function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async ({
    brandId = '',
    refresh = false,
  }: IAccessTokenArgs = {}): Promise<IAccessToken> => {
    const opts: Record<string, unknown> = {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
    };
    if (brandId) {
      opts.brandId = brandId;
    }
    if (refresh) {
      opts.ignoreCache = true;
    }

    // get current token (retrieves a new token from Auth0 if local cache is empty)
    let accessToken = await getAccessTokenSilently(opts);
    let decoded = jwtDecode(accessToken) as any;
    let tokenBrandId = decoded[`${namespace}/brandId`] as string;

    // retrieve new token if brandId was passed and is different than token's brandId
    if (brandId && brandId !== tokenBrandId) {
      accessToken = await getAccessTokenSilently({ ...opts, ignoreCache: true });
      decoded = jwtDecode(accessToken);
      tokenBrandId = decoded[`${namespace}/brandId`] as string;

      // to do: what if user still doesnt have access to requested brand?
    }

    const result: IAccessToken = { accessToken };
    if (tokenBrandId) {
      result.brandId = tokenBrandId;
    }

    return result;
  };

  return {
    getAccessToken,
    namespace,
  };
}
