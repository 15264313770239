import { CampaignAttributionSetting, CampaignObjective } from 'types/components/audienceCampaign';
import CampaignAttributesView from './CampaignAttributes.View';

const CampaignAttributes = () => {
  const campaignObjectiveOptions = [
    {
      label: 'Sales',
      value: CampaignObjective.Sales,
    },
  ];

  const campaignAttributionSettingOptions = [
    {
      label: CampaignAttributionSetting.Seven_Day_Click_And_One_Day_View,
      value: CampaignAttributionSetting.Seven_Day_Click_And_One_Day_View,
    },
  ];

  return (
    <CampaignAttributesView
      campaignObjectiveOptions={campaignObjectiveOptions}
      campaignAttributionSettingOptions={campaignAttributionSettingOptions}
    />
  );
};

export default CampaignAttributes;
