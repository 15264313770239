import { Meta } from '@innovationdepartment/proxima-ui';
import { FacebookIntegrationModalProps } from 'types/components/integrations';
import FacebookIntegrationModalMainView from './FacebookIntegrationModal.MainContent';
import FacebookIntegrationModalSideContent from './FacebookIntegrationModal.SideContent';
import IntegrationsModal from '../IntegrationsModal';

const FacebookIntegrationModalView: React.FC<FacebookIntegrationModalProps> = (props) => {
  const {
    brandId,
    adAccount,
    status,
    loading,
    onClose,
    onConnectAdAccountClick,
    onDisconnectClick,
    onAcceptTermsClick,
    open,
  } = props;

  return (
    <IntegrationsModal
      icon={<Meta />}
      title="Meta"
      open={open}
      onClose={onClose}
      sideContent={
        <FacebookIntegrationModalSideContent
          brandId={brandId}
          loading={loading}
          adAccount={adAccount}
          status={status}
          onConnectAdAccountClick={onConnectAdAccountClick}
          onAcceptTermsClick={onAcceptTermsClick}
          onDisconnectClick={onDisconnectClick}
        />
      }
    >
      <FacebookIntegrationModalMainView />
    </IntegrationsModal>
  );
};

export default FacebookIntegrationModalView;
