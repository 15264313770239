import styled from '@emotion/styled';

const SideContentItemWrapper = styled.div<{
  flow?: React.CSSProperties['flexDirection'];
  gap?: number;
}>`
  display: flex;
  flex-direction: ${({ flow = 'row' }) => flow};
  flex-wrap: wrap;
  gap: ${({ gap = 8 }) => gap}px;
`;

export default SideContentItemWrapper;
