import { useEffect } from 'react';
import { useAudiencesApi } from 'api';
import { useForm, useFormState } from 'react-final-form';
import { GenerateAudiencesForm } from 'types/components/audiences';
import { formatDate } from 'utils';
import LookalikeSourcesView from './LookalikeSources.View';

const LookalikeSources = () => {
  const form = useForm();
  const formState = useFormState<GenerateAudiencesForm>();
  const { galaxies: galaxiesArr } = formState.values;

  const { loading, getAvailableGalaxies } = useAudiencesApi();

  const galaxies = galaxiesArr;

  useEffect(() => {
    const fetchNewGalaxies = async () => {
      const response = await getAvailableGalaxies();
      if (response.error) return;
      form.change('galaxies', response.data);
    };

    fetchNewGalaxies();
  }, []);

  const newGalaxies = galaxies.length === 5;

  const lastUpdated =
    galaxies.length > 0
      ? // get last updated galaxy `updatedAt` prop and format it
        formatDate(
          new Date(Math.max(...galaxies.map((galaxy) => new Date(galaxy.updatedAt).getTime())))
        )
      : null;

  return (
    <LookalikeSourcesView
      lastUpdated={lastUpdated}
      newGalaxies={newGalaxies}
      loading={loading}
      galaxies={galaxies}
    />
  );
};

export default LookalikeSources;
