import styled from '@emotion/styled';
import { ButtonBase, GraySquare, Text, colors } from '@innovationdepartment/proxima-ui';
import { AdAccount } from 'types/stores/ad-account-store';

type AdAccountSelectionSearchItemProps = {
  selected: boolean;
  adAccount: AdAccount;
  onClick: () => void;
};

const AdAccountSelectionSearchItemWrapper = styled(ButtonBase)<{ selected: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 8px;
  background-color: ${({ selected }) => (selected ? colors.neutral0 : 'transparent')};
`;

const AdAccountSelectionSearchItem = (props: AdAccountSelectionSearchItemProps) => {
  const { adAccount, onClick, selected } = props;

  return (
    <AdAccountSelectionSearchItemWrapper disableRipple onClick={onClick} selected={selected}>
      <GraySquare />
      <Text variant="body2" color="neutral100">
        {adAccount.name}
      </Text>
    </AdAccountSelectionSearchItemWrapper>
  );
};

export default AdAccountSelectionSearchItem;
