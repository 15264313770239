import styled from '@emotion/styled';
import { Banner, Button, Text, colors } from '@innovationdepartment/proxima-ui';

type LockedBrandBannerProps = {
  mailToLink: () => void;
};

const BannerNoIconWrapper = styled.div`
  & svg {
    display: none;
  }
  & div {
    background-color: ${colors.red20};
    border: none;
    border-radius: unset;
  }
`;

const BannerText = styled(Text)`
  margin-right: 16px;
`;

const LockedBrandBannerView = ({ mailToLink }: LockedBrandBannerProps) => (
  <BannerNoIconWrapper>
    <Banner type="error" title="" body="">
      <div style={{ margin: '0 auto' }}>
        <BannerText variant="body2Semibold">Please select a plan to continue.</BannerText>
        <Button onClick={mailToLink} size="small" label="Contact us" />
      </div>
    </Banner>
  </BannerNoIconWrapper>
);

export default LockedBrandBannerView;
