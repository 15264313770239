import { PropsOf } from '@emotion/react';
import {
  // TODO(Jenky): Disabled on 03/22/2023
  // ManageIcon,
  IntegrationsIcon,
  Group as AudiencesIcon,
  NavbarMenu,
  Settings,
  AvatarImagePlaceholder,
  LinkIcon,
  NavBarButton,
} from '@innovationdepartment/proxima-ui';
import { Brand } from 'types/stores/brand-store';
import LoadingSpinner from 'ui/LoadingSpinner';
import renderNavbarItem from './renderNavbarItem';
import { MAX_BRANDS_HISTORY } from 'constants/brandsHistory';

type NavbarTopButton = PropsOf<typeof NavBarButton>['button'] & { pathname: string };
export const getNavbarTopButtons = (brandId: string): NavbarTopButton[] => [
  {
    label: 'Audiences',
    pathname: `/brand/${brandId}/audiences`,
    icon: <AudiencesIcon />,
  },
  // TODO(Jenky): Disabled on 03/22/2023
  // {
  //   label: 'Manage',
  //   pathname: '/brand/${brandId}/manage',
  //   icon: <ManageIcon />,
  // },
  {
    label: 'Integrations',
    pathname: `/brand/${brandId}/integrations`,
    icon: <IntegrationsIcon />,
  },
];

export const getNavbarBottomButtons = ({
  loading,
  brands,
  brand,
}: {
  loading: boolean;
  brands: Brand[];
  brand: Brand;
}) => {
  type NavbarMenuItem = NonNullable<PropsOf<typeof NavbarMenu>['items']>[number] & {
    noTooltip?: true;
  };

  const brandsHistoryItems: NavbarMenuItem[] = [];

  brandsHistoryItems.push(
    ...([
      /* brand name title */
      {
        label: brand.name,
        value: brand.brandId,
        disableHover: true,
        active: true,
        icon: <AvatarImagePlaceholder />,
        trailingDivider: true,
      },
      /* brand settigs route */
      {
        label: 'Brand settings',
        value: `/brand/${brand.brandId}/settings/general`,
        icon: <Settings />,
        trailingDivider: true,
        noTooltip: true,
      },
    ] satisfies NavbarMenuItem[])
  );

  if (loading) {
    brandsHistoryItems.push({
      label: '',
      value: 'spinner',
      icon: <LoadingSpinner />,
      trailingDivider: true,
      noTooltip: true,
    });
  } else {
    brands
      /* filter own brand */
      .filter((brandItem) => brandItem.brandId !== brand.brandId)
      .slice(0, MAX_BRANDS_HISTORY)
      .forEach((brandItem) => {
        brandsHistoryItems.push({
          label: brandItem.name,
          value: brandItem.brandId,
          icon: <AvatarImagePlaceholder />,
          trailingDivider: brandItem.brandId === brands.at(-1)?.brandId,
        });
      });
  }

  brandsHistoryItems.push({
    label: 'Manage all brands',
    value: '/brands',
    icon: <LinkIcon />,
    noTooltip: true,
  });

  return [
    {
      menuIcon: <Settings data-testid="Manage brand" />,
      items: brandsHistoryItems,
      key: 'brand-settings',
      label: '',
      render: renderNavbarItem,
    },
  ] satisfies PropsOf<typeof NavbarMenu>[];
};
