import styled from '@emotion/styled';
import { colors, Text, LinkIcon } from '@innovationdepartment/proxima-ui';
import { KLAVIYO_LEARN_MORE_URL } from 'constants/urls';

const TitleWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
`;

const BodyLinkWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const LearnMoreLink = styled.a`
  margin-left: 4px;
  cursor: pointer;
  color: ${colors.blue70};

  &:visited {
    color: ${colors.blue70};
  }

  &:is(:hover, :focus, :link) {
    color: ${colors.blue70};
  }

  text-decoration: none;

  & svg {
    width: 16px;
    height: 16px;
    margin-bottom: -3px; /* ugh... */
    margin-left: 2px;

    & path {
      fill: ${colors.blue70};
    }
  }
`;

const ConnectKlaviyoAccountTitle = () => (
  <TitleWrapper>
    <Text color="neutral100" variant="h5">
      Connect Klaviyo account
    </Text>
    <BodyLinkWrapper>
      <Text color="neutral70" variant="body2Medium">
        Follow the instructions below to connect your account.
        <LearnMoreLink href={KLAVIYO_LEARN_MORE_URL} target="_blank" rel="noreferrer noopener">
          Learn More <LinkIcon />
        </LearnMoreLink>
      </Text>
    </BodyLinkWrapper>
  </TitleWrapper>
);

export default ConnectKlaviyoAccountTitle;
