import { AUTH0_USER_ID_NAMESPACE } from 'constants/defaults';
import { useEffect, useState } from 'react';
import { pick } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth0Api } from 'api';
import { useUserStore } from 'stores';

import * as analytics from 'lib/analytics';
import * as datadog from 'lib/datadog';
import { useProximaSDK, useShowSpinner } from 'hooks';

type UserResponseData = {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailVerified?: boolean;
};

const UserStoreLoader: React.FC = (props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [loaded, setLoaded] = useState<boolean>(false);
  const userProfile = useProximaSDK('UsersApi');
  const { getUserInfo } = useAuth0Api();
  const { updateUserStore } = useUserStore();

  const loading = !loaded;

  useShowSpinner({ show: loading });

  // Initializes the user state
  useEffect(() => {
    if (!user) return;
    const userId = user[AUTH0_USER_ID_NAMESPACE];
    if (!userId) return;

    // set user info on datadog
    datadog.setUserId({ id: userId });

    const initializeUser = async () => {
      // init from Auth0 id token
      const { email, email_verified: emailVerified } = user;
      const userState: UserResponseData = { userId, email, emailVerified };

      // add user profile
      const profileResp = await userProfile
        .getProfile({ userId }, { skipErrorToaster: true })
        .catch(() => ({} as any));

      if (profileResp.status === 200) {
        const { userId: omit, ...toUpdate } = profileResp.data;
        const { firstName, lastName } = toUpdate;
        userState.firstName = firstName;
        userState.lastName = lastName;
      }

      // if email is not verified, check status via Auth0 api
      if (!userState.emailVerified) {
        const userInfoResp = await getUserInfo();
        if (!userInfoResp.error) {
          userState.emailVerified = userInfoResp.data.email_verified;
        }
      }

      // populate store
      await updateUserStore(userState);

      // notify segment.io of user login
      const userTraits = pick(userState, ['firstName', 'lastName', 'emailVerified', 'email']);
      analytics.trackIdentify({ userId, traits: { ...userTraits, action: 'Sign in' } });

      const hasUserProfile = userState.firstName && userState.lastName;

      // if first name and last name are not set, switch to setup/user
      if (!hasUserProfile) {
        navigate('/setup/user');
        setLoaded(true);
        return;
      }

      // email verification routing
      if (!userState.emailVerified) {
        navigate('/email/verify');
      } else if (location.pathname === '/email/verify') {
        navigate('/');
      }

      setLoaded(true);
    };
    initializeUser();
  }, [user]);

  if (loading) return null;

  return <>{children}</>;
};

export default UserStoreLoader;
