import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useFacebook } from 'hooks';
import { Spinner } from '@innovationdepartment/proxima-ui';

const debouncer = debounce((cb) => cb(), 500);

const FacebookOAuthSuccessCallback = () => {
  const { submitCodeAndCreateFBIntegration } = useFacebook();

  useEffect(() => {
    debouncer(submitCodeAndCreateFBIntegration);
  }, []);

  return <Spinner />;
};

export default FacebookOAuthSuccessCallback;
