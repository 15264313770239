import {
  SetShopifyAccessTokenProps,
  ShopifyOAuthFromAppStoreProps,
  ShopifyOAuthProps,
  SubmitCodeForTokenProps,
} from 'types/components/integrations';
import { useHandleApi } from 'hooks';

export default function useOAuth() {
  const { loading, handleApi } = useHandleApi();

  const submitCodeForAccessToken = async (brandId: string, data: SubmitCodeForTokenProps) => {
    const endpoint = `/brand/${brandId}/integration/fb`;
    return handleApi({ endpoint, method: 'POST', data, brandId });
  };

  const generateShopify0AuthUrl = async (data: ShopifyOAuthProps) => {
    const endpoint = `/integration/shopify/auth`;
    return handleApi({ endpoint, method: 'POST', data, brandId: data.brandId });
  };

  const generateShopify0AuthUrlFromAppStore = async (data: ShopifyOAuthFromAppStoreProps) => {
    const endpoint = `/integration/shopify/auth-with-validation`;
    return handleApi({ endpoint, method: 'POST', data, publicEndpoint: true });
  };

  const createIntegration = async (brandId: string, data: SetShopifyAccessTokenProps) => {
    const endpoint = `/brand/${brandId}/integration/shopify`;
    return handleApi({ endpoint, method: 'POST', data, brandId });
  };

  return {
    submitCodeForAccessToken,
    generateShopify0AuthUrl,
    generateShopify0AuthUrlFromAppStore,
    createIntegration,
    loading,
  };
}
