export const allowedKeys = ['name', 'accountId', 'integrationId', 'status'] as const;

export enum AdAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}

type Keys = (typeof allowedKeys)[number];

type AdAccountBase = Omit<{ [key in Keys]: string }, 'status'> & { status: AdAccountStatus };

type AdAccountProperties = {
  acceptedTos: boolean;
};

export type AdAccount = AdAccountBase & { properties: Partial<AdAccountProperties> } & {
  adPixels?: AdPixel[];
};

export type AdAccountStore = {
  adAccount: AdAccount | null;
  clear: () => void;
  update: (adAccount: Partial<AdAccount>) => void;

  adAccountsList: AdAccount[];
  getActiveAdAccountFromList: () => AdAccount | undefined;
  updateAdAccountsList: (adAccounts: AdAccount[]) => void;
};

export type AdPixel = {
  id: string;
  name: string;
  creationTime: Date | string;
  createdAt: Date | string;
  status: AdPixelStatus;
};

export enum AdPixelStatus {
  Active = 'active',
  Deleted = 'deleted',
}
