import { IMenuItem, MoreMenu, Text } from '@innovationdepartment/proxima-ui';
import {
  AudienceTableCellRendererProps,
  SeedAudienceTableRowProps,
  SeedAudienceTableRowExtraFunctions,
  SeedAudienceStatus,
} from 'types/components/audiences';

const AudienceRowCellActions = (
  props: AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
    SeedAudienceTableRowExtraFunctions
) => {
  const { row, showCreateLookalikeModal } = props;

  if (!row) return null;

  const isDisabled = row.status !== SeedAudienceStatus.Delivered;

  const onClick = () => {
    if (!isDisabled) showCreateLookalikeModal(row);
  };

  const items: IMenuItem[] = [
    {
      disabled: isDisabled,
      label: 'Create new lookalike',
      value: 'createNewLookalike',
      tooltipProps: isDisabled
        ? {
            title: (
              <Text variant="tag2Medium" color="white">
                Initial audiences are still processing.
              </Text>
            ),
            arrow: true,
            placement: 'top',
          }
        : undefined,
    },
  ];

  return <MoreMenu hide={!row?.isHovered} items={items} onClick={onClick} />;
};

export default AudienceRowCellActions;
