import styled from '@emotion/styled';
import { Table } from '@innovationdepartment/proxima-ui';
import { AddOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Header } from 'ui/Header';

const Container = styled.div`
  width: 100%;
  padding: 0 32px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface IBrandListProps {
  brands: Record<string, any>[];
  config: any; // https://github.com/InnovationDepartment/proxima.web.components/blob/main/src/lib/Table/README.md
}

const BrandListView = (props: IBrandListProps) => {
  const { brands, config } = props;

  const navigate = useNavigate();
  const onAddBrand = () => navigate('/setup/brand');

  const titleRowButton = {
    label: 'Add new brand',
    onClick: onAddBrand,
    icon: <AddOutlined />,
  };

  return (
    <Container>
      <Header title="Brands" button={titleRowButton} />
      <Table data={brands} config={config as any} />
    </Container>
  );
};

export default BrandListView;
