import ProximaLink from 'ui/ProximaLink';
import SideContentItemWrapper from '../SideContentItemWrapper';

type SideContentTermsAndPrivacyProps = {
  terms: string;
  privacy: string;
};

const SideContentTermsAndPrivacy = (props: SideContentTermsAndPrivacyProps) => {
  const { terms, privacy } = props;
  return (
    <SideContentItemWrapper flow="column" gap={0}>
      <ProximaLink color="gray" to={terms}>
        Terms of service
      </ProximaLink>
      <ProximaLink color="gray" to={privacy}>
        Privacy Policy
      </ProximaLink>
    </SideContentItemWrapper>
  );
};

export default SideContentTermsAndPrivacy;
