import styled from '@emotion/styled';
import { Button, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'constants/integrations';

type DisconnectIntegrationModalViewProps = {
  confirm: () => void;
  close: () => void;
  type: IntegrationType.Facebook | IntegrationType.Klaviyo;
};

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: end;
  width: 100%;
  button:first-of-type {
    margin-right: 1rem;
  }
`;

const TextWrapper = styled.div`
  width: 476px;
  & p {
    font-weight: 400;
  }
`;

const content = {
  [IntegrationType.Facebook]: (
    <>
      Proxima will&nbsp;<b>no longer be able to share custom audiences</b>&nbsp;with your ad
      account.
    </>
  ),
  [IntegrationType.Klaviyo]: <>All configuration info from this account will be cleared.</>,
};

const DisconnectIntegrationModalView = ({
  type,
  confirm,
  close,
}: DisconnectIntegrationModalViewProps) => (
  <TextWrapper>
    <SpaceDivider y={2} />
    <Text variant="body1Semibold" color="neutral90">
      Are you sure you want to disconnect?
    </Text>
    <br />
    <Text variant="body2Medium" color="neutral70">
      {content[type]}
    </Text>
    <SpaceDivider y={6} />
    <ButtonGroup>
      <Button size="small" variant="outlined" label="Cancel" onClick={close} />
      <Button size="small" color="error" label="Disconnect" onClick={confirm} />
    </ButtonGroup>
  </TextWrapper>
);

export default DisconnectIntegrationModalView;
