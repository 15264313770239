import { AUDIENCES_GETTING_STARTED_URL } from 'constants/urls';
import React from 'react';
import styled from '@emotion/styled';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import ProximaLink from 'ui/ProximaLink';
import { SeedAudiencesTableProps } from 'types/components/audiences';
import SeedAudiencesTableEmptyContentSteps from './SeedAudiencesTable.EmptyContentSteps';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
`;

const TextWrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 720px;
  gap: ${({ gap = 16 }) => gap}px;
`;

const ButtonWrapper = styled.div`
  max-width: 260px;
  margin-bottom: 16px;
  width: fill-available;
`;

const SeedAudiencesTableEmptyContent = ({
  showCreateModal,
}: Omit<SeedAudiencesTableProps, 'showCreateLookalikeModal' | 'onRetryAudience'>) => (
  <ContentWrapper>
    <TextWrapper>
      <Text variant="h4" color="neutral80">
        Create your first audience
      </Text>
      <Text variant="body1Semibold" color="neutral60">
        Build high-performing lookalikes to target people most likely to purchase, unlock more
        efficient advertising spend, and scale ads profitably.
      </Text>
    </TextWrapper>
    <SeedAudiencesTableEmptyContentSteps />
    <ButtonWrapper>
      <Button
        label="Generate audiences"
        size="medium"
        onClick={() => showCreateModal()}
        fullWidth
      />
    </ButtonWrapper>
    <Text variant="body2" color="neutral60">
      <ProximaLink to={AUDIENCES_GETTING_STARTED_URL}>Learn more</ProximaLink>&nbsp;about how to use
      Proxima audiences.
    </Text>
  </ContentWrapper>
);

export default React.memo(SeedAudiencesTableEmptyContent);
