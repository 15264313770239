import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { SideBarMenu } from '@innovationdepartment/proxima-ui';

const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
`;

type AccountViewProps = {
  pathname: string;
  onNavigate: (pathname: string) => void;
  menuItems: { label: string; pathname: string }[];
};

const AccountView = ({ menuItems, pathname, onNavigate }: AccountViewProps) => (
  <Layout>
    <SideBarMenu
      title="User profile"
      menuItems={menuItems}
      pathname={pathname}
      onClick={onNavigate}
    />
    <Outlet />
  </Layout>
);

export default AccountView;
