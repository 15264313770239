import { create } from 'zustand';
import pick from 'lodash/pick';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Brand, BrandStore } from 'types/stores/brand-store';

const emptyState = { brandId: '', name: '', domain: '', status: '' };
const allowedKeys = Object.keys(emptyState);
const persistKeyName = 'brand-store';

/* we can now not rely on storage, but Shopify still requires it */
const useBrandStore = create<BrandStore, [['zustand/persist', BrandStore]]>(
  persist(
    (set, get) => ({
      brand: emptyState,
      brandInitialized: false,
      clearBrandStore: () => set({ brand: emptyState, brandInitialized: false }),
      updateBrandStore: (brand: Brand) =>
        set((prevState) => ({ brand: { ...prevState.brand, ...pick(brand, allowedKeys) } })),
      hasBrandStoreInitialized: () => {
        const { brand } = get();
        return !!(brand.brandId && brand.name);
      },
    }),
    { name: persistKeyName, storage: createJSONStorage(() => window.sessionStorage) }
  )
);

export default useBrandStore;
