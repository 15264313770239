import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

const ContentWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  // TODO(Jenky): this is temporary until we figure out the fonts situation
  & > :last-of-type {
    font-weight: 400;
  }
`;

type ContentProps = {
  title: string;
  description: React.ReactNode;
};

const MainContentDescription = ({ title, description }: ContentProps) => (
  <ContentWrapper>
    <Text variant="h5" color="neutral100">
      {title}
    </Text>
    <Text variant="body2" color="neutral100">
      {description}
    </Text>
  </ContentWrapper>
);

export default MainContentDescription;
