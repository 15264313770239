import styled from '@emotion/styled';
import { Text, Tooltip } from '@innovationdepartment/proxima-ui';
import { LookalikeSourcesViewProps } from 'types/components/audiences';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > svg {
    margin-left: 8px;
  }
`;

const TooltipWrapper = styled.div`
  padding: 4px;
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const LookalikeSourcesHeader = ({ lastUpdated }: LookalikeSourcesViewProps) => (
  <HeaderWrapper>
    <Text variant="body1Semibold" color="neutral100">
      Lookalike sources
    </Text>
    <Tooltip
      info
      arrow
      placement="bottom"
      title={
        <TooltipWrapper>
          <Text variant="body3">
            The targeting algorithm analyzes shopping behaviors across Proxima’s shopper universe
            and your store (if connected) to build custom seed audiences tailored to your brand.
          </Text>
          <Text variant="body3">
            Our ever-expanding shopper universe is built off a rich pool of first-party data from
            leading eCom brands.
          </Text>
          <Text variant="body3">
            Connecting your Shopify store gives the algorithm an additional level of accuracy to
            help you find new, high-value customers.
          </Text>
        </TooltipWrapper>
      }
    />
    <div style={{ flex: 1 }} />
    {lastUpdated && (
      <>
        <Text variant="body2" color="neutral100">
          Last updated&nbsp;
        </Text>
        <Text variant="body2" color="neutral60">
          {lastUpdated}
        </Text>
      </>
    )}
  </HeaderWrapper>
);

export default LookalikeSourcesHeader;
