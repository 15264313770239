import { useFormState } from 'react-final-form';
import styled from '@emotion/styled';
import { Text, Button, SpaceDivider, FormField, Card } from '@innovationdepartment/proxima-ui';

import HideCard from 'ui/HideCard';
import { FlavorCategory } from 'types/components/audiences';
import { OrganizationType, OrganizationOptions } from '../constants';
import ComingSoon from './BrandSetup.ComingSoon';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type BrandSetupProps = {
  onCloseBtnClick?: () => void;
  categories: FlavorCategory[];
};

const BrandSetupView = ({ onCloseBtnClick, categories }: BrandSetupProps) => {
  const { values } = useFormState();

  const isAgency = values.organizationType === OrganizationType.Agency;
  const isBrand = values.organizationType === OrganizationType.Brand;
  const isBrandNameEmpty = !values.brandName?.length;

  const disableSubmit = isBrandNameEmpty || isAgency;

  const showCancelBtn = !!onCloseBtnClick;
  const onCancel = () => onCloseBtnClick?.();

  const categoriesList = categories
    // filter out 'Other' category for now
    .filter((category) => category.name !== 'Other')
    .map((category) => ({
      label: category.name,
      value: category.id,
    }));

  return (
    <Card>
      <Text variant="h4">Set up your account</Text>
      <SpaceDivider y={4} />
      <Text variant="body2Semibold">What type of organization is your company?</Text>
      <SpaceDivider y={2} />
      <FormField type="toggleButtons" options={OrganizationOptions} name="organizationType" />
      <HideCard hide={isBrand}>
        <SpaceDivider y={3} />
        <ComingSoon />
      </HideCard>
      <HideCard hide={isAgency}>
        <SpaceDivider />
        <FormField
          label="Brand name"
          type="input"
          fullWidth
          placeholder="Brand name"
          name="brandName"
          size="medium"
        />
        <SpaceDivider y={2} />
        <FormField
          label="Brand URL"
          type="input"
          fullWidth
          placeholder="Brand URL"
          name="website"
          size="medium"
        />
        <SpaceDivider y={2} />
        <FormField
          placeholder="Primary Category"
          label="Primary Category"
          type="select"
          fullWidth
          name="category"
          size="medium"
          options={categoriesList}
        />
      </HideCard>
      <SpaceDivider y={8} />
      <ButtonGroup>
        <Button submit fullWidth label="Continue" disabled={disableSubmit} size="medium" />
        {showCancelBtn && (
          <Button
            fullWidth
            variant="outlined"
            label="Cancel"
            color="primary"
            onClick={onCancel}
            size="medium"
          />
        )}
      </ButtonGroup>
    </Card>
  );
};

export default BrandSetupView;
