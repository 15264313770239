import { ConnectKlaviyoAccountConfigurationViewProps } from 'types/components/klaviyo';
import ConnectKlaviyoAccountConfigurationView from './ConnectKlaviyoAccount.Configuration.View';
import { useForm } from 'react-final-form';
import { useEffect, useRef, useState } from 'react';

const ConnectKlaviyoAccountConfiguration = (props: ConnectKlaviyoAccountConfigurationViewProps) => {
  const { integration, onLoadToken } = props;
  const mounted = useRef(false);
  const [rerender, setRerender] = useState(Date.now());

  const form = useForm();

  /* loads the token once mounted */
  useEffect(() => {
    if (integration && !mounted.current) {
      const loadToken = async () => {
        const token = await onLoadToken();
        if (token) {
          form.reset({ privateKey: token, publicKey: integration.integrationKey });
          setRerender(Date.now());
        }
      };

      loadToken();
    } else if (!integration) {
      form.reset({ privateKey: '', publicKey: '' });
      setRerender(Date.now());
    }

    mounted.current = true;
  }, [integration]);

  return <ConnectKlaviyoAccountConfigurationView {...props} rerender={rerender} />;
};

export default ConnectKlaviyoAccountConfiguration;
