import styled from '@emotion/styled';
import { EditOutlined as EditOutlinedIcon } from '@mui/icons-material';
import { Button, Text, colors } from '@innovationdepartment/proxima-ui';
import { FlavorCategory } from 'types/components/audiences';
import Tag from 'ui/Tag';

const Container = styled.div`
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 172px;
  padding: 8px 16px 32px 16px;
  width: 100%;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.neutral10};
`;

const Header = styled.div``;

const TextWrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: row;
  gap: 120px;

  & > span:first-of-type {
    width: 120px;
  }
`;

const InnerSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  & button {
    padding: 8px 8px 8px 4px;
  }

  > span {
    margin: auto 0;
  }
`;

interface IBrandProfileProps {
  flavorCategory?: FlavorCategory;
  onShowBrandProfileModal: () => void;
}

const BrandProfileView = ({ flavorCategory, onShowBrandProfileModal }: IBrandProfileProps) => (
  <Container>
    <Header>
      <InnerSettingsHeader>
        <Text variant="body1Semibold">Brand profile</Text>
        <Button
          overrideColors={{ textColor: 'neutral100' }}
          label="Edit"
          variant="text"
          startIcon={<EditOutlinedIcon />}
          size="small"
          onClick={onShowBrandProfileModal}
        />
      </InnerSettingsHeader>
      <Divider />
    </Header>
    <Text variant="body2Semibold">Business Categories</Text>
    <TextWrapper>
      <Text color="neutral60" variant="body2Semibold">
        Primary Category
      </Text>
      {flavorCategory ? <Tag label={flavorCategory.name} variant="tag1Medium" /> : ''}
    </TextWrapper>
  </Container>
);

export default BrandProfileView;
