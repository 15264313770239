import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { pick, debounce } from 'lodash';
import { FormApi } from 'final-form';
import { Form, useToaster } from '@innovationdepartment/proxima-ui';

import { validateEmail } from 'utils';
import { useUserStore } from 'stores';
import { UserProfileFormValues } from 'types/components/userProfile';

import * as analytics from 'lib/analytics';

import { useProximaSDK, useShowSpinner } from 'hooks';
import UserProfileView from './UserProfile.View';
import ChangePasswordModal from './ChangePasswordModal';
import toasterConfig from './toasterConfig';

const USER_VALUES = ['firstName', 'lastName', 'email'];

const UserProfile = () => {
  const usersApi = useProximaSDK('UsersApi');
  const [isLoading, setIsLoading] = useState(false);
  const { showToaster } = useToaster();
  const { user, updateUserStore } = useUserStore();
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const initialValues = pick(user, USER_VALUES);

  useShowSpinner({ show: isLoading });

  const sendAnalytics = (newValues: typeof initialValues) =>
    analytics.trackIdentify({
      userId: user!.userId,
      traits: { action: 'Profile updated', ...newValues },
    });

  const onSubmit = async (values: { [key: string]: any }, formApi: FormApi) => {
    if (!user) return;
    const { firstName, lastName } = values;
    setIsLoading(true);

    let response: AxiosResponse<void, any>;
    try {
      response = await usersApi.updateProfile({
        userId: user.userId,
        updateProfileRequest: { firstName, lastName },
      });
    } catch (e) {
      setIsLoading(false);
      return;
    }

    sendAnalytics(values);
    if (response.status === 200) {
      showToaster(toasterConfig.success);
      updateUserStore({ firstName, lastName });
      formApi.initialize({ ...values, firstName, lastName });
      formApi.reset();
    }
    setIsLoading(false);
  };

  const validate = (values: UserProfileFormValues) => {
    const errors: { [key: string]: string } = {};
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (validateEmail(values.email)) {
      errors.email = 'Email is invalid';
    }
    return errors;
  };

  const debouncedSubmit = debounce(onSubmit, 200);

  const openChangePasswordModal = () => setChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setChangePasswordModalOpen(false);

  return (
    <>
      <Form validate={validate} onSubmit={debouncedSubmit} initialValues={initialValues}>
        <UserProfileView
          openChangePasswordModal={openChangePasswordModal}
          submitDisabled={isLoading}
        />
      </Form>
      <ChangePasswordModal open={changePasswordModalOpen} onClose={closeChangePasswordModal} />
    </>
  );
};

export default UserProfile;
