import { useFormState } from 'react-final-form';
import { Text, Button, SpaceDivider, FormField, Card } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import { formatPhoneNumber } from 'utils';

const NameWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const UserSetupView = () => {
  const { valid } = useFormState();

  return (
    <Card>
      <Text variant="h4">Enter your details</Text>
      <SpaceDivider />
      <Text variant="body1">Let us know a bit more about yourself.</Text>
      <SpaceDivider y={3} />
      <NameWrapper>
        <FormField
          type="input"
          name="firstName"
          fullWidth
          placeholder="First name"
          label="First name"
          size="medium"
        />
        <FormField
          type="input"
          name="lastName"
          fullWidth
          placeholder="Last name"
          label="Last name"
          size="medium"
        />
      </NameWrapper>
      <SpaceDivider y={2} />
      <FormField
        type="input"
        name="phoneNumber"
        fullWidth
        placeholder="Phone number (Optional)"
        label="Phone number (Optional)"
        format={formatPhoneNumber}
        size="medium"
      />
      <SpaceDivider y={4} />
      <Button submit fullWidth label="Continue" disabled={!valid} size="medium" />
    </Card>
  );
};

export default UserSetupView;
