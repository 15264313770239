import { Dispatch, ReactNode, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { Divider, SelectInput, Text } from '@innovationdepartment/proxima-ui';
import ToggleStandardPlans from './Plans/ToggleStandardPlans.Container';
import ProPlan from './Plans/ProPlan.Container';

type BuildPlanModalContentViewProps = {
  spendAmount: string | undefined;
  setSpendAmount: Dispatch<SetStateAction<string | undefined>>;
  brandId: string;
};

const PlansWrapper = styled.div`
  display: block;
  width: 100%;
`;

const SpendLabel = styled.div`
  max-width: 230px;
`;

const SpendSelectorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
  align-items: center;
`;

const SelectInputWrapper = styled.div`
  div {
    min-width: 200px;
  }
`;

const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const options = [
  { label: 'Select', value: 'none' },
  { label: 'Yes, I spend more than $20,000', value: 'yes' },
  { label: 'No, I spend less than $20,000', value: 'no' },
];

const renderPlan = (
  spendAmount: BuildPlanModalContentViewProps['spendAmount'],
  brandId: string
) => {
  let content: ReactNode = null;

  if (spendAmount === 'no') content = <ToggleStandardPlans brandId={brandId} />;
  if (spendAmount === 'yes') content = <ProPlan />;

  return content;
};

const BuildPlanModalContentView = ({
  spendAmount,
  setSpendAmount,
  brandId,
}: BuildPlanModalContentViewProps) => (
  <Wrapper>
    <SpendSelectorWrapper>
      <SpendLabel>
        <Text variant="body1">Do you spend more than $20,000 a month on Meta ads?</Text>
      </SpendLabel>
      <SelectInputWrapper>
        <SelectInput options={options} onChange={(val) => setSpendAmount(val)} />
      </SelectInputWrapper>
    </SpendSelectorWrapper>
    {spendAmount !== 'none' && <Divider />}
    <PlansWrapper>{renderPlan(spendAmount, brandId)}</PlansWrapper>
  </Wrapper>
);

export default BuildPlanModalContentView;
