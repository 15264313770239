import { useFormState } from 'react-final-form';
import { Button } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import LoadingSpinner from 'ui/LoadingSpinner';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import { CampaignFormProps, GenerateNewCampaignViewProps } from 'types/components/audienceCampaign';

import AdPixel from '../Layout/AdPixel';
import CampaignAttributes from '../Layout/CampaignAttributes/CampaignAttributes.Container';
import SkipCampaign from '../Layout/SkipCampaign';

const ModalSection = styled.div<{ flex?: number }>`
  gap: 16px;
  display: flex;
  flex-flow: column;
  /*flex-basis: ${({ flex = 1 }) => flex * 350}px; /* bad math */
  flex-grow: ${({ flex = 1 }) => flex};
  flex-shrink: 0;
`;

const GenerateNewCampaignView = (props: GenerateNewCampaignViewProps) => {
  const { loading, adPixels, onFormSkipClick } = props;

  const formState = useFormState<CampaignFormProps>();

  const { valid } = formState;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <>
          <SkipCampaign onFormSkipClick={onFormSkipClick} />
          <Button
            submit
            disabled={!valid || loading}
            startIcon={loading && <LoadingSpinner />}
            label="Create campaign"
          />
        </>
      }
    >
      <ModalSection flex={1}>
        <CampaignAttributes />
        <AdPixel options={adPixels} />
      </ModalSection>
    </ModalBaseChildrenWrapper>
  );
};

export default GenerateNewCampaignView;
