import React, { useEffect } from 'react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import axios, { AxiosError } from 'axios';

const SERVER_500_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

const ApiErrorBoundary: React.FC = ({ children }) => {
  const { showToaster } = useToaster();

  // Handles all API call errors and show a toaster
  useEffect(() => {
    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
      const options: Parameters<typeof showToaster>[number] = {
        message: SERVER_500_ERROR_MESSAGE,
        variant: 'error',
      };

      if (error.config?.skipErrorToaster) return Promise.reject(error);

      if (error.response) {
        const { status } = error.response;
        const isServerError = status >= 500;
        const isClientError = status >= 400 && status < 500;

        // Client errors
        // =============================================================
        const isUnauthorizedError = status === 401;
        const isForbiddenError = status === 403;

        // 400 responses
        // [{ path: 'firstName', message: 'First name is required' }, {}, {},{} ]

        if (isServerError) options.message = SERVER_500_ERROR_MESSAGE;
        if (isClientError) {
          const responseMsg = Array.isArray(error.response.data)
            ? error.response.data[0].message
            : error.response.data;
          const errorMsg = responseMsg || SERVER_500_ERROR_MESSAGE;
          options.message = errorMsg;
        }
        if (isUnauthorizedError) options.message = 'You are not authorized to perform this action.';
        if (isForbiddenError) options.message = 'You are not allowed to perform this action.';

        showToaster(options);
      }

      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use((response) => response, onResponseError);

    return () => {
      // clear interceptors after unmount
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return <>{children}</>;
};

export default ApiErrorBoundary;
