export enum CustomTosStep {
  AcceptTerms = 'accept-terms',
  UserClickedAccepted = 'user-clicked-accepted',
}

export type AcceptFacebookCustomTermsOfServiceViewProps = {
  step: CustomTosStep;
  loading: boolean;
  backToUrl: string;
  onSkipClick: () => void;
  onClose?: () => void;
  onAcceptTermsClick: () => void;
  onTermsAlreadyAcceptedClick: () => void;
};
