import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import SectionLayout from 'ui/SectionLayout';
import AudiencesGettingStartedLinks from 'ui/AudiencesGettingStartedLinks';
import { AudiencesProps } from 'types/components/audiences';
import AudiencesRecommendedActions from './Audiences.RecommendedActions';
import SeedAudiencesTable from './SeedAudiencesTable';

const AudiencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 52px;
`;

const headerContent = (
  <div>
    <Text variant="body1" color="neutral80">
      Leverage an ever-expanding universe of shopper data and predictive AI to reach new customers,
      lower acquisition costs, and increase ROI on ad spend.
    </Text>
    <AudiencesGettingStartedLinks />
  </div>
);

const AudiencesView: React.FC<AudiencesProps> = ({
  onRetryAudience,
  seedAudiences,
  showCreateModal,
  showCreateLookalikeModal,
  showNewGalaxiesBadge,
}) => (
  <SectionLayout title="Audiences" headerContent={headerContent}>
    <AudiencesWrapper>
      <AudiencesRecommendedActions
        showNewGalaxiesBadge={showNewGalaxiesBadge}
        showCreateModal={showCreateModal}
      />
      <SeedAudiencesTable
        onRetryAudience={onRetryAudience}
        showCreateLookalikeModal={showCreateLookalikeModal}
        seedAudiences={seedAudiences}
        showCreateModal={showCreateModal}
      />
    </AudiencesWrapper>
  </SectionLayout>
);

export default AudiencesView;
