import { AUTH0_USER_ID_NAMESPACE } from 'constants/defaults';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AllowedColors,
  Button,
  MagicStarBig,
  Modal,
  Text,
  colors,
  useToaster,
} from '@innovationdepartment/proxima-ui';
import ButtonGroup from 'ui/ButtonGroup';

import * as analytics from 'lib/analytics';

const TextWrapper = styled.div`
  margin: 24px 0 56px;
  max-width: 382px;
`;

type GetEarlyAccessModalProps = {
  show: boolean;
  onClose: () => void;
};

const debouncer = debounce((cb) => cb(), 500);

const GetEarlyAccessModal = (props: GetEarlyAccessModalProps) => {
  const { user } = useAuth0();
  const { showToaster } = useToaster();
  const { onClose, show } = props;

  const requestAccess = () => {
    debouncer(() => {
      if (user?.[AUTH0_USER_ID_NAMESPACE]) {
        /* for now we're requesting to TikTok */
        /* no need to make this generic (but it can be in the future) */
        analytics.trackActionEvent({
          location: 'RequestAccess',
          area: 'TikTokModal',
          action: 'Clicked',
          userId: user[AUTH0_USER_ID_NAMESPACE],
          element: 'RequestAccessButton',
        });
      }

      showToaster({ variant: 'success', message: 'Request submitted! We’ll be in touch.' });
    });

    onClose();
  };

  return (
    <Modal
      resizable
      noPadding={false}
      open={show}
      title="Get early access"
      onClose={onClose}
      allowCloseOnClickOutside
    >
      <TextWrapper>
        <Text variant="body1">
          Get early access to AI audiences on more platforms including TikTok, Google, Snapchat, and
          Pinterest.
        </Text>
      </TextWrapper>
      <ButtonGroup>
        <Button
          size="small"
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          color="secondary"
        />
        <Button
          overrideColors={{
            backgroundColor: 'linear-gradient(to right, #45EDBA, #89D0E9)',
            textColor: colors.neutral100 as AllowedColors,
          }}
          startIcon={
            <div style={{ display: 'inherit', marginRight: -4 }}>
              <MagicStarBig />
            </div>
          }
          variant="contained"
          size="small"
          label="Request access"
          onClick={requestAccess}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default GetEarlyAccessModal;
