import styled from '@emotion/styled';
import { Button } from '@innovationdepartment/proxima-ui';
import { BrandIntegrationStatus } from 'constants/integrations';
import { useFormState } from 'react-final-form';
import { ConnectKlaviyoAccountConfigurationViewProps } from 'types/components/klaviyo';
import ButtonGroup from 'ui/ButtonGroup';
import LoadingSpinner from 'ui/LoadingSpinner';

const StyledButtonGroup = styled(ButtonGroup)`
  margin: 16px 20px 20px;
  align-items: stretch;
  gap: 12px;
`;

const ConnectKlaviyoAccountConfigurationButtons = (
  props: ConnectKlaviyoAccountConfigurationViewProps
) => {
  const { loading, onDisconnect, status } = props;
  const formState = useFormState();

  const isConnected = status === BrandIntegrationStatus.Connected;
  const isDisconnected = status === BrandIntegrationStatus.Disconnected;
  const isNeverConnected = status === BrandIntegrationStatus.NeverConnected;

  const showDisconnect = isConnected;

  const isSubmitting = formState.submitting;
  const isInvalid = formState.invalid;
  const isModified = formState.dirtySinceLastSubmit;
  const isSubmitInvalid = formState.hasSubmitErrors && !isModified;
  const isTouched = formState.dirty;
  const isInvalidAfterSubmit = !isTouched && isSubmitInvalid; // errors from submit function, normally from API
  const isInvalidAfterReset = isInvalid && !isModified; // errors from submit function
  let isDisabled = isInvalidAfterSubmit || isInvalidAfterReset || isSubmitting;

  let primaryLabel = 'Connect';

  if (isConnected) {
    primaryLabel = 'Refresh connection';
    isDisabled = (!isModified && !isTouched) || isSubmitting;
  }

  if (loading) {
    if (isNeverConnected || isDisconnected) primaryLabel = 'Connecting';
    else if (isSubmitting) primaryLabel = 'Refreshing';
  }

  return (
    <StyledButtonGroup>
      {showDisconnect && (
        <Button
          label="Disconnect"
          variant="outlined"
          onClick={onDisconnect}
          startIcon={loading && isNeverConnected && <LoadingSpinner />}
        />
      )}
      <Button
        submit
        disabled={isDisabled}
        startIcon={loading && isSubmitting && <LoadingSpinner />}
        label={primaryLabel}
      />
    </StyledButtonGroup>
  );
};

export default ConnectKlaviyoAccountConfigurationButtons;
