import { Navigate, Route, Routes } from 'react-router-dom';
import ConnectKlaviyoAccount from './ConnectKlaviyoAccount';

const KlaviyoRouter = () => (
  <Routes>
    <Route path="">
      <Route path="connect-account" element={<ConnectKlaviyoAccount />} />
      <Route index element={<Navigate to="connect-account" />} />
    </Route>
  </Routes>
);

export default KlaviyoRouter;
