import FBTable from '@innovationdepartment/proxima-fbtable';
import { DatePicker } from '@innovationdepartment/proxima-ui';
import { PropsOf } from '@emotion/react';
import { AdAccount } from 'types/stores/ad-account-store';

export const PAGE_DEFAULT_SIZE = 20;

export type AdManager = 'ad' | 'adset' | 'campaign';
export type FBTableTabs = 'ads' | 'adsets' | 'campaigns';
export type PaginationSettings = {
  pageSize?: number;
  pageNumber?: number;
  queryItems?: Record<string, string | string[]>;
};

export type PageSettings = {
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
};

export type StateData<T> = {
  data: T[];
  summary?: any; // TODO(Jenky): Define these props
};

type FBTableProps = PropsOf<typeof FBTable>;
export type CampaignData = FBTableProps['campaigns'];
export type AdsetData = FBTableProps['adsets'];
export type AdData = FBTableProps['ads'];

export type FacebookTableViewProps = {
  loading: boolean;
  integrationsLoading: boolean;
  adAccount?: AdAccount;
  showMissingIntegrationOverlay: boolean;
  lastUpdate: string | null;
  // TODO: Address these data types
  campaigns: CampaignData;
  adsets: AdsetData;
  ads: AdData;
  tab: FBTableTabs;
  dateRange: PropsOf<typeof DatePicker>['dateRange'];
  onDateRangeChange: (dates: FacebookTableViewProps['dateRange']) => void; // PropsOf<typeof FacebookTableSearchFilter>['onDateRangeChange'];
  onNext?: () => void;
  onPrevious?: () => void;
  onTabClick: PropsOf<typeof FBTable>['onTabClick'];
  onRowSelect: PropsOf<typeof FBTable>['onRowSelect'];
};
