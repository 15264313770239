import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { BrandItem, UserBrandAccess } from '@innovationdepartment/proxima-sdk-axios';
import { useEffect, useState } from 'react';
import { useBrandStore, useUserStore } from 'stores';
import { Brand } from 'types/stores/brand-store';

import { useProximaSDK, useShowSpinner } from 'hooks';
import BrandListView from './BrandList.View';
import brandRowRender from './brandRowRender';

const BrandList = () => {
  const brandsApi = useProximaSDK('BrandsApi');
  const usersApi = useProximaSDK('UsersApi');

  const navigate = useNavigate();
  const { user } = useUserStore();
  const { /* brand, */ clearBrandStore } = useBrandStore();

  const [fetchingBrands, setFetchingBrands] = useState(true);
  const [brands, setBrands] = useState<any[]>([]);

  const loading = !user?.userId || fetchingBrands;

  useShowSpinner({ show: loading });

  useEffect(() => {
    if (!user) return;
    const fetchBrands = async () => {
      let brandUsersRes: AxiosResponse<UserBrandAccess[], any>;

      try {
        // get brands for user
        brandUsersRes = await usersApi.getAllBrandsForUser({ userId: user.userId });
      } catch (e) {
        // TODO(Jenky): Handle error
        return;
      }

      const { data: brandIds = [] } = brandUsersRes;

      // navigate to setup if no brands
      if (!brandIds.length) {
        // prevents redirect loop if brand is set up in our store
        // see Setup.State.tsx:25
        clearBrandStore();
        navigate('/setup');
        return;
      }

      /* TODO(Jenky): remove this for the time being */
      // /* if only one brand, navigate into the app */
      // if (brandIds.length === 1 && !brand?.brandId) {
      //   navigate(`/brand/${brandIds[0].brandId}/audiences`);
      //   return;
      // }

      const brandsIds = brandIds.map((brandObj) => brandObj.brandId).join(',');
      let brandsRes: AxiosResponse<BrandItem[], any>;

      try {
        // get brand details
        brandsRes = await brandsApi.getBrandBatch({ brandId: brandsIds });
      } catch (e) {
        // TODO(Jenky): Handle error
        return;
      }

      setBrands(brandsRes.data);

      setFetchingBrands(false);
    };

    fetchBrands();
  }, [user]);

  if (loading) return null;

  const onBrandRowClick = async (row: Brand) => {
    navigate(`/brand/${row.brandId}/audiences`);
  };

  const brandListConfig: any = {
    onRowClick: onBrandRowClick,
    cellRenderer: brandRowRender,
    columns: {
      columnId: 'brandId',
      order: ['name', 'createdAt'],
      name: { label: 'Brand name' },
      createdAt: { label: 'Date joined', width: 240 },
    },
  };

  return <BrandListView brands={brands} config={brandListConfig} />;
};

export default BrandList;
