import styled from '@emotion/styled';
import { Button, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import CheckMarkLineView from './CheckMarkLine.View';

type IncludedSectionProps = {
  options: string[];
  buttonText: string;
  onSubmit: () => void;
  buttonDisabled?: boolean;
};

const IncludedWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > span:first-child {
    padding-bottom: 16px;
  }
`;

const IncludedSection = ({
  options,
  buttonText,
  onSubmit,
  buttonDisabled,
}: IncludedSectionProps) => (
  <IncludedWrapper>
    <Text variant="overline1">INCLUDED:</Text>
    {options.map((text) => (
      <CheckMarkLineView key={text} text={text} />
    ))}
    <SpaceDivider y={4} />
    <Button disabled={buttonDisabled} label={buttonText} fullWidth onClick={onSubmit} />
  </IncludedWrapper>
);

export default IncludedSection;
