import { useLocation } from 'react-router-dom';

import { useBrandStore, useUserStore } from 'stores';
import LockedBrandBannerView from './LockedBrandBanner.View';
import { HELP_LINK } from 'constants/emails';
import * as analytics from 'lib/analytics';
import { BrandStatus } from 'types/stores/brand-store';

const LockedBrandBanner = () => {
  const location = useLocation();
  const { brand } = useBrandStore();
  const { user } = useUserStore();

  const ROUTES_TO_INCLUDE = [
    `/brand/${brand?.brandId}/audiences`,
    `/brand/${brand?.brandId}/integrations`,
  ];

  const mailToLink = () => {
    analytics.trackActionEvent({
      action: 'Clicked',
      userId: user?.userId!,
      brandId: brand.brandId,
      location: 'LockBannerContactUs',
      element: 'ContactUsButton',
    });

    const a = document.createElement('a');
    a.setAttribute('href', HELP_LINK);
    a.click();
  };

  const shouldSkipBanner = ROUTES_TO_INCLUDE.some((path) => location.pathname === path);

  // don't show on specific routes regardless of the brand status
  if (!shouldSkipBanner) return null;

  // if the brand is locked, then we need to show the banner.
  // it shouldn't show for any status that is not locked
  if (brand.status !== BrandStatus.Locked) return null;

  return <LockedBrandBannerView mailToLink={mailToLink} />;
};

export default LockedBrandBanner;
