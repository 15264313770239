import { UserProfileViewProps } from 'types/components/userProfile';
import SettingsSectionLayout from 'ui/SettingsSectionLayout/SettingsSectionLayout';
import UserProfileForm from './UserProfile.Form';

const UserProfileView = (props: UserProfileViewProps) => {
  const { submitDisabled, openChangePasswordModal } = props;
  return (
    <SettingsSectionLayout title="General">
      <UserProfileForm
        submitDisabled={submitDisabled}
        openChangePasswordModal={openChangePasswordModal}
      />
    </SettingsSectionLayout>
  );
};

export default UserProfileView;
