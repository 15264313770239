import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import { useNavigate } from 'react-router-dom';
import AllSetView from './AllSet.View';
import { useShopify } from 'hooks';
import { useEffect } from 'react';
import LoadingSpinner from 'ui/LoadingSpinner';

const TIMEOUT_TO_REDIRECT = 1000 * 5; // 5 seconds

const AllSet = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const { getShopifyURLRedirect } = useShopify();
  const { brand } = useBrandStore();
  const navigate = useNavigate();

  const onClick = () => {
    /* if user is coming from audiences page, redirect to audiences page after setup is complete */
    let navigateTo = getShopifyURLRedirect({});

    /* send back to default integrations page if no redirect is found */
    navigateTo = navigateTo || `/brand/${brand.brandId}/integrations`;

    navigate(navigateTo as string);
  };

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) return;

    setTimeout(() => {
      loginWithRedirect({ action: 'sign-up' });
    }, TIMEOUT_TO_REDIRECT);
  }, [isLoading, isAuthenticated]);

  if (isLoading) return <LoadingSpinner />;

  return <AllSetView isAuthenticated={isAuthenticated} onClick={onClick} />;
};

export default AllSet;
