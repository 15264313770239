import React from 'react';
import styled from '@emotion/styled';
import { CustomCard, AddCircle, ActionButtonProps } from '@innovationdepartment/proxima-ui';
import { PillProps } from '@innovationdepartment/proxima-ui/dist/esm/types/pill';
import OnboardingNavbar from './Onboarding.Navbar';
import OnboardingMainSection from './Onboarding.MainSection';
import OnboardingBrands from './Onboarding.Brands';

const OnboardingWrapper = styled.div`
  margin-top: 72px;
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

type IntegrationItem = {
  // TODO(Jenky): add type prop here?
  icon: JSX.Element;
  title: React.ReactNode;
  description: string;
  onClick: () => void;
  pill?: PillProps;
  loading: boolean;
  isRequired?: boolean;
  isConnected: boolean;
  isUnavailable?: boolean;
};

type OnboardingViewProps = {
  integrationsItems: IntegrationItem[];
  onContinue: () => void;
  onSkip: () => void;
};

const OnboardingView = (props: OnboardingViewProps) => {
  const { integrationsItems, onSkip, onContinue } = props;

  const skip = integrationsItems.every(
    (integration) => !(integration.isRequired && integration.isConnected)
  );

  return (
    <>
      <OnboardingNavbar skip={skip} onSkip={onSkip} onContinue={onContinue} />
      <OnboardingWrapper>
        <OnboardingMainSection>
          {integrationsItems.map((integration) => {
            const { icon, title, description, onClick, pill, loading, isConnected, isUnavailable } =
              integration;

            const actionButton: ActionButtonProps | undefined = isUnavailable
              ? undefined
              : {
                  onClick: isConnected ? undefined : onClick,
                  label: isConnected ? 'Connected' : 'Connect',
                  loading,
                  icon: isConnected ? <></> : <AddCircle />,
                  variant: isConnected ? 'success' : 'default',
                };

            return (
              <CustomCard
                headerProps={{
                  icon,
                  pill,
                  actionButton,
                }}
                key={description}
                label={title}
                contentText={description}
                size="medium"
              />
            );
          })}
        </OnboardingMainSection>
        <OnboardingBrands />
      </OnboardingWrapper>
    </>
  );
};

export default OnboardingView;
