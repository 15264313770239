import { useContext, useEffect } from 'react';
import { SpinnerWrapperContext } from 'ui/SpinnerWrapper';
import { ISpinnerContext } from 'types/context/spinnerContext';

const useShowSpinner = ({ show } = { show: false }) => {
  const { showSpinner } = useContext<ISpinnerContext>(SpinnerWrapperContext);

  useEffect(() => {
    showSpinner(show);
  }, [show]);
};

export default useShowSpinner;
