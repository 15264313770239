import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { LookalikeSourcesDataSourceInfoViewProps } from 'types/components/audiences';
import LoadingSpinner from 'ui/LoadingSpinner';
import Tag from 'ui/Tag';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 8px;
`;

const LookalikeSourcesDataSourceInfoView = ({
  loading,
  shopName,
  flavorCategory,
}: LookalikeSourcesDataSourceInfoViewProps) => (
  <ContentWrapper>
    <Text variant="body2Semibold">Proxima shopper universe</Text>
    {loading && <LoadingSpinner />}
    {flavorCategory ? <Tag label={flavorCategory.name} /> : !loading && '-'}
    <Text variant="body2Semibold">First-party data</Text>
    {loading && <LoadingSpinner />}
    {!loading && <Tag disabled={!shopName} label={shopName ?? 'Your Shopify store'} />}
  </ContentWrapper>
);

export default LookalikeSourcesDataSourceInfoView;
