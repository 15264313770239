import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import LoadingSpinner from 'ui/LoadingSpinner';
import { GenerateAudiencesForm, LookalikeSourcesViewProps } from 'types/components/audiences';
import LookalikeSourcesAudiencesLookalike from './LookalikeSourcesAudiences.Lookalike';

const LookalikeWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  height: 248px;
`;

const LookalikeSourcesAudiencesLookalikes = (props: LookalikeSourcesViewProps) => {
  const formState = useFormState<GenerateAudiencesForm>();
  const { lookalikeSize } = formState.values;
  const { loading, galaxies } = props;

  // TODO(Jenky): ask product about this addon
  if (loading) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    );
  }

  return (
    <LookalikeWrapper>
      {galaxies.map((galaxy) => (
        <LookalikeSourcesAudiencesLookalike key={galaxy.id} galaxy={galaxy} size={lookalikeSize} />
      ))}
    </LookalikeWrapper>
  );
};

export default LookalikeSourcesAudiencesLookalikes;
