import styled from '@emotion/styled';
import { Button, Divider, Text, LinkIcon, colors, Meta } from '@innovationdepartment/proxima-ui';
import { Link } from 'react-router-dom';
import {
  AcceptFacebookCustomTermsOfServiceViewProps,
  CustomTosStep,
} from 'types/components/audiencesCustomTos';
import ButtonGroup from 'ui/ButtonGroup';
import LoadingSpinner from 'ui/LoadingSpinner';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';

const ContentWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InsideContent = styled.div`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h5 {
    margin-bottom: 8px;
  }

  & > p {
    margin-bottom: 48px;
  }

  & > hr {
    margin-bottom: 16px;
  }

  & svg path {
    fill: ${colors.neutral100};
  }
`;

const SkipButtonWrapper = styled.div``;

const AcceptFacebookCustomTermsOfServiceView = (
  props: AcceptFacebookCustomTermsOfServiceViewProps
) => {
  const {
    step,
    loading,
    backToUrl,
    onClose,
    onSkipClick,
    onAcceptTermsClick,
    onTermsAlreadyAcceptedClick,
  } = props;

  return (
    <IntegrationsModal
      open
      full
      icon={<Meta />}
      onClose={onClose}
      title={
        <>
          <Text variant="overline1" color="neutral60">
            Integrate
          </Text>
          <Text variant="h6">Meta</Text>
        </>
      }
    >
      <ContentWrapper>
        <InsideContent>
          <Text variant="h5" color="neutral100">
            Meta Custom Audiences Terms not accepted
          </Text>
          <Text variant="body1" color="neutral100">
            In order to be able to add Proxima audiences to your Business Account, please make sure
            to accept Meta&apos;s Custom Audiences Terms of Service
          </Text>
          <Divider margin={16} />
          <ButtonGroup spaceBetween>
            {step === CustomTosStep.AcceptTerms && !onClose && (
              <SkipButtonWrapper onClick={onSkipClick}>
                <Link to={backToUrl}>
                  <Text color="neutral80" variant="body1">
                    Do it later
                  </Text>
                </Link>
              </SkipButtonWrapper>
            )}
            {step === CustomTosStep.AcceptTerms && (
              <Button
                label="Accept terms"
                onClick={onAcceptTermsClick}
                variant="outlined"
                endIcon={<LinkIcon />}
              />
            )}
            {/* this element works as padding to position elements */}
            {step === CustomTosStep.UserClickedAccepted && <span />}
            {step === CustomTosStep.UserClickedAccepted && (
              <Button
                disabled={loading}
                startIcon={loading && <LoadingSpinner />}
                label="I accepted"
                onClick={onTermsAlreadyAcceptedClick}
              />
            )}
          </ButtonGroup>
        </InsideContent>
      </ContentWrapper>
    </IntegrationsModal>
  );
};

export default AcceptFacebookCustomTermsOfServiceView;
