import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Meta, ProximaLogoWithTextBlack, useToaster } from '@innovationdepartment/proxima-ui';
import { usePolling, useFacebook, useProximaSDK, useQuery, useShowSpinner } from 'hooks';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';
import { AdAccount, AdAccountStatus } from 'types/stores/ad-account-store';
import { useBrandStore } from 'stores';
import AdAccountSelectionView from './AdAccountSelection.View';

type AdAccountSelectionProps = {
  /**
   * only used for testing purposes - to mock the polling time
   * @default 1000
   * */
  polling?: number;
};

const AdAccountSelection = (props: AdAccountSelectionProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToaster } = useToaster();
  const { pollUntilCondition } = usePolling();
  const { isFacebookReconnect, setIsFacebookReconnect } = useFacebook();
  const { getQueryParams } = useQuery();

  const { redirect = '/brands', brandId: queryParamBrandId } = getQueryParams();
  const { brand } = useBrandStore();

  /* internal state */
  const [selectedAdAccount, setSelectedAdAccount] = useState<AdAccount>();
  const [adAccounts, setAdAccounts] = useState<AdAccount[]>([]);
  /* flags */
  const [isFetchingAdAccounts, setIsFetchingAdAccounts] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  const brandId = queryParamBrandId || brand.brandId;
  const fbIntegrationApi = useProximaSDK('FbIntegrationApi');

  const saveAdAccount = async (adAccount: AdAccount) => {
    if (!adAccount) return;

    setIsError(false);
    setIsSaving(true);
    try {
      await fbIntegrationApi.updateAdAccount({
        brandId,
        adAccountId: adAccount.accountId,
        updateAdAccountInput: { status: AdAccountStatus.Active },
      });
      setIsSaving(false);

      const hasAcceptedTos = adAccount.properties?.acceptedTos;

      const url = hasAcceptedTos
        ? redirect
        : `/brand/${brandId}/audiences/custom-tos?adAccount=${adAccount.accountId}&redirect=${redirect}`;
      navigate(url);
      return;
    } catch (err) {
      setIsError(true);
    }
    setIsSaving(false);
  };

  const refreshAdAccounts = async () => {
    try {
      const response = await fbIntegrationApi.refreshAdAccounts({ brandId });
      setAdAccounts(response.data as AdAccount[]);
    } catch (err) {
      /** */
    }
  };

  const fetchAdAccounts = () => {
    const reconnectGracePeriod = 10;
    const pollLimit = 30;
    let pollCount = 0;
    let stopPolling = false;
    // @todo convert this to an actual hook
    const fetchAndProcessAdAccounts = async () => {
      const actualPollCount = pollCount - 1;
      pollCount += 1;
      setIsFetchingAdAccounts(true);
      try {
        const response = await fbIntegrationApi.getAdAccounts({ brandId });
        const isAdAccountEmpty = !Array.isArray(response.data) || response.data?.length === 0;

        const isFacebookReconnectAndAwaitingOnAdAccount =
          isFacebookReconnect() && actualPollCount < reconnectGracePeriod && isAdAccountEmpty;

        stopPolling = actualPollCount >= pollLimit;

        // if the account had Active FB integration, wait for the data
        if (isFacebookReconnectAndAwaitingOnAdAccount) return;

        if (!isAdAccountEmpty) {
          stopPolling = true;
          setIsFetchingAdAccounts(false);
          setAdAccounts(response.data as AdAccount[]);
          setIsFacebookReconnect(undefined);
          return;
        }

        if (stopPolling) {
          showToaster({
            variant: 'warning',
            message: 'Unable to fetch ad accounts. Please refresh the page.',
          });
          setIsFacebookReconnect(undefined);
          setIsFetchingAdAccounts(false);
        }
      } catch (err) {
        stopPolling = true;
        setIsFetchingAdAccounts(false);
      }
    };

    const { polling = 1000 } = props;

    pollUntilCondition({
      cb: fetchAndProcessAdAccounts,
      condition: () => stopPolling,
      waitForFirstCall: true,
      timeout: polling,
    })();
  };

  useEffect(() => {
    fetchAdAccounts();
  }, []);

  let icon: React.ReactNode = <Meta />;
  let title: React.ReactNode = 'Meta';

  const isSetup = location.pathname.includes('/setup');

  if (isSetup) {
    icon = null;
    title = (
      <div style={{ marginLeft: 48, display: 'flex' }}>
        <ProximaLogoWithTextBlack />
      </div>
    );
  }

  useShowSpinner({ show: fbIntegrationApi.loading });

  return (
    <IntegrationsModal
      icon={icon}
      title={title}
      full
      onClose={isSetup ? undefined : () => navigate(`/brand/${brandId}/integrations`)}
      open
    >
      <AdAccountSelectionView
        refreshAdAccounts={refreshAdAccounts}
        brandId={brandId}
        isLoading={isFetchingAdAccounts || fbIntegrationApi.loading}
        isSaving={isSaving}
        isError={isError}
        saveAdAccount={() => selectedAdAccount && saveAdAccount(selectedAdAccount)}
        adAccounts={adAccounts}
        selectedAdAccount={selectedAdAccount}
        setSelectedAdAccount={setSelectedAdAccount}
        redirectTo={redirect}
      />
    </IntegrationsModal>
  );
};

export default AdAccountSelection;
