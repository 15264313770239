import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner } from '@innovationdepartment/proxima-ui';

// https://auth0.com/developers/hub/code-samples/spa/react-javascript/basic-authentication-react-router-6
const ProtectedRoute = ({ component }: { component: any }) => {
  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <Spinner />,
  });

  return <Component />;
};

export default ProtectedRoute;
