import { Modal } from '@innovationdepartment/proxima-ui';
import DisconnectIntegrationModalView from './DisconnectIntegrationModal.View';
import { PropsOf } from '@emotion/react';

export type DisconnectIntegrationModalProps = {
  open: boolean;
} & PropsOf<typeof DisconnectIntegrationModalView>;

const DisconnectIntegrationModal = ({
  open,
  type,
  confirm,
  close,
}: DisconnectIntegrationModalProps) => (
  <Modal noPadding={false} open={open} title="Disconnect account?">
    <DisconnectIntegrationModalView type={type} confirm={confirm} close={close} />
  </Modal>
);
export default DisconnectIntegrationModal;
