/* eslint no-underscore-dangle: 0 */
import { StoreApi } from 'zustand';

type ZustandStore = StoreApi<Record<string | number | symbol, any>>;
type Stores = { [key: string]: ZustandStore };

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect: ({
        name,
      }: {
        name: string;
      }) => { init: (state: any) => void; send: (event: string, state: any) => void } | undefined;
    };
  }
}

export default (stores: Stores) => {
  if (import.meta.env.NODE_ENV === 'development') {
    const connection = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({ name: 'stores' });
    connection?.init(stores);

    Object.values(stores).forEach((store) => {
      store.subscribe((newState) => {
        connection?.send('State', newState);
      });
    });
  }
};
