import { Klaviyo } from '@innovationdepartment/proxima-ui';
import { KlaviyoIntegrationModalProps } from 'types/components/integrations';
import KlaviyoIntegrationModalMainView from './KlaviyoIntegrationModal.MainContent';
import KlaviyoIntegrationModalSideContent from './KlaviyoIntegrationModal.SideContent';
import IntegrationsModal from '../IntegrationsModal';

const KlaviyoIntegrationModalView: React.FC<KlaviyoIntegrationModalProps> = (props) => {
  const { status, onClose, onManageClick, open } = props;

  return (
    <IntegrationsModal
      icon={<Klaviyo />}
      title="Klaviyo"
      open={open}
      onClose={onClose}
      sideContent={
        <KlaviyoIntegrationModalSideContent onManageClick={onManageClick} status={status} />
      }
    >
      <KlaviyoIntegrationModalMainView />
    </IntegrationsModal>
  );
};

export default KlaviyoIntegrationModalView;
