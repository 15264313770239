import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { FACEBOOK_PRIVACY_POLICIES_URL, FACEBOOK_TERM_POLICIES_URL } from 'constants/urls';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import { FacebookIntegrationModalProps } from 'types/components/integrations';
import FacebookLogin from 'components/FacebookLogin';
import SideContentContainer from '../IntegrationsModal/LayoutComponents/SideContentContainer';
import SideContentTermsAndPrivacy from '../IntegrationsModal/LayoutComponents/SideContentTermsAndPrivacy';
import SideContentCategories from '../IntegrationsModal/LayoutComponents/SideContentCategories';
import IntegrationsModalStatus from '../IntegrationsModal/LayoutComponents/IntegrationsModalStatus';
import SideContentItemWrapper from '../IntegrationsModal/LayoutComponents/SideContentItemWrapper';
import styled from '@emotion/styled';

const CATEGORIES = ['Social', 'Marketing'];

const LINKS = {
  terms: FACEBOOK_TERM_POLICIES_URL,
  privacy: FACEBOOK_PRIVACY_POLICIES_URL,
};

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const ExtraContentWrapper = styled.div`
  width: 100%;
  & > :last-child {
    margin: 16px 0;
  }
`;

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FacebookIntegrationModalSideContent = (props: {
  loading: FacebookIntegrationModalProps['loading'];
  status: FacebookIntegrationModalProps['status'];
  adAccount: FacebookIntegrationModalProps['adAccount'];
  onConnectAdAccountClick: FacebookIntegrationModalProps['onConnectAdAccountClick'];
  onAcceptTermsClick: FacebookIntegrationModalProps['onAcceptTermsClick'];
  onDisconnectClick: FacebookIntegrationModalProps['onDisconnectClick'];
  brandId: FacebookIntegrationModalProps['brandId'];
}) => {
  const {
    brandId,
    loading,
    adAccount,
    status,
    onAcceptTermsClick,
    onConnectAdAccountClick,
    onDisconnectClick,
  } = props;
  const termsNotAccepted = status === BrandIntegrationStatus.TermsRequired;
  const isIncomplete = status === BrandIntegrationStatus.Incomplete;
  const isConnected = status === BrandIntegrationStatus.Connected;

  const swapTopConntent = isConnected || isIncomplete || termsNotAccepted;

  let topContent: React.ReactNode = (
    <ConnectWrapper>
      <IntegrationsModalStatus type={IntegrationType.Facebook} status={status} required />
      <FacebookLogin
        onSuccessRedirect={`/brand/${brandId}/integrations`}
        customButtonProps={{ label: 'Get started', submit: true }}
      />
    </ConnectWrapper>
  );

  const disconnectButton: React.ReactNode = swapTopConntent && (
    <Button
      size="small"
      variant="outlined"
      color="error"
      fullWidth
      disabled={loading}
      label={loading ? 'Disconnecting...' : 'Disconnect'}
      onClick={() => !loading && onDisconnectClick()}
    />
  );

  const connectAdAccountContent: React.ReactNode = (
    <ExtraContentWrapper>
      <Button
        fullWidth
        variant="outlined"
        onClick={onConnectAdAccountClick}
        label="Select Ad Account"
      />
    </ExtraContentWrapper>
  );

  const termsNotAcceptedContent: React.ReactNode = (
    <ExtraContentWrapper>
      <Button fullWidth variant="outlined" onClick={onAcceptTermsClick} label="Accept Terms" />
    </ExtraContentWrapper>
  );

  if (swapTopConntent) {
    topContent = (
      <SideContentItemWrapper flow="column" gap={16}>
        <StatusWrapper>
          {status && <IntegrationsModalStatus type={IntegrationType.Facebook} status={status} />}
          {termsNotAccepted && termsNotAcceptedContent}
          {isIncomplete && connectAdAccountContent}
        </StatusWrapper>
        <SideContentItemWrapper flow="column" gap={8}>
          <SideContentItemWrapper flow="column" gap={0}>
            <Text variant="body2" color="neutral100">
              Ad account
            </Text>
            <Text variant="body2" color="neutral60">
              {adAccount?.name}
            </Text>
          </SideContentItemWrapper>
          <SideContentItemWrapper flow="column" gap={0}>
            <Text variant="body2" color="neutral100">
              Ad account ID
            </Text>
            <Text variant="body2" color="neutral60">
              {adAccount?.accountId}
            </Text>
          </SideContentItemWrapper>
        </SideContentItemWrapper>
      </SideContentItemWrapper>
    );
  }

  return (
    <SideContentContainer>
      {topContent}
      <SideContentCategories categories={CATEGORIES} />
      <SideContentTermsAndPrivacy {...LINKS} />
      {disconnectButton}
    </SideContentContainer>
  );
};

export default FacebookIntegrationModalSideContent;
