import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

import { Header } from 'ui/Header';

const SectionWrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const SectionHeader = styled.div`
  padding: 0 32px 16px;
  border-bottom: 1px solid ${colors.neutral20};
  background-color: ${colors.neutral5};
  display: flex;
  flex-flow: column;
`;

const SectionContent = styled.div`
  // overflow: auto;
  flex-wrap: wrap;
  flex: 1;
  display: flex;
  padding: 32px;
  gap: 32px;
`;

type SectionLayoutProps = {
  title: string;
  headerContent?: React.ReactNode;
};

const SectionLayout: React.FC<SectionLayoutProps> = ({ children, headerContent, title }) => (
  <SectionWrapper>
    <SectionHeader>
      <Header title={title} />
      {headerContent}
    </SectionHeader>
    <SectionContent>{children}</SectionContent>
  </SectionWrapper>
);

export default SectionLayout;
