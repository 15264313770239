import {
  LookalikeAudienceTableRowProps,
  AudienceTableCellRendererProps,
  LookalikeAudienceStatus,
} from 'types/components/audiences';
import AudienceRowCellNameWrapper from './AudienceRowCell.NameWrapper';

const AudienceRowCellLookalikeAudience = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => (
  <AudienceRowCellNameWrapper
    rowName={row!.name as string}
    error={row?.displayStatus === LookalikeAudienceStatus.Error}
  />
);

export default AudienceRowCellLookalikeAudience;
