import type { PartialProfile } from '@innovationdepartment/proxima-sdk-axios';
import { Table } from '@innovationdepartment/proxima-ui';
import { AddOutlined } from '@mui/icons-material';
import { useBrandStore } from 'stores';
import LoggedInAsBrandHeader from 'ui/LoggedInAsBrandHeader';

import SettngsSectionLayout from 'ui/SettingsSectionLayout';

interface IUsersProps {
  addUserButtonClick: () => void;
  users: PartialProfile[];
  tableConfig: any;
}

const UsersView = ({ addUserButtonClick, users, tableConfig }: IUsersProps) => {
  const {
    brand: { name: brandName },
  } = useBrandStore();

  const titleRowButton = {
    label: 'Invite user',
    onClick: addUserButtonClick,
    icon: <AddOutlined />,
  };

  const titleAppendText = brandName ? <LoggedInAsBrandHeader /> : undefined;
  return (
    <SettngsSectionLayout title="Users" titleAppendText={titleAppendText} button={titleRowButton}>
      <Table data={users} config={tableConfig} />
    </SettngsSectionLayout>
  );
};

export default UsersView;
