import { useHandleApi } from 'hooks';
import {
  FlavorCategory,
  CreateSeedAudienceSchema,
  SeedAudience,
  CreateLookalikeAudienceSchema,
} from 'types/components/audiences';
import { CampaingInput } from 'types/components/audienceCampaign';
import { useParams } from 'react-router-dom';

const useAudiencesApi = () => {
  const { loading, handleApi } = useHandleApi();
  const { brandId } = useParams();

  const getSeedAudiences = async () => {
    const endpoint = `/brand/${brandId}/seed-audience`;
    return handleApi({ endpoint, brandId });
  };

  const createSeedAudiences = async (data: CreateSeedAudienceSchema) => {
    const endpoint = `/brand/${brandId}/seed-audience`;
    return handleApi({ endpoint, method: 'POST', data, brandId });
  };

  const retryLookalikeAudience = async ({
    seedAudienceId,
    lookalikeAudienceId,
  }: {
    seedAudienceId: string;
    lookalikeAudienceId: string;
  }) => {
    const endpoint = `/brand/${brandId}/seed-audience/${seedAudienceId}/lookalike-audience/${lookalikeAudienceId}/retry`;
    return handleApi({ endpoint, method: 'POST', brandId });
  };

  const getAvailableGalaxies = async () => {
    const endpoint = `/brand/${brandId}/galaxy/available`;
    return handleApi({ endpoint, brandId });
  };

  const getAvailableFlavorCategories = async () => {
    const endpoint = '/flavor-category';
    return handleApi({ endpoint });
  };

  const getCurrentLookalikeAudienceSizes = async (seedAudience: SeedAudience) => {
    const { id: seedAudienceId } = seedAudience;
    const endpoint = `/brand/${brandId}/seed-audience/${seedAudienceId}/lookalike-audience/size`;
    return handleApi({ endpoint, brandId });
  };

  const createLookalikeAudience = async (lookalikeData: CreateLookalikeAudienceSchema) => {
    const { seedAudienceId, ...data } = lookalikeData;
    const endpoint = `/brand/${brandId}/seed-audience/${seedAudienceId}/lookalike-audience`;
    return handleApi({ endpoint, method: 'POST', data, brandId });
  };

  const getBrandAudienceSettings = async () => {
    const endpoint = `/brand/${brandId}/audience-settings`;
    return handleApi({ endpoint, brandId });
  };

  const updateBrandAudienceSettings = async ({
    brandId: brandIdParam,
    flavorCategoryId,
  }: {
    brandId?: string;
    flavorCategoryId: FlavorCategory['id'];
  }) => {
    const brandIdToUse = brandIdParam || brandId;
    const endpoint = `/brand/${brandIdToUse}/audience-settings`;
    return handleApi({
      endpoint,
      brandId: brandIdToUse,
      method: 'PATCH',
      data: { flavorCategoryId },
    });
  };

  /* helper methods */
  const getBrandFlavorCategory = async () => {
    const [
      { data: { flavorCategoryId } = { flavorCategoryId: null } },
      { data: flavorCategories = [] },
    ] = await Promise.all([getBrandAudienceSettings(), getAvailableFlavorCategories()]);

    if (!flavorCategoryId || !flavorCategories) return null;
    const flavorCategory = (flavorCategories as FlavorCategory[]).find(
      (category) => category.id === flavorCategoryId
    );
    return flavorCategory;
  };

  const createCampaign = async (data: CampaingInput, audienceGroupId: string) => {
    const endpoint = `/brand/${brandId}/audience-group/${audienceGroupId}/campaign`;
    return handleApi({ endpoint, method: 'POST', data, brandId });
  };

  const getCampaignByAudienceGroupId = async (audienceGroupId: string) => {
    const endpoint = `/brand/${brandId}/audience-group/${audienceGroupId}/campaign`;
    const skipErrorToaster = true;
    return handleApi({ endpoint, brandId, skipErrorToaster });
  };

  return {
    loading,
    getSeedAudiences,
    createSeedAudiences,
    getAvailableGalaxies,
    getBrandAudienceSettings,
    updateBrandAudienceSettings,
    getAvailableFlavorCategories,
    getCurrentLookalikeAudienceSizes,
    createLookalikeAudience,
    retryLookalikeAudience,

    /* helper methods */
    getBrandFlavorCategory,
    createCampaign,
    getCampaignByAudienceGroupId,
  };
};

export default useAudiencesApi;
