import styled from '@emotion/styled';
import { Link, LinkIcon, Text, colors } from '@innovationdepartment/proxima-ui';
import { MANUAL_IMPORT_HELP_URL } from 'constants/urls';

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
`;

const FlexText = styled(Text)`
  display: flex;
  gap: 8px;
`;

const LinkText = styled.div`
  color: ${colors.blue70};
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  * > path {
    fill: ${colors.blue70};
  }
`;

const CustomerListUploadModalHeader = () => (
  <ContentHeader>
    <Text variant="h5">Securely upload your customer list</Text>
    <FlexText variant="body2" color="neutral70">
      Follow the instructions below to upload your customer list.
      <Link to={MANUAL_IMPORT_HELP_URL}>
        <LinkText>
          Learn more
          <LinkIcon />
        </LinkText>
      </Link>
    </FlexText>
  </ContentHeader>
);

export default CustomerListUploadModalHeader;
