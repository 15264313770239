import styled from '@emotion/styled';
import { Banner, Button, Text, colors } from '@innovationdepartment/proxima-ui';

type TrialEndedBannerViewProps = {
  onOpenManageSubscriptionModal: () => void;
};

const BannerNoIconWrapper = styled.div`
  & svg {
    display: none;
  }
  & div {
    background-color: ${colors.red20};
    border: none;
    border-radius: unset;
  }
`;

const BannerText = styled(Text)`
  margin-right: 16px;
`;

const InnerBannerWrapper = styled.div`
  margin: 0 auto;
`;

const TrialEndedBannerView = ({ onOpenManageSubscriptionModal }: TrialEndedBannerViewProps) => (
  <BannerNoIconWrapper>
    <Banner type="error" title="" body="">
      <InnerBannerWrapper>
        <BannerText variant="body2Semibold">Please select a plan to continue.</BannerText>
        <Button
          onClick={onOpenManageSubscriptionModal}
          size="small"
          data-testid="trial-ended-banner"
          variant="contained"
          label="Choose plan"
        />
      </InnerBannerWrapper>
    </Banner>
  </BannerNoIconWrapper>
);

export default TrialEndedBannerView;
