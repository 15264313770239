import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { SideBarMenu } from '@innovationdepartment/proxima-ui';

const Layout = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;

  & > div:first-child {
    height: 100%;
  }
`;

interface ISettingsProps {
  onNavigate: (pathname: string) => void;
  menuItems: { label: string; pathname: string }[];
  pathname: string;
}

const SettingsView = ({ menuItems, pathname, onNavigate }: ISettingsProps) => (
  <Layout>
    <SideBarMenu
      onClick={onNavigate}
      title="Brand settings"
      menuItems={menuItems}
      pathname={pathname}
    />
    <Outlet />
  </Layout>
);

export default SettingsView;
