import {
  LomiLogo,
  FinnLogo,
  HintLogo,
  ObviLogo,
  ActAcreLogo,
  CarawayLogo,
  JoeTheJuiceLogo,
  MindBodyGreenLogo,
} from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';

const LogosWrapper = styled.section`
  width: 100%;
  max-width: 1344px;
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
`;

const OnboardingBrands = () => (
  <LogosWrapper role="contentinfo">
    <CarawayLogo />
    <ActAcreLogo />
    <HintLogo />
    <MindBodyGreenLogo />
    <LomiLogo />
    <ObviLogo />
    <JoeTheJuiceLogo />
    <FinnLogo />
  </LogosWrapper>
);

export default OnboardingBrands;
