import {
  ActionButton,
  AddCircle,
  Button,
  Divider,
  Reload,
  SpaceDivider,
} from '@innovationdepartment/proxima-ui';
import { ChooseBrandForShopifyIntegrationProps } from 'types/components/integrations';
import ButtonGroup from 'ui/ButtonGroup';
import LoadingSpinner from 'ui/LoadingSpinner';

const ChooseBrandForShopifyFooter = (props: ChooseBrandForShopifyIntegrationProps) => {
  const { loading, selectedBrandId, onRefreshBrandsClick, onCreateNewBrandClick, onContinueClick } =
    props;

  return (
    <>
      <SpaceDivider y={1} />
      <ButtonGroup>
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={onRefreshBrandsClick}
          label="Refresh"
          startIcon={<Reload />}
        />
        <ActionButton
          icon={<AddCircle />}
          label="Create New Brand"
          onClick={onCreateNewBrandClick}
        />
        <span style={{ flex: 1 }} />
      </ButtonGroup>
      <SpaceDivider y={4} />
      <Divider />
      <SpaceDivider y={2} />
      <ButtonGroup>
        <Button
          startIcon={loading ? <LoadingSpinner /> : undefined}
          size="small"
          disabled={loading || !selectedBrandId}
          label={loading ? 'Connecting' : 'Connect'}
          onClick={onContinueClick}
        />
      </ButtonGroup>
    </>
  );
};

export default ChooseBrandForShopifyFooter;
