import { DEFAULT_FRONTEND_TIMEZONE } from 'constants/defaults';
import * as dateFnsTz from 'date-fns-tz';

const dateFormatter = (date: Date) =>
  dateFnsTz.format(date, 'MMM dd, yyyy', { timeZone: DEFAULT_FRONTEND_TIMEZONE });
const timeFormatter = (date: Date) =>
  dateFnsTz.format(date, 'h:mm a', { timeZone: DEFAULT_FRONTEND_TIMEZONE });

const formatDate = (date: string | Date, includeTime = false) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  if (includeTime) return `${dateFormatter(newDate)} at ${timeFormatter(newDate)}`;

  return dateFormatter(newDate);
};

const formatDateFromIso = (date: string) => formatDate(new Date(date));

export { formatDate, formatDateFromIso };
