import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import OnboardingHeader from './Onboarding.Header';

const OnboardingMainSectionWrapper = styled.main`
  max-width: 1344px;
  background-color: ${colors.white};
  border: 2px solid ${colors.neutral20};
  padding: 48px;
  border-radius: 12px;
  width: 100%;
`;

const OnboardingIntegrationsWrapper = styled.section`
  margin: 24px 0 0;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 calc(50% - 1rem);

    /* fixes last odd element flex width */
    &:nth-child(odd):last-child {
      flex: 0.5;
      max-width: calc(49%);
    }
  }
`;

const OnboardingMainSection: React.FC = (props) => {
  const { children } = props;

  return (
    <OnboardingMainSectionWrapper>
      <OnboardingHeader />
      <OnboardingIntegrationsWrapper>{children}</OnboardingIntegrationsWrapper>
    </OnboardingMainSectionWrapper>
  );
};

export default OnboardingMainSection;
