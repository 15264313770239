import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

const Layout = styled.div`
  background-color: ${colors.legacy.white};
  min-height: 100vh;
  padding: 50px;
  & > h2 {
    text-align: center;
  }
`;

export default Layout;
