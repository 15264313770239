import styled from '@emotion/styled';
import pluralize from 'pluralize';
import { Button, Text, colors } from '@innovationdepartment/proxima-ui';

type TrialEndedPillViewProps = {
  daysRemaining: number;
  onOpenManageSubscriptionModal: () => void;
};

const GradientButton = styled(Button)`
  height: 28px;
  width: 65px;
`;

const Pill = styled.div`
  background-color: ${colors.neutral80};
  border-radius: 8px;
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 255px;
`;

const CenteredText = styled(Text)`
  line-height: 28px;
`;

const TrialEndedPillView = ({
  daysRemaining,
  onOpenManageSubscriptionModal,
}: TrialEndedPillViewProps) => (
  <Pill>
    <CenteredText variant="body2Semibold" color="white">
      {`Trial ends in ${pluralize('day', daysRemaining, true)}`}
    </CenteredText>
    <GradientButton
      label="Upgrade"
      overrideColors={{
        backgroundColor: 'linear-gradient(to bottom right, #1ce8ab, #5091f3)',
        textColor: 'neutral100',
      }}
      onClick={onOpenManageSubscriptionModal}
    />
  </Pill>
);

export default TrialEndedPillView;
