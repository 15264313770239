import styled from '@emotion/styled';
import { FormField, Text, colors, Refresh } from '@innovationdepartment/proxima-ui';
import LoadingSpinner from 'ui/LoadingSpinner';
import { AdPixelViewProps } from 'types/components/audienceCampaign';

const ContentWrapper = styled.div`
  width: 778px;
  flex-wrap: wrap;
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  width: 778px;
  margin-bottom: 12px;
`;

const ContentSection = styled.div`
  width: 826px;
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  padding: 24px;
`;

const TextWrapper = styled.div`
  width: 381px;
  flex: 50%;
`;

const RefreshSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const RefreshWrapperIcon = styled.div`
  flex: 80%;
  text-align: right;
  padding-top: 4px;
`;

const RefreshWrapper = styled.div`
  text-align: left;
`;

const AdPixelView = ({ loading, options, onRefreshClick }: AdPixelViewProps) => (
  <ContentSection>
    <ContentWrapper>
      <HeaderContentWrapper>
        <Text variant="body1Semibold" color="neutral100">
          Pixel
        </Text>
      </HeaderContentWrapper>
      <TextWrapper>
        <Text variant="body2" color="neutral70">
          Choose the pixel you want to use to track conversions. Only pixel IDs associated with this
          ad account are shown here.
        </Text>
      </TextWrapper>
      <TextWrapper>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <FormField name="adPixel" type="select" fullWidth options={options} />
        )}
      </TextWrapper>
      <TextWrapper />
      <TextWrapper>
        <RefreshSection>
          <RefreshWrapperIcon>
            <Refresh />
            &nbsp;
          </RefreshWrapperIcon>
          <RefreshWrapper>
            <div
              onClick={onRefreshClick}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
            >
              <Text variant="table2Link" color="blue70">
                Refresh
              </Text>
            </div>
          </RefreshWrapper>
        </RefreshSection>
      </TextWrapper>
    </ContentWrapper>
  </ContentSection>
);

export default AdPixelView;
