import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { NavbarMenu, Tooltip, Text } from '@innovationdepartment/proxima-ui';
import React from 'react';

type NavbarItemProps = NonNullable<PropsOf<typeof NavbarMenu>['items']>[number] & {
  noTooltip?: true;
};

const NavbarMenuItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  & > :not(:last-child) {
    min-width: 32px;
  }
`;

const ActiveText = styled.span`
  font-weight: 700;
`;

const cssRules = `
  align-self: stretch;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 1;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
`;

export default function renderNavbarItem(item: NavbarItemProps) {
  const { pathname } = useLocation();
  const TextWrapper = item.active ? ActiveText : React.Fragment;

  let LinkWrapper;
  let TooltipWrapper = Tooltip;

  if (item.noTooltip) TooltipWrapper = React.Fragment;

  if (item.value === 'spinner') {
    LinkWrapper = React.Fragment;
  } else {
    LinkWrapper = item.disableHover
      ? styled.div`
          ${cssRules}
        `
      : styled(Link)`
          ${cssRules}
        `;
  }

  let to: string = item.value;

  if (!item.value.startsWith('/')) {
    /* example of logic */
    const path = pathname
      /* /brand/{brandId}/settings/general */
      .split('/')
      /* ['', 'brand', '{brandId}', 'settings', 'general'] */
      .filter(Boolean)
      /* ['brand', '{brandId}', 'settings', 'general'] */
      .slice(2)
      /* ['settings', 'general'] */
      .join('/');
    /* settings/general */

    to = `/brand/${item.value}/${path}`;
  }

  return (
    <LinkWrapper to={to}>
      <TooltipWrapper title={item.label} placement="top-end">
        <NavbarMenuItem>
          {item.icon}
          <Text ellipsis color="neutral80" variant="body2">
            <TextWrapper>{item.label}</TextWrapper>
          </Text>
        </NavbarMenuItem>
      </TooltipWrapper>
    </LinkWrapper>
  );
}
