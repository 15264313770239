import styled from '@emotion/styled';
import { AudiencesDone, IntegrationsProcessing, Text } from '@innovationdepartment/proxima-ui';
import { ModalStep } from 'types/components/audiences';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const GeneratedAudiencesStatusHeader = ({
  isProcessing,
  stepType,
}: {
  isProcessing: boolean;
  stepType: ModalStep;
}) => {
  const setHeaderText = () => {
    if (stepType === ModalStep.Audience && isProcessing) {
      return 'Sending audiences to Meta...';
    }
    if (stepType === ModalStep.Campaign && isProcessing) {
      return 'Sending to Meta...';
    }
    if ((stepType === ModalStep.Audience || stepType === ModalStep.Campaign) && !isProcessing) {
      return 'You’re all set!';
    }

    return '';
  };

  const setSubHeaderText = () => {
    if (stepType === ModalStep.Audience && isProcessing) {
      return 'We’ll email you when they’re ready';
    }
    if (stepType === ModalStep.Audience && !isProcessing) {
      return 'Find Proxima audiences in your Meta account.';
    }
    if (stepType === ModalStep.Campaign && isProcessing) {
      return 'We’ll email you when the campaign is ready';
    }
    if (stepType === ModalStep.Campaign && !isProcessing) {
      return 'Find your Proxima campaign in your Meta account.';
    }
    return '';
  };

  return (
    <>
      <HeaderWrapper>
        <Text variant="h6">{setHeaderText()}</Text>
        <Text variant="body2">{setSubHeaderText()}</Text>
      </HeaderWrapper>
      {isProcessing ? <IntegrationsProcessing /> : <AudiencesDone />}
    </>
  );
};
export default GeneratedAudiencesStatusHeader;
