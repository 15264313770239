import { useFormState } from 'react-final-form';
import { GenerateAudiencesForm, GenerateNewAudiencesViewProps } from 'types/components/audiences';
import { Button } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import LoadingSpinner from 'ui/LoadingSpinner';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import AudiencesMoreInfo from '../Layout/AudiencesMoreInfo';
import AdTargetingEnhancement from '../Layout/AdTargetingEnhancement';
import AdPlatformSelection from '../Layout/AdPlatformSelection';
import LookalikeSources from '../Layout/LookalikeSources';
import LookalikeSize from '../Layout/LookalikeSize';

const ModalSection = styled.div<{ flex?: number }>`
  gap: 16px;
  display: flex;
  flex-flow: column;
  flex-basis: ${({ flex = 1 }) => flex * 350}px; /* bad math */
  flex-grow: ${({ flex = 1 }) => flex};
  flex-shrink: 0;
`;

const GenerateNewAudiencesView = (props: GenerateNewAudiencesViewProps) => {
  const { loading } = props;
  const formState = useFormState<GenerateAudiencesForm>();

  const { valid } = formState;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <Button
          submit
          disabled={!valid || loading}
          startIcon={loading && <LoadingSpinner />}
          label="Generate and continue"
        />
      }
    >
      <ModalSection flex={2}>
        <AudiencesMoreInfo />
        <AdTargetingEnhancement />
        <AdPlatformSelection />
        <LookalikeSize loading={false} sizes={[]} disabled={loading} />
      </ModalSection>
      <ModalSection>
        <LookalikeSources />
      </ModalSection>
    </ModalBaseChildrenWrapper>
  );
};

export default GenerateNewAudiencesView;
