import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import { Button } from '@innovationdepartment/proxima-ui';
import { CreateNewLookalikeModalViewProps } from 'types/components/audiences';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import LookalikeSize from 'ui/Modals/CreateNewAudiencesModal/Layout/LookalikeSize';
import AdPlatformSelection from 'ui/Modals/CreateNewAudiencesModal/Layout/AdPlatformSelection';
import { LookalikeSizePreview, LookalikeSizeSubmitting } from './Layout';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 640px;
`;

const CreateNewLookalikeModalView = (props: CreateNewLookalikeModalViewProps) => {
  const { seedAudience, loading, isSubmitting, sizes, onClose } = props;
  const formState = useFormState();

  const { valid } = formState;
  const { integrationType } = formState.values;

  const sizesForPlatform =
    sizes.find((size) => size.integrationType === integrationType)?.sizes ?? [];

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        isSubmitting ? (
          <Button label="Close" variant="outlined" onClick={onClose} />
        ) : (
          <Button submit disabled={!valid} label="Generate & send to platform" />
        )
      }
    >
      <ContentWrapper>
        {isSubmitting ? (
          <LookalikeSizeSubmitting />
        ) : (
          <>
            <AdPlatformSelection />
            <LookalikeSize loading={loading} sizes={sizesForPlatform} />
          </>
        )}
        <LookalikeSizePreview seedAudience={seedAudience} />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default CreateNewLookalikeModalView;
