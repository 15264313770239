import { IntegrationType } from 'constants/integrations';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntegrationsStore } from 'stores';
import { Integration, IntegrationStatus } from 'types/stores/integration-store';
import { useAdManager, useProximaSDK } from 'hooks';

const useIntegrations = () => {
  const { brandId: paramBrandId } = useParams();
  const { showToaster } = useToaster();
  const integrationStore = useIntegrationsStore();
  const { loading: adManagerLoading, getAdAccount } = useAdManager();
  const navigate = useNavigate();
  const integrationsApi = useProximaSDK('IntegrationsApi');

  const initializeIntegrations = async () => {
    if (!paramBrandId) {
      navigate('/');
      return;
    }

    // Ensure we start off with a cleared out integration store
    integrationStore.clear();

    // Sync integration store with api
    const [result] = await Promise.all([
      integrationsApi.getIntegrationsHistoryForBrand({ brandId: paramBrandId }),
      getAdAccount({ refresh: true }),
    ]);
    const integrations = result.data ?? [];

    // If we have integrations set them in the store
    integrationStore.addIntegration(integrations as Integration[]);
  };

  const deleteIntegration = async (integrationId: string) => {
    if (!paramBrandId) return;

    const result = await integrationsApi.deleteIntegration({
      brandId: paramBrandId,
      integrationId,
    });

    if (result.status !== 204) {
      showToaster({ message: 'Unable to remove integration', variant: 'error' });
      return;
    }

    integrationStore.deleteIntegration(integrationId);
  };

  const getAllIntegrationsByType = (type?: IntegrationType, active: boolean = false) =>
    integrationStore.integrations
      .filter(
        (integration) =>
          (type ? integration.type === type : true) &&
          (active ? integration.status === IntegrationStatus.Active : true)
      )
      /** sort them by last active */
      .sort((a, b) => new Date(b.lastUpdateAt).getTime() - new Date(a.lastUpdateAt).getTime());

  const getActiveIntegrations = (type?: IntegrationType) => getAllIntegrationsByType(type, true);

  const hasActiveIntegrations = (type?: IntegrationType) => getActiveIntegrations(type).length > 0;

  return {
    loading: integrationsApi.loading || adManagerLoading,
    hasActiveIntegrations,
    getActiveIntegrations,
    initializeIntegrations,
    getAllIntegrationsByType,
    deleteIntegration,
  };
};

export default useIntegrations;
