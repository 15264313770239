export enum BrandStatus {
  Active = 'active',
  Deleted = 'deleted',
  Locked = 'locked',
}

export type Brand = {
  brandId: string;
  name: string;
  domain: string;
  status: string; // TODO: change to BrandStatus
};

export type BrandStore = {
  brand: Brand;
  clearBrandStore: () => void;
  updateBrandStore: (brand: Brand) => void;
  brandInitialized: boolean;
  hasBrandStoreInitialized: () => boolean;
};
