import styled from '@emotion/styled';
import { RocketLaunch, SpaceDivider, Text, Button } from '@innovationdepartment/proxima-ui';

type AllSetViewProps = {
  onClick: () => void;
  isAuthenticated: boolean;
};

const Container = styled.div`
  text-align: center;
  margin-top: 125px;
`;

const IconWrapper = styled.span`
  & > svg {
    overflow: hidden;
    border-radius: 50%;
  }
`;

const Dot = styled.span<{ d?: number }>`
  position: relative;

  ${({ d = 1 }) => `
  animation: l5 1s infinite linear alternate;
  animation-delay: ${d}s;
  `}

  &::before {
    content: '•';
    position: relative;
  }

  @keyframes l5 {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 1;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const AllSetView = ({ isAuthenticated, onClick }: AllSetViewProps) => {
  const topTitle = isAuthenticated ? 'All set!' : 'Hang tight!';
  const bottomContent = isAuthenticated ? (
    <Button label="Close" onClick={onClick} />
  ) : (
    <Text variant="body1">
      Redirecting you to Proxima
      <Dot d={0.3} />
      <Dot d={0.67} />
      <Dot d={1} />
    </Text>
  );

  return (
    <Container>
      <IconWrapper>
        <RocketLaunch />
      </IconWrapper>
      <SpaceDivider y={5} />
      <Text variant="h4">{topTitle}</Text>
      <SpaceDivider y={4} />
      {bottomContent}
    </Container>
  );
};

export default AllSetView;
