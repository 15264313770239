import styled from '@emotion/styled';

const ButtonGroup = styled.div<{ spaceBetween?: boolean; hPadding?: number }>`
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-end')};
  gap: 16px;

  ${({ hPadding }) => {
    if (hPadding) {
      return `
        & > button {
          padding: 8px ${hPadding}px;
        }
      `;
    }

    return ``;
  }}
`;

export default ButtonGroup;
