import { useHandleApi } from 'hooks';
import { CreateBillingContactSchema } from 'types/billing';

const useBillingApi = () => {
  const { loading, handleApi } = useHandleApi();

  const getBillingContacts = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/billing/contact` });

  const createBillingContact = async (contact: CreateBillingContactSchema, brandId: string) =>
    handleApi({
      endpoint: `/brand/${brandId}/billing/contact`,
      method: 'POST',
      data: contact,
    });

  const deleteBillingContact = async ({
    contactId,
    brandId,
  }: {
    contactId: string;
    brandId: string;
  }) =>
    handleApi({
      endpoint: `/brand/${brandId}/billing/contact/${contactId}`,
      method: 'DELETE',
    });

  const updateSubscription = async (brandId: string, subscriptionType: string) =>
    handleApi({
      endpoint: `/brand/${brandId}/billing/subscription`,
      method: 'PUT',
      data: { subscriptionType },
      brandId,
    });

  const getBrandSubscription = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/billing/subscription`, brandId });

  const getStripeCustomerPortalUrl = async (brandId: string) =>
    handleApi({ endpoint: `/brand/${brandId}/billing/stripe/customer-portal`, brandId });
  return {
    getBillingContacts,
    createBillingContact,
    deleteBillingContact,
    getBrandSubscription,
    getStripeCustomerPortalUrl,
    loading,
    updateSubscription,
  };
};

export default useBillingApi;
