import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { IntegrationsModalBaseProps } from './types';

const MainContentWrapper = styled.div<{ full: boolean }>`
  padding: 32px;
  max-width: ${({ full }) => (full ? '100%' : '668px')};
  overflow: auto;
`;

const SideContentWrapper = styled.div`
  border-left: 1px solid ${colors.neutral20};
  padding: 32px 16px;
`;

const IntegrationsModalView: React.FC<IntegrationsModalBaseProps> = (props) => {
  const { children, title, icon, open, onClose, sideContent, full = false } = props;

  let headerTitle: React.ReactNode = (
    <>
      <Text color="neutral60" variant="overline1">
        INTEGRATE
      </Text>
      <Text color="neutral100" variant="h6">
        {title}
      </Text>
    </>
  );

  if (typeof title !== 'string') headerTitle = title;

  return (
    <ModalBase
      disableTransition={full}
      header={{ padding: 'normal' }}
      full={full}
      open={open}
      title={headerTitle}
      icon={icon}
      onClose={onClose}
    >
      <MainContentWrapper full={full}>{children}</MainContentWrapper>
      {sideContent && <SideContentWrapper>{sideContent}</SideContentWrapper>}
    </ModalBase>
  );
};

export default IntegrationsModalView;
