import { IntegrationType } from 'constants/integrations';
import styled from '@emotion/styled';
import { PropsOf } from '@emotion/react';
import { Banner, Button } from '@innovationdepartment/proxima-ui';

// TODO(Jenky): replace this once we accept more than one integration
type Types = IntegrationType.Facebook;

const contentDefaults: {
  [key in Types]: { banner: PropsOf<typeof Banner>; button: PropsOf<typeof Button> };
} = {
  facebook: {
    banner: {
      title: 'We lost connection to your Meta ad account',
      body: 'Please reconnect your ad account.',
      type: 'error',
    },
    button: {
      label: 'Reconnect Meta',
      variant: 'outlined',
      size: 'small',
      onClick: () => {},
    },
  },
};

type IntegrationDisconnectionWarningBannerProps = {
  type: Types;
  footer: boolean;
  onClick: () => void;
};

const ActionWrapper = styled.div`
  margin-left: 32px;
`;

const IntegrationDisconnectionWarningBannerView = (
  props: IntegrationDisconnectionWarningBannerProps
) => {
  const { type, footer, onClick } = props;
  const { button, banner } = contentDefaults[type];

  return (
    <Banner {...banner} footer={footer}>
      <ActionWrapper>
        <Button {...button} onClick={onClick} submit={false} />
      </ActionWrapper>
    </Banner>
  );
};

export default IntegrationDisconnectionWarningBannerView;
