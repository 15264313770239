import styled from '@emotion/styled';
import { Modal, Text } from '@innovationdepartment/proxima-ui';
import { HELLO_EMAIL } from 'constants/emails';

const TextWrapper = styled.div`
  margin: 24px 0 56px;
  max-width: 382px;
`;

type FreeTrialModalProps = {
  show: boolean;
  onClose: () => void;
};

const mailTo = `mailto:${HELLO_EMAIL}`;

const FreeTrialModal = (props: FreeTrialModalProps) => {
  const { onClose, show } = props;

  return (
    <Modal
      noPadding={false}
      open={show}
      title="Your account is locked"
      onClose={onClose}
      allowCloseOnClickOutside
    >
      <TextWrapper>
        <Text variant="body1">
          Please contact our team to continue using Proxima. Email us at{' '}
          <a href={mailTo}>{HELLO_EMAIL}</a>
        </Text>
      </TextWrapper>
    </Modal>
  );
};

export default FreeTrialModal;
