import { Navigate, Route, Routes } from 'react-router-dom';

import Audiences from './Audiences.Container';
import AcceptFacebookCustomTermsOfService from './AcceptFacebookCustomTermsOfService';

const AudiencesRouter = () => (
  <Routes>
    <Route path="">
      <Route index element={<Audiences />} />
      <Route path="custom-tos" element={<AcceptFacebookCustomTermsOfService />} />
      <Route path="*" element={<Navigate to="" />} />
    </Route>
  </Routes>
);

export default AudiencesRouter;
