import styled from '@emotion/styled';
import {
  SpaceDivider,
  Text,
  Button,
  Reload,
  SwitchUser,
  colors,
} from '@innovationdepartment/proxima-ui';
import FacebookLogin from 'components/FacebookLogin';
import { useQuery } from 'hooks';
import { AdAccount } from 'types/stores/ad-account-store';
import LoadingSpinner from 'ui/LoadingSpinner';
import ProximaLink from 'ui/ProximaLink';

type AdAccountSelectionFooterProps = {
  brandId: string;
  isLoading: boolean;
  isSaving: boolean;
  selectedAdAccount: AdAccount | undefined;
  redirectTo: string;
  saveAdAccount: () => void;
  refreshAdAccounts: () => void;
};

const ButtonGroup = styled.div<{ flex?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  ${({ flex }) => (flex ? 'flex: 1;' : '')}
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.neutral80};
`;

const AdSetupFooter = ({
  brandId,
  isLoading,
  isSaving,
  selectedAdAccount,
  redirectTo,
  saveAdAccount,
  refreshAdAccounts,
}: AdAccountSelectionFooterProps) => {
  const disableButtons = isLoading || isSaving;
  const { getQueryParams } = useQuery();
  const { redirect } = getQueryParams<'redirect'>();

  return (
    <>
      <SpaceDivider y={1} />
      <ButtonGroup>
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={refreshAdAccounts}
          label="Refresh"
          startIcon={<Reload />}
          disabled={disableButtons}
        />
        <FacebookLogin
          onSuccessRedirect={redirect ?? `/brand/${brandId}/integrations`}
          brandId={brandId}
          customButtonProps={{
            disabled: disableButtons,
            submit: true,
            size: 'small',
            variant: 'text',
            label: 'Switch User',
            color: 'secondary',
            startIcon: <SwitchUser />,
          }}
        />
      </ButtonGroup>
      <SpaceDivider y={4} />
      <Divider />
      <SpaceDivider y={2} />
      <ButtonGroup>
        <ButtonGroup flex>
          <Text variant="body2">Not sure which ad account to use?</Text>
          <ProximaLink to={redirectTo}>Skip for now</ProximaLink>
        </ButtonGroup>
        <Button
          startIcon={isSaving ? <LoadingSpinner /> : undefined}
          size="small"
          disabled={disableButtons || !selectedAdAccount}
          label={isSaving ? 'Saving' : 'Connect'}
          onClick={saveAdAccount}
        />
      </ButtonGroup>
    </>
  );
};

export default AdSetupFooter;
