import React from 'react';
import ApiErrorBoundary from './ApiErrorBoundary';
import RenderErrorBoundary from './RenderErrorBoundary';

const ErrorBoundaries: React.FC = ({ children }) => {
  // order on the components matters!
  const errorBoundaryWrappers: React.FC[] = [RenderErrorBoundary, ApiErrorBoundary];

  let errorBoundary: React.ReactNode = children;

  errorBoundaryWrappers.forEach((ErrorWrapper) => {
    errorBoundary = <ErrorWrapper>{errorBoundary}</ErrorWrapper>;
  });

  return errorBoundary as React.ReactElement;
};

export default ErrorBoundaries;
