import styled from '@emotion/styled';
import ContentSection from 'ui/ContentSection';
import { AdEnhancementViewProps } from 'types/components/audiences';
import AdTargetingEnhancementHeader from './AdTargetingEnhancement.Header';
import AdTargetingEnhancementShopify from './AdTargetingEnhancement.Shopify';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const AdTargetingEnhancementViewView = (props: AdEnhancementViewProps) => (
  <ContentSection>
    <ContentWrapper>
      <AdTargetingEnhancementHeader />
      <AdTargetingEnhancementShopify {...props} />
    </ContentWrapper>
  </ContentSection>
);

export default AdTargetingEnhancementViewView;
