import { Text, colors } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';

const ErrorDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.red50};
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AudienceRowCellNameWrapper = ({ rowName, error }: { rowName: string; error?: boolean }) => (
  <NameWrapper>
    {error && <ErrorDot />}
    <Text variant="table1Link" color="neutral100">
      {rowName}
    </Text>
  </NameWrapper>
);

export default AudienceRowCellNameWrapper;
