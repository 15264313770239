import styled from '@emotion/styled';
import { colors, Text, ClockIcon } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${colors.neutral0};
`;

const TopTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ComingSoon = () => (
  <Container>
    <TopTitle>
      <ClockIcon />
      <Text variant="body2Semibold">
        <strong>Coming soon</strong>
      </Text>
    </TopTitle>
    <Text variant="body1">
      We’ll be releasing features built for agencies in the near future. In the meantime, you can
      setup as many brand accounts as you need for your clients.
    </Text>
  </Container>
);

export default ComingSoon;
