import styled from '@emotion/styled';
import { FormField, Text } from '@innovationdepartment/proxima-ui';
import ContentSection from 'ui/ContentSection';
import LoadingSpinner from 'ui/LoadingSpinner';
import { LookalikeSizesViewProps } from 'types/components/audiences';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const SelectWrapper = styled.div`
  max-width: 258px;
`;

const LookalikeSizeView = ({ disabled, loading, options }: LookalikeSizesViewProps) => (
  <ContentSection>
    <ContentWrapper>
      <Text variant="body1Semibold" color="neutral100">
        Lookalike size
      </Text>
      <SelectWrapper>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <FormField
            // TODO(Jenky): fix this issue with the UI library
            disabled={disabled && false}
            name="lookalikeSize"
            type="select"
            fullWidth
            options={options}
            placeholder="Select a lookalike size"
          />
        )}
      </SelectWrapper>
    </ContentWrapper>
  </ContentSection>
);

export default LookalikeSizeView;
