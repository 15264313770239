import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import { useUserStore, useBrandStore } from 'stores';
import { useAccessToken } from 'hooks';
import { Header } from 'ui/Header';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px;
`;

const ContentWrapper = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: 500px 500px;
  grid-column-gap: 50px;
`;

const ContentGrid = styled.div`
  height: auto;
  display: grid;
  place-items: center;
  grid-row-gap: 10px;
  padding-top: 20px;
  margin-top: 30px;
`;

const TextRow = styled.div`
  width: 100%;
  display: grid;
  justify-content: start;
  grid-template-columns: 150px auto;
  grid-column-gap: 8px;
`;

const CodeGroup = styled.div`
  padding-top: 20px;
`;

const JsonBlock = styled.div`
  width: 100%;
  background-color: ${colors.legacy.mistDarker};
  white-space: pre;
  font-size: 12px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DataRow = ({ label, value }: { label: string; value: string }) => (
  <TextRow>
    <Text variant="body1" color="tealDarker">
      {label}
    </Text>
    <Text variant="body1" color="rustBase">
      {value}
    </Text>
  </TextRow>
);

const Debug = () => {
  const { user: auth0User } = useAuth0();
  const { user } = useUserStore();
  const { brand } = useBrandStore();
  const { getAccessToken } = useAccessToken();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessToken();
      setAccessToken(token.accessToken);
    };

    getToken();
  }, []);

  return (
    <Container>
      <Header title="Debugging dashboard" />

      <ContentWrapper>
        <div>
          <ContentGrid>
            {user?.firstName && (
              <DataRow label="User Name:" value={`${user.firstName} ${user.lastName}`} />
            )}
            {user?.email && <DataRow label="Email:" value={user.email} />}
            {brand?.name && <DataRow label="Brand Name:" value={brand.name} />}
            {brand?.brandId && <DataRow label="Brand ID:" value={brand.brandId} />}
          </ContentGrid>

          <CodeGroup>
            <Text variant="body1" color="tealDarker">
              ID Token:
            </Text>
            <JsonBlock>{JSON.stringify(auth0User, null, 2)}</JsonBlock>
          </CodeGroup>

          <CodeGroup>
            <Text variant="body1" color="tealDarker">
              Access Token:
            </Text>
            <JsonBlock>{accessToken && JSON.stringify(jwtDecode(accessToken), null, 2)}</JsonBlock>
          </CodeGroup>
        </div>
      </ContentWrapper>
    </Container>
  );
};

export default Debug;
