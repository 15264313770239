import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const location = useLocation();

  const getQueryParams = <T,>() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params as { [key in T extends string ? T : string]: string };
  };

  return {
    getQueryParams,
  };
}
