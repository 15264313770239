import styled from '@emotion/styled';
import { SHOPIFY_PRIVACY_POLICIES_URL, SHOPIFY_TERM_POLICIES_URL } from 'constants/urls';
import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import { ShopifyIntegrationModalProps } from 'types/components/integrations';
import IntegrationsModalStatus from '../IntegrationsModal/LayoutComponents/IntegrationsModalStatus';
import SideContentItemWrapper from '../IntegrationsModal/LayoutComponents/SideContentItemWrapper';
import SideContentTermsAndPrivacy from '../IntegrationsModal/LayoutComponents/SideContentTermsAndPrivacy';
import SideContentCategories from '../IntegrationsModal/LayoutComponents/SideContentCategories';
import SideContentContainer from '../IntegrationsModal/LayoutComponents/SideContentContainer';

const CATEGORIES = ['Ecommerce', 'Data & Enrichment'];

const LINKS = {
  terms: SHOPIFY_TERM_POLICIES_URL,
  privacy: SHOPIFY_PRIVACY_POLICIES_URL,
};

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ShopifyIntegrationModalSideContent = (props: {
  status: BrandIntegrationStatus;
  loading: ShopifyIntegrationModalProps['loading'];
  storeName?: ShopifyIntegrationModalProps['storeName'];
  onGetStartedClick: ShopifyIntegrationModalProps['onGetStartedClick'];
  onDisconnectClick: ShopifyIntegrationModalProps['onDisconnectClick'];
}) => {
  const { loading, status, storeName, onGetStartedClick, onDisconnectClick } = props;
  const isConnected = status === BrandIntegrationStatus.Connected;
  let storeInfo: React.ReactNode = null;

  if (isConnected && storeName) {
    storeInfo = (
      <SideContentItemWrapper flow="column" gap={0}>
        <Text variant="body2" color="slateDarker">
          Store name
        </Text>
        <Text variant="body2" color="slateLight">
          {storeName}
        </Text>
      </SideContentItemWrapper>
    );
  }

  const topContent = (
    <SideContentItemWrapper flow="column" gap={16}>
      <IntegrationsModalStatus type={IntegrationType.Shopify} status={status} />
      {storeInfo}
    </SideContentItemWrapper>
  );

  return (
    <SideContentContainer>
      <ConnectWrapper>
        {topContent}
        {!isConnected && onGetStartedClick && (
          <Button size="small" fullWidth label="Get started" onClick={onGetStartedClick} />
        )}
      </ConnectWrapper>
      <SideContentCategories categories={CATEGORIES} />
      <SideContentTermsAndPrivacy {...LINKS} />
      {isConnected && onDisconnectClick && (
        <Button
          size="small"
          variant="outlined"
          color="error"
          fullWidth
          disabled={loading}
          label={loading ? 'Disconnecting...' : 'Disconnect'}
          onClick={() => !loading && onDisconnectClick()}
        />
      )}
    </SideContentContainer>
  );
};

export default ShopifyIntegrationModalSideContent;
