export enum UploadStatus {
  Pending = 'pending',
  Uploaded = 'uploaded',
  Failed = 'failed',
}

export type UploadCustomerBatch = {
  brandId: string;
  fileName: string;
};

export type UploadCustomerBatchStatus = {
  brandId: string;
  batchId: string;
  uploadStatus: UploadStatus;
};

export type BannerMessage = {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
};
