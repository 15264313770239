import * as date from 'date-fns';
import styled from '@emotion/styled';
import { Button, Text, colors } from '@innovationdepartment/proxima-ui';
import { BillingPlanTypeSubscription, PlanDescription, PlanStatus } from 'types/billing';
import BillingPlanPaymentFailed from './BillingPlan.PaymentFailed';
import { HELLO_EMAIL } from 'constants/emails';
import { ReactNode } from 'react';

const ButtonWrapper = styled.div`
  height: 38px;
  widht: 108px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Flex = styled.div`
  border: 1px solid ${colors.neutral10};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
`;

const DAYS_PAST_TRIAL_END_FOR_MESSAGE = 10;
const FLAT_FEE_AMOUNT = '2,388';

const formatDate = (dateToFormat: string) =>
  new Date(dateToFormat).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const BillingPlanView = ({
  plan,
  paymentFailed,
  trialEndedDate,
  currentPeriodEnd,
  customDescription,
  handleButtonClick,
  status,
}: BillingPlanTypeSubscription) => {
  const daysSinceTrialEnded = date.differenceInDays(new Date(), new Date(trialEndedDate));

  const planDescription: PlanDescription = {
    monthly: 'Variable fee: 10% of monthly spend on Proxima Audiences',
    yearly: `Flat fee: $${FLAT_FEE_AMOUNT} billed annually`,
    pro: customDescription,
    noPlan: daysSinceTrialEnded > DAYS_PAST_TRIAL_END_FOR_MESSAGE && (
      <Text variant="body2" color="red70">
        Your trial has ended
      </Text>
    ),
    freeTrial: <Text variant="body2">Trial ends on {formatDate(trialEndedDate)}</Text>,
  };

  const planTitle: PlanDescription = {
    monthly: 'Monthly (Starter)',
    yearly: 'Annual (Starter)',
    pro: 'Pro plan',
    noPlan: 'No plan',
    freeTrial: 'Trial',
  };

  const isCanceled = status === PlanStatus.Canceled;
  const hasNonTrialPlan = plan && plan !== 'noPlan' && plan !== 'freeTrial';
  const showManagePlanButton = hasNonTrialPlan && !isCanceled;

  const buttonLabel = showManagePlanButton ? 'Manage plan' : 'Choose plan';
  const buttonVariant = showManagePlanButton ? 'outlined' : 'contained';
  const buttonColor = showManagePlanButton ? 'secondary' : undefined;
  const mailTo = `mailto:${HELLO_EMAIL}`;

  let content: ReactNode;
  if (status === PlanStatus.Canceled) {
    content = (
      <>
        <Text variant="body2">No plan</Text>
      </>
    );
  } else {
    content = (
      <>
        <Text variant="body2Semibold">{plan && planTitle[plan]}</Text>
        <Text variant="body2">{plan && planDescription[plan]}</Text>
        {hasNonTrialPlan && (
          <Text variant="body2Semibold" color="neutral60">
            Next billing date {formatDate(currentPeriodEnd)}
          </Text>
        )}
        <Text variant="body2">
          To change your plan, please contact <a href={mailTo}>{HELLO_EMAIL}</a>
        </Text>
        {paymentFailed && <BillingPlanPaymentFailed />}
      </>
    );
  }

  return (
    <Flex>
      <Container>{content}</Container>
      <ButtonWrapper>
        <Button
          label={buttonLabel}
          variant={buttonVariant}
          color={buttonColor}
          onClick={() => handleButtonClick(buttonLabel === 'Choose plan')}
          size="small"
        />
      </ButtonWrapper>
    </Flex>
  );
};

export default BillingPlanView;
