import { useNavigate, useParams } from 'react-router-dom';
import { useKlaviyo } from 'hooks';
import { KlaviyoIntegrationModalProps } from 'types/components/integrations';
import KlaviyoIntegrationModalView from './KlaviyoIntegrationModal.View';

const KlaviyoIntegrationModal = (props: Pick<KlaviyoIntegrationModalProps, 'open' | 'onClose'>) => {
  const navigate = useNavigate();
  const { open, onClose } = props;
  const { getKlaviyoStatus } = useKlaviyo();
  const { brandId } = useParams<{ brandId: string }>();

  const onManageKlaviyoAccount = () => {
    navigate(`/brand/${brandId}/klaviyo/connect-account`);
  };

  return (
    <KlaviyoIntegrationModalView
      open={open}
      onClose={onClose}
      status={getKlaviyoStatus()}
      onManageClick={onManageKlaviyoAccount}
    />
  );
};

export default KlaviyoIntegrationModal;
