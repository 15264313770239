import { create } from 'zustand';
import { Integration, IntegrationStatus, IntegrationStore } from 'types/stores/integration-store';

const emptyState: Integration[] = [];

const useIntegrationsStore = create<IntegrationStore>((set) => ({
  integrations: emptyState,
  clear: () => set({ integrations: [] }),
  deleteIntegration: (integrationId: string) =>
    set((prevState) => ({
      integrations: prevState.integrations.map((integration) =>
        integration.integrationId === integrationId
          ? { ...integration, status: IntegrationStatus.Deleted }
          : integration
      ),
    })),
  addIntegration: (integration: Integration | Integration[]) =>
    set((prevState) => ({
      integrations: prevState.integrations.concat(
        ...(!Array.isArray(integration) ? [integration] : integration)
      ),
    })),
}));

export default useIntegrationsStore;
