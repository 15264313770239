import { useCallback, useEffect, useRef } from 'react';

type UseExecuteUntilConditionProps = {
  cb: () => void;
  condition: () => boolean;
  timeout?: number;
  waitForFirstCall?: boolean;
};

function usePolling() {
  const keepPolling = useRef<boolean>(true);
  const poll = useCallback((props: UseExecuteUntilConditionProps) => {
    const { cb, condition, timeout = 1000, waitForFirstCall = true } = props;
    let firstCallPending = true;

    const trampoline = async (fn: () => Promise<void> | void) => {
      while (keepPolling.current) {
        if (condition()) return;
        // eslint-disable-next-line
        await fn();
      }
    };

    const callableMethod = async () => {
      if (condition()) return;

      await cb();

      if (!keepPolling.current) return;

      const time = waitForFirstCall && firstCallPending ? 0 : timeout;

      const sleep = () => new Promise((resolve) => setTimeout(resolve, time));

      await sleep();

      firstCallPending = false;
    };

    return () => trampoline(callableMethod);
  }, []);

  useEffect(
    () => () => {
      // cleanup
      keepPolling.current = false;
    },
    []
  );

  return {
    pollUntilCondition: poll,
  };
}

export default usePolling;
