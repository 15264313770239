import { default as useIntegrationsStore } from './useIntegrationsStore';
import { default as useUserStore } from './useUserStore';
import { default as useBrandStore } from './useBrandStore';
import { default as useAdAccountStore } from './useAdAccountStore';

import storeDevTool from './storesDevTool';

const stores = {
  useUserStore,
  useBrandStore,
  useIntegrationsStore,
  useAdAccountStore,
};

storeDevTool(stores);

export { useUserStore, useBrandStore, useIntegrationsStore, useAdAccountStore };
