import { IntegrationType } from 'constants/integrations';
import styled from '@emotion/styled';
import { CheckmarkGrey, Text, colors } from '@innovationdepartment/proxima-ui';
import { AdPlatformSelectionViewProps } from 'types/components/audiences';

const SelectionOptions = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

type SelectionOptionProps = { isDisabled?: boolean; isSelected: boolean };

/*
  TODO(Jenky): make these elements components for the UI library
  once these platform selection is a thing in our app
*/
const getSelectedOptionStyles = (props: SelectionOptionProps) => {
  const { isSelected, isDisabled } = props;
  const isSelectedOrDisabled = isSelected || isDisabled;

  const styles = {
    cursor: 'pointer',
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.neutral30,
    '& > :last-child path': {
      fill: colors.neutral100,
    },
  };

  if (isSelectedOrDisabled) styles.backgroundColor = colors.neutral0;

  if (isSelected) {
    styles.borderWidth = '2px';
    styles.borderColor = colors.neutral100;
  } else if (isDisabled) {
    styles.borderColor = colors.neutral10;
  }

  return styles;
};

const SelectionOption = styled.div<SelectionOptionProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  flex: 0 0 258px;

  border-width: 1px;
  border-style: solid;

  ${getSelectedOptionStyles}

  /* label text */
  & > :nth-child(2) {
    flex: 1;
    margin-left: 8px;
  }

  & svg {
    max-width: 20px;
    max-height: 20px;
  }
`;

const AdPlatformSelectionOptions = (props: AdPlatformSelectionViewProps) => {
  const { platforms, onSelectPlatform, selectedPlatform, onTikTokOptionClick } = props;

  return (
    <SelectionOptions data-testid="available-platforms">
      {platforms.map((platform) => {
        const { icon, label, disabled, value, helperText } = platform;

        const isSelected = selectedPlatform === value;
        const isTiktok = value === IntegrationType.TikTok;

        const handleClick = () => {
          if (disabled) return;
          if (isTiktok) {
            onTikTokOptionClick();
            return;
          }
          onSelectPlatform(value);
        };

        return (
          <SelectionOption
            key={value}
            onClick={handleClick}
            isDisabled={platform.disabled}
            isSelected={isSelected}
          >
            {icon}
            <Text variant="body2" color={disabled ? 'neutral60' : 'neutral100'}>
              {label}
            </Text>
            {helperText && <Text variant="body3Semibold">{helperText}</Text>}
            {isSelected && <CheckmarkGrey />}
          </SelectionOption>
        );
      })}
    </SelectionOptions>
  );
};

export default AdPlatformSelectionOptions;
