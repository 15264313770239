import { useState } from 'react';
import { Form, useToaster } from '@innovationdepartment/proxima-ui';
import {
  CampaignAttributionSetting,
  CampaignObjective,
  CampaignStatus,
  CreateNewCampaignModalViewProps,
  CampaingInput,
  CampaignFormProps,
} from 'types/components/audienceCampaign';
import GenerateNewCampaignView from './GenerateNewCampaign.View';

const GenerateNewCampaign = (props: CreateNewCampaignModalViewProps) => {
  const { onCreateNewCampaign, audiencesCampaign, onStatusChange, onFormSkipClick } = props;
  const [loading, setLoading] = useState(false);
  const { showToaster } = useToaster();
  const { size, adPixels, audienceGroupId } = audiencesCampaign;

  const formatDate = () => {
    // format <month>.<day>.<year>
    const date = new Date();
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return `${month}.${day}.${year}`;
  };

  const setCampaignName = () => {
    const formatCreatedDate = formatDate();
    return `Proxima-Prospecting-${size * 100}%-${formatCreatedDate}`;
  };

  const initialValues = {
    name: setCampaignName(),
    campaignObjective: CampaignObjective.Sales,
    campaignAttributionSetting: CampaignAttributionSetting.Seven_Day_Click_And_One_Day_View,
    adPixel: adPixels.length > 0 ? adPixels[0].value : undefined,
    cpr: undefined,
    dailyBudget: undefined,
  } as CampaignFormProps;

  const onSubmit = async (formData: typeof initialValues) => {
    setLoading(true);
    const input: CampaingInput = {
      name: formData.name,
      campaignObjective: CampaignObjective.Sales,
      campaignAttributionSetting: CampaignAttributionSetting.Seven_Day_Click_And_One_Day_View,
      cpr: Number(formData.cpr),
      dailyBudget: Number(formData.dailyBudget),
      adPixelId: formData.adPixel ?? '',
    };

    const response = await onCreateNewCampaign(input, audienceGroupId);
    setLoading(false);

    if (!response) return;
    // on success, switch view to processing status
    if (!response.error) onStatusChange(CampaignStatus.Processing);
    // else, show error on modal, enable back the form
    else showToaster({ message: response.error, variant: 'error' });
  };

  const validateNumber = (value: number | undefined) => {
    if (!Number(value)) {
      return false;
    }
    if (Number(value) <= 0) {
      return false;
    }
    return true;
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    const amountMessage = 'An amount is required';
    if (!formData.name) errors.name = 'A campaign name is required';
    if (!validateNumber(formData.cpr)) errors.cpr = amountMessage;
    if (!validateNumber(formData.dailyBudget)) errors.dailyBudget = amountMessage;
    if (!formData.adPixel) errors.adPixel = 'Please select a pixel';

    return errors;
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <GenerateNewCampaignView
        loading={loading}
        adPixels={adPixels}
        onFormSkipClick={onFormSkipClick}
      />
    </Form>
  );
};

export default GenerateNewCampaign;
