import { Global, css } from '@emotion/react';

const globalStyles = css`
  html {
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  div {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: #007989;
  }

  a:hover,
  a:active,
  a:visited {
    color: #00525e;
    text-decoration: underline;
  }
`;

export default () => <Global styles={globalStyles} />;
