import { Routes, Route, Navigate } from 'react-router-dom';

import Users from 'components/Users';
import Billing from 'components/Billing';

import Settings from './Settings.Container';
import GeneralBrandSettings from './Layout/GeneralBrandSettings.Container';

const SettingsRouter = () => (
  <Routes>
    <Route path="" element={<Settings />}>
      <Route path="general" element={<GeneralBrandSettings />} />
      <Route path="users" element={<Users />} />
      <Route path="billing" element={<Billing />} />
      <Route index element={<Navigate to="users" />} />
    </Route>
  </Routes>
);

export default SettingsRouter;
