import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import {
  LookalikeAudienceTableRowProps,
  AudienceTableCellRendererProps,
  LookalikeAudienceStatus,
  LookalikeAudienceTableRowExtras,
  SeedAudienceStatus,
} from 'types/components/audiences';
import { status as statusUtils } from './utils';
import VerticalWrapper from './AudienceRowCell.VerticalWrapper';
import AudienceRowCellErrorStatus from './AudienceRowCell.ErrorStatus';

const StatusBadge = styled.div<{ color: string }>`
  align-self: flex-start;
  padding: 0 6px;
  border-radius: 10px;
  background-color: ${({ color }) => color};
`;

const AudienceRowCellLookalikeStatus = ({
  row,
  onRetryAudience,
  seedAudience,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps> &
  LookalikeAudienceTableRowExtras) => {
  // @ts-ignore
  const color = statusUtils.color[row?.displayStatus] ?? statusUtils.color.default;

  const isSeedAudienceError = seedAudience?.status === SeedAudienceStatus.Error;
  const isError = row?.displayStatus === LookalikeAudienceStatus.Error;

  // only show error status with retry if only lookalike audience is in error
  // lookalike status should show "error" if seed audience is in error
  const showErrorStatusComponent = !isSeedAudienceError && isError;

  const onRetry = () =>
    onRetryAudience({
      seedAudienceId: seedAudience.id,
      lookalikeAudienceId: row!.id,
    });

  if (showErrorStatusComponent) return <AudienceRowCellErrorStatus onRetry={onRetry} />;

  return (
    <VerticalWrapper>
      <StatusBadge color={color}>
        <Text variant="tag2Medium" color="neutral80">
          {row?.displayStatus}
        </Text>
      </StatusBadge>
    </VerticalWrapper>
  );
};

export default AudienceRowCellLookalikeStatus;
