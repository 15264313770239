import { PUBLIC_ROUTES } from 'components/App/constants';

export default function usePublicPage() {
  const isRoutePublicPage = (pathname: string | undefined) =>
    PUBLIC_ROUTES.some((route: string) => pathname && pathname.startsWith(route));

  return {
    isRoutePublicPage,
  };
}
