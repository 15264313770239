import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

import { formatDate } from 'utils';
import UsersActionMenu from './ActionMenu';

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const FontWeightWrapper = styled.span`
  & * {
    font-weight: 400;
  }
`;

const userRowRender = ({ header, cell }: { header: any; cell: any }) => {
  switch (header) {
    case 'email': {
      return (
        <FontWeightWrapper>
          <Text variant="table1" color="neutral100">
            {cell}
          </Text>
        </FontWeightWrapper>
      );
    }
    case 'lastLogin': {
      const formattedCell = cell instanceof Date ? formatDate(cell) : cell;
      return (
        <FontWeightWrapper>
          <Text variant="table1" color="neutral100">
            {formattedCell}
          </Text>
        </FontWeightWrapper>
      );
    }
    case 'menu': {
      if (cell) return <UsersActionMenu menuProps={cell} />;
      return null;
    }
    default: {
      return (
        <FontWeightWrapper>
          <Text variant="table1">
            <Capitalize>{cell}</Capitalize>
          </Text>
        </FontWeightWrapper>
      );
    }
  }
};

export default userRowRender;
