import { IApiResponse } from 'types/hooks/useHandleApi';

export type CampaignFormProps = {
  name: string;
  campaignObjective: CampaignObjective;
  campaignAttributionSetting: CampaignAttributionSetting;
  cpr: number | undefined;
  dailyBudget: number | undefined;
  adPixel: string | undefined;
};

export type CampaingInput = Omit<CampaignFormProps, 'adPixel'> & {
  adPixelId: string;
};

export enum CampaignObjective {
  Sales = 'OUTCOME_SALES',
}
export enum CampaignAttributionSetting {
  Seven_Day_Click_And_One_Day_View = '7-day click and 1-day view',
}

export type AdPixelProps = {
  options: Option[];
};

export type AdPixelViewProps = AdPixelProps & {
  loading: boolean;
  onRefreshClick: () => void;
};

export type CampaignAttributeProps = {
  campaignObjectiveOptions: Option[];
  campaignAttributionSettingOptions: Option[];
};

export type Option = { label: string; value: string };

export enum CampaignStatus {
  New = 'new',
  Processing = 'processing',
  Ready = 'ready',
  None = 'none',
}

export type CreateNewCampaignModalProps = {
  audiencesCampaign: AudiencesCampaign;
  onCreateNewCampaign: (
    input: CampaingInput,
    audienceGroupId: string
  ) => Promise<IApiResponse<any> | undefined>;
};

export type ActiveAdPixel = { id: string; name: string; createdDate: Date };

export type AudiencesCampaign = {
  size: number;
  audienceGroupId: string;
  adPixels: Option[];
};

export type GenerateNewCampaignViewProps = {
  loading: boolean;
  adPixels: Option[];
  onFormSkipClick: SkipCampaignViewProps['onFormSkipClick'];
};

export type CreateNewCampaignModalViewProps = CreateNewCampaignModalProps & {
  onStatusChange: (status: CampaignStatus) => void;
  onFormSkipClick: SkipCampaignViewProps['onFormSkipClick'];
};

export type GenerateNewCampaignProps = {
  onCreateNewCampaign: CreateNewCampaignModalViewProps['onCreateNewCampaign'];
};

export type SkipCampaignModalViewProps = {
  showSkipCampaignModal: boolean;
  onSkipModalClose: () => void;
  onSkipModalClick: () => void;
};

export type SkipCampaignModalProps = {
  showSkipCampaignModal: boolean;
  onSkipModal: () => void;
  onSkipModalClose: SkipCampaignModalViewProps['onSkipModalClose'];
};

export type SkipCampaignViewProps = {
  onFormSkipClick: () => void;
};
