import styled from '@emotion/styled';
import ContentSection from 'ui/ContentSection';
import { AdPlatformSelectionViewProps } from 'types/components/audiences';
import AdPlatformSelectionOptions from './AdPlatformSelection.Options';
import AdPlatformSelectionInfo from './AdPlatformSelection.Header';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const AdPlatformSelectionView = (props: AdPlatformSelectionViewProps) => (
  <ContentSection>
    <ContentWrapper>
      <AdPlatformSelectionInfo />
      <AdPlatformSelectionOptions {...props} />
    </ContentWrapper>
  </ContentSection>
);

export default AdPlatformSelectionView;
