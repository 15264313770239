import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { CreatedBrandResponse, UserBrandAccess } from '@innovationdepartment/proxima-sdk-axios';

import { useProximaSDK, useShopify, useShowSpinner } from 'hooks';
import { useAudiencesApi } from 'api';
import { Brand } from 'types/stores/brand-store';
import { useUserStore } from 'stores';

import * as analytics from 'lib/analytics';

import { FlavorCategory } from 'types/components/audiences';
import { ShopifyAppRedirect } from 'types/components/shopify';

const sendAnalytics = (userId: string) => {
  analytics.trackActionEvent({
    userId,
    action: 'Clicked',
    area: 'SignupFlow',
    location: 'BrandSetupModal',
    element: 'ContinueButton',
  });
  analytics.trackActionEvent({
    userId,
    action: 'FacebookAd conversion',
    area: 'SignupFlow',
    location: 'BrandSetupModal',
    element: 'ContinueButton',
  });
};

type BrandSetupKeys = 'brandName' | 'organizationType' | 'category' | 'website';
type BrandCreateParams = { [key in BrandSetupKeys]?: string };
type ErrorDefinitions = { [key in BrandSetupKeys]?: string };

const useBrandSetup = ({ pollInterval = 250 }) => {
  const brandsApi = useProximaSDK('BrandsApi');
  const usersApi = useProximaSDK('UsersApi');
  const { setShopifyURLRedirect, getShopifyURLRedirect } = useShopify();
  const navigate = useNavigate();
  const { user } = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [categories, setCategories] = useState<FlavorCategory[]>([]);
  const {
    loading: isFetchingCategories,
    getAvailableFlavorCategories,
    updateBrandAudienceSettings,
  } = useAudiencesApi();

  const loading = isLoading || isPolling || isFetchingCategories;

  useShowSpinner({ show: loading || isCreating });

  // TODO(Jenky): maybe use usePolling hook here ???
  const pollForBrand = async (brand: Brand) => {
    setIsPolling(true);
    function wait(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    let resultBrandId: string | null = null;
    let userHasAccess = false;
    setIsLoading(true);
    while (!userHasAccess) {
      // eslint-disable-next-line no-await-in-loop
      await wait(pollInterval);

      let userBrandsRes: AxiosResponse<UserBrandAccess[], any>;
      try {
        // eslint-disable-next-line no-await-in-loop
        userBrandsRes = await usersApi.getAllBrandsForUser({ userId: user!.userId });
      } catch (e) {
        setIsPolling(false);
        break;
      }

      if (userBrandsRes.status !== 200) {
        setIsPolling(false);
        break;
      }

      resultBrandId = brand.brandId;
      userHasAccess = userBrandsRes.data.some(
        ({ brandId }: { brandId: string }) => brandId === brand.brandId
      );
    }
    setIsPolling(false);
    return resultBrandId as string;
  };

  const navigateToNextStep = (brandId: string) => {
    const onboardingPath = `/brand/${brandId}/onboarding`;
    const shopifyUrl = getShopifyURLRedirect({
      key: ShopifyAppRedirect.Signup,
      remove: true,
      brandId,
    });
    setShopifyURLRedirect({
      path: onboardingPath,
      brandId,
      key: ShopifyAppRedirect.Onboarding,
      expireInMs: 1000 * 60 * 60 * 3,
    });
    navigate(shopifyUrl ?? onboardingPath);
  };

  const onContinueBrandSetup = async (brand: Brand, category: string) => {
    /* if it's gotten this far, brand credentials are ready for user */
    const resultBrandId = await pollForBrand(brand);
    /* update brand flavor category once user has access to brand */
    await updateBrandAudienceSettings({
      brandId: brand.brandId,
      flavorCategoryId: category as string,
    });

    setIsLoading(false);
    setIsCreating(false);
    sendAnalytics(user!.userId);
    navigateToNextStep(resultBrandId);
  };

  const onCreateBrand = async (
    params: BrandCreateParams
  ): Promise<ErrorDefinitions | undefined> => {
    if (!user) return undefined;
    const { brandName: name, category, website } = params;
    if (!name || !website) return undefined;
    setIsCreating(true);
    let createRes: AxiosResponse<CreatedBrandResponse, any>;

    try {
      createRes = await brandsApi.createBrand({ createBrandRequest: { name, website } });
    } catch (e) {
      setIsCreating(false);
      const errors: ErrorDefinitions = {};
      const { response } = e as any;
      if (response?.status === 409) {
        response.data.forEach((error: { path: string; message: string }) => {
          if (error.path === 'name')
            errors.brandName =
              'This brand already exists. Please request an invite from the account admin.';
          if (error.path === 'domain') errors.website = 'This URL is already taken.';
        });
      }
      return errors;
    }

    setIsCreating(false);
    if (createRes.status !== 200) return undefined;
    await onContinueBrandSetup(createRes.data as Brand, category as string);
    return undefined;
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await getAvailableFlavorCategories();
      if (res.status === 200) {
        setCategories(res.data);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!loading && !isPolling && !isFetchingCategories) {
      analytics.trackPageVisit({
        userId: user!.userId,
        area: 'SignupFlow',
        location: 'BrandSetupModal',
      });
    }
  }, [loading, isPolling, isFetchingCategories]);

  return {
    loading,
    isCreating,
    onCreateBrand,
    categories,
  };
};

export default useBrandSetup;
