import pick from 'lodash/pick';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { Brand } from 'types/stores/brand-store';

// this interface can be found in '@datadog/browser-rum/node_modules/@datadog/browser-core/User';
interface User {
  id?: string | undefined;
  email?: string | undefined;
  name?: string | undefined;
  [key: string]: unknown;
}

// set VITE_DATADOG_CLIENT_TOKEN and VITE_DATADOG_RUM_APP_ID in .env to test datadog logging locally
// manually error function example: datadogLogs.logger.error('test', { name: 'buttonName', id: 123 })
const initDataDog = () => {
  if (!import.meta.env.VITE_DATADOG_CLIENT_TOKEN || !import.meta.env.VITE_DATADOG_RUM_APP_ID) {
    // eslint-disable-next-line
    console.log(
      'Datadog client token or app ID has not been set. Logs are not being sent to Datadog'
    );
    return;
  }

  const env = import.meta.env.VITE_DATADOG_ENV ? import.meta.env.VITE_DATADOG_ENV : 'development';

  datadogLogs.setLoggerGlobalContext({ app: 'proxima' });

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'proxima-web',
    env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'], // only send console errors
    sampleRate: 100,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.VITE_VERSION,
  });

  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_RUM_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'proxima-web',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.VITE_VERSION,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};

const setUserId = (user: User) => {
  datadogRum.setUser(pick(user, ['id']));
  datadogLogs.setUser(pick(user, ['id']));
};

const setUserBrandId = (brand: Brand) => {
  datadogRum.setUserProperty('brand', pick(brand, ['brandId']));
  datadogLogs.setUserProperty('brand', pick(brand, ['brandId']));
};

export { initDataDog, setUserId, setUserBrandId };
