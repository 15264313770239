import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { SkipCampaignViewProps } from 'types/components/audienceCampaign';

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const SkipWrapper = styled.div`
  cursor: pointer;
  margin-top: -1px;
`;

const SkipCampaign = (props: SkipCampaignViewProps) => {
  const { onFormSkipClick } = props;

  return (
    <ContentWrapper>
      <Text variant="body2" color="neutral70">
        Rather setup the campaign yourself?&nbsp;
      </Text>
      <SkipWrapper onClick={onFormSkipClick}>
        <Text variant="body2Link" color="blue70">
          Skip
        </Text>
      </SkipWrapper>
    </ContentWrapper>
  );
};

export default SkipCampaign;
