import { useIntegrations } from 'hooks';
import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { IntegrationStatus } from 'types/stores/integration-store';
import { IntegrationResponseStatusEnum } from '@innovationdepartment/proxima-sdk-axios';

const useKlaviyo = () => {
  const { getAllIntegrationsByType } = useIntegrations();

  const getKlaviyoStatus = () => {
    const integrations = getAllIntegrationsByType(IntegrationType.Klaviyo);

    const isActive = integrations.some(
      (integration) => integration.status === IntegrationStatus.Active
    );

    const isIncomplete = integrations.some(
      (integration) => integration.status === IntegrationResponseStatusEnum.Expired
    );

    const isDisconnected = integrations.length > 0 && !isActive;

    /* returning order matters here */
    if (isActive) return BrandIntegrationStatus.Connected;

    if (isDisconnected || isIncomplete) return BrandIntegrationStatus.Disconnected;

    return BrandIntegrationStatus.NeverConnected;
  };

  return {
    getKlaviyoStatus,
  };
};

export default useKlaviyo;
