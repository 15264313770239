import styled from '@emotion/styled';
import { colors, Text, LabelStatus, FormField } from '@innovationdepartment/proxima-ui';
import ConnectKlaviyoAccountConfigurationButtons from './ConnectKlaviyoAccount.ConfigurationButtons';
import { ConnectKlaviyoAccountConfigurationViewProps } from 'types/components/klaviyo';
import { BrandIntegrationStatus } from 'constants/integrations';
import { PropsOf } from '@emotion/react';

const ConfigurationWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.neutral20};
  max-width: 640px;
`;

const TitleWrapper = styled.div`
  padding: 4px 0 12px;
  margin: 8px 20px 0;
  display: flex;
  border-bottom: 1px solid ${colors.neutral10};
  justify-content: space-between;
`;

const FieldsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const getStatus = (status: BrandIntegrationStatus) => {
  const KLAVIYO_LABEL_STATUS_MAP: { [key: string]: PropsOf<typeof LabelStatus> } = {
    [BrandIntegrationStatus.Connected]: { label: 'Connected', variant: 'success' },
    [BrandIntegrationStatus.Disconnected]: { label: 'Disconnected', variant: 'error' },
    [BrandIntegrationStatus.NeverConnected]: { label: 'Not Connected', variant: 'default' },
  };
  return (
    KLAVIYO_LABEL_STATUS_MAP[status] ??
    KLAVIYO_LABEL_STATUS_MAP[BrandIntegrationStatus.NeverConnected]
  );
};

const ConnectKlaviyoAccountConfigurationView = (
  props: ConnectKlaviyoAccountConfigurationViewProps & { rerender: number }
) => {
  const { status, rerender } = props;

  return (
    <ConfigurationWrapper>
      <TitleWrapper>
        <Text color="neutral90" variant="body1Semibold">
          Klaviyo API configuration
        </Text>
        <LabelStatus {...getStatus(status)} />
      </TitleWrapper>
      <FieldsWrapper>
        <FieldWrapper>
          <Text variant="body2Semibold" color="neutral100">
            Private API key
          </Text>
          <FormField type="input" name="privateKey" key={rerender} />
        </FieldWrapper>
        <FieldWrapper>
          <Text variant="body2Semibold" color="neutral100">
            Public API key
          </Text>
          <FormField type="input" name="publicKey" key={rerender + 1000} />
        </FieldWrapper>
      </FieldsWrapper>
      <ConnectKlaviyoAccountConfigurationButtons {...props} />
    </ConfigurationWrapper>
  );
};

export default ConnectKlaviyoAccountConfigurationView;
