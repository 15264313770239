import { useLocation, useNavigate } from 'react-router-dom';
import AccountView from './Account.View';

const MENU_ITEMS = [{ label: 'General', pathname: 'general' }];

const Account = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onRouteChange = (path: string) => navigate(path);

  return <AccountView menuItems={MENU_ITEMS} onNavigate={onRouteChange} pathname={pathname} />;
};

export default Account;
