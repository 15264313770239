import styled from '@emotion/styled';
import { Banner, Text, Upload, colors } from '@innovationdepartment/proxima-ui';
import { useState } from 'react';
import { BannerMessage } from 'types/brandCustomerSettings';
import ModalBase, { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import CustomerListUploadModalHeader from './Layout/CustomerListUploadModal.Header';
import CustomerListUploadModalInstructions from './Layout/CustomerListUploadModal.Instructions';
import CustomerListUploadModalFileUpload from './Layout/CustomerListUploadModal.FileUpload';

const HeaderContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadIconContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 4px;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 48px;
`;

const uploadHeader = (
  <HeaderContainer>
    <UploadIconContainer>
      <Upload />
    </UploadIconContainer>
    <HeaderText>
      <Text variant="overline1" color="neutral60">
        Secure Upload
      </Text>
      <Text variant="h6">Manual Import</Text>
    </HeaderText>
  </HeaderContainer>
);

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 64px;
  width: 640px;
`;

const NoteText = styled(Text)`
  padding-top: 12px;
`;

const Spacing = styled.div`
  margin-top: 32px;
`;

type CustomerListUploadModalViewProps = {
  open: boolean;
  onClose: () => void;
  handleCustomerListUpload: (fileUploaded: File) => Promise<BannerMessage | undefined>;
};

const CustomerListUploadModalView = (props: CustomerListUploadModalViewProps) => {
  const { open, onClose, handleCustomerListUpload } = props;

  const [bannerMessage, setBannerMessage] = useState<BannerMessage>();

  const handleClose = () => {
    onClose();
    setBannerMessage(undefined);
  };

  return (
    <ModalBase open={open} onClose={handleClose} title={uploadHeader} full>
      <ModalBaseChildrenWrapper>
        <ModalContentContainer>
          <CustomerListUploadModalHeader />
          <CustomerListUploadModalInstructions />
          <NoteText variant="body2" color="neutral70">
            Note: It can take a few minutes for your file upload to be verified. You will receive an
            email when verification is complete.
          </NoteText>
          {bannerMessage && (
            <Spacing>
              <Banner
                body={bannerMessage.message}
                title=""
                type={bannerMessage.type}
                showIcon={false}
              />
            </Spacing>
          )}
          <CustomerListUploadModalFileUpload
            onBannerMessageChanged={setBannerMessage}
            handleCustomerListUpload={handleCustomerListUpload}
          />
        </ModalContentContainer>
      </ModalBaseChildrenWrapper>
    </ModalBase>
  );
};

export default CustomerListUploadModalView;
