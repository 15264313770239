import { useEffect } from 'react';
import { useErrorBoundary } from 'use-error-boundary';

const RenderErrorBoundary: React.FC = ({ children }) => {
  const { ErrorBoundary, error, reset } = useErrorBoundary();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      reset();
    }
  }, [error]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default RenderErrorBoundary;
