import { Method } from 'axios';

export type HttpMethod = Method;

interface IApiRequestDetails {
  url: string;
  accessToken?: string;
  method?: HttpMethod;
  data?: any; // TODO(Jenky): change?
  headers?: { [key: string]: string };
  skipErrorToaster?: boolean;
}

export interface IApiValidationError {
  path: string;
  message: string;
}

export interface IApiResponse<T = any> {
  status: number | null;
  data?: T | null;
  error?: string | null;
  url?: string | null;
}

export type HandleApiRequest = (options: IApiRequestDetails) => Promise<IApiResponse>;

export type HandleApiOpts = Omit<IApiRequestDetails, 'url' | 'accessToken'> & {
  endpoint: string;
  brandId?: string;
  publicEndpoint?: boolean;
  skipErrorToaster?: boolean;
};

export enum ProximaApiResponseCode {
  // Successful responses
  Success = 200,
  NoContent = 204,
  // Unsuccessful responses
  AuthenticationFailure = 401,
  AuthorizationFailure = 403,
  NotAcceptable = 406,
  UnsupportedMediaType = 415,
}
