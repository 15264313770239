import { useAuth0 } from '@auth0/auth0-react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useUserApi } from 'api';
import { useBrandStore, useUserStore } from 'stores';
import capEmailText from 'utils/capEmailText';
import * as analytics from 'lib/analytics';

import VerifyView from './Verify.View';

const Verify = () => {
  const { user } = useUserStore();
  const { clearBrandStore } = useBrandStore();
  const { sendVerificationEmail } = useUserApi();
  const { showToaster } = useToaster();
  const { logout } = useAuth0();

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
    clearBrandStore();
  };

  const onSendVerificationEmailClick = async () => {
    const response = await sendVerificationEmail(user!.userId);
    if (response.error) return;
    showToaster({
      message: 'Verification email sent.',
      variant: 'success',
    });
    analytics.trackActionEvent({
      action: 'Clicked',
      userId: user!.userId,
      area: 'SignupFlow',
      location: 'VerifyEmailModal',
      element: 'ResendButton',
    });
  };

  const email = capEmailText(user!.email!);

  return (
    <VerifyView
      onLogout={onLogout}
      email={email}
      onSendEmailAgainClick={onSendVerificationEmailClick}
    />
  );
};

export default Verify;
