type Storage = typeof window.localStorage | typeof window.sessionStorage;

/*
  TODO(Jenky): Convert this hook into a redirection system
*/
function useStorage(store: Storage = window.sessionStorage) {
  return {
    setItem: (key: string, value: string, expireInMs?: number) => {
      store.setItem(key, value);
      if (expireInMs) {
        const expire = new Date().getTime() + expireInMs;
        store.setItem(`${key}_expire`, expire.toString());
      }
    },
    getItem: (key: string, remove = false) => {
      const expire = store.getItem(`${key}_expire`);
      if (expire && new Date().getTime() > parseInt(expire, 10)) {
        store.removeItem(key);
        store.removeItem(`${key}_expire`);
        return null;
      }
      const value = store.getItem(key);
      if (remove) {
        store.removeItem(key);
        store.removeItem(`${key}_expire`);
      }
      return value;
    },
    removeItem: (key: string) => {
      store.removeItem(key);
      store.removeItem(`${key}_expire`);
    },
  };
}

export default useStorage;
