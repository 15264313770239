import { AUDIENCES_GETTING_STARTED_URL, AUDIENCES_METHODOLOGIES_GUIDE_URL } from 'constants/urls';
import styled from '@emotion/styled';
import { KnowledgeSmall, Text, colors } from '@innovationdepartment/proxima-ui';
import ProximaLink from 'ui/ProximaLink';

const LinksWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 16px;
  & > * {
    & path {
      fill: ${colors.blue70};
    }
    color: ${colors.blue70};
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      color: ${colors.blue80};
      & path {
        fill: ${colors.blue80};
      }
    }
  }
`;

const AudiencesGettingStartedLinks = () => (
  <LinksWrapper>
    <ProximaLink to={AUDIENCES_GETTING_STARTED_URL}>
      <KnowledgeSmall />
      <Text>How to get started</Text>
    </ProximaLink>
    <ProximaLink to={AUDIENCES_METHODOLOGIES_GUIDE_URL}>
      <KnowledgeSmall />
      <Text>Campaign setup guide</Text>
    </ProximaLink>
  </LinksWrapper>
);

export default AudiencesGettingStartedLinks;
