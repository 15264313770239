import { create } from 'zustand';
import pick from 'lodash/pick';

import { User, UserStore } from 'types/stores/user-store';

const allowedKeys = ['userId', 'firstName', 'lastName', 'email', 'emailVerified'];

const useUserStore = create<UserStore>((set) => ({
  user: null,
  clearUserStore: () => set({ user: null }),
  updateUserStore: (user: Partial<User>) =>
    set((prevState) => {
      const newUser = pick(user, allowedKeys) as User;
      const store: Partial<UserStore> = { user: newUser };
      if (prevState.user) store.user = { ...prevState.user, ...newUser };
      return store;
    }),
}));

export default useUserStore;
