import styled from '@emotion/styled';
import {
  SearchBox,
  SpaceDivider,
  Text,
  SatelliteConnectionNoDetectedGray,
  Banner,
} from '@innovationdepartment/proxima-ui';
import { ChooseBrandForShopifyIntegrationProps } from 'types/components/integrations';
import { Brand } from 'types/stores/brand-store';
import ChooseBrandForShopifyBrandItem from './ChooseBrandForShopify.BrandItem';
import ChooseBrandForShopifyFooter from './ChooseBrandForShopify.Footer';

const Container = styled.div`
  max-width: 640px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`;

const NoBrandsFoundContainer = styled.div`
  height: 100%;
  display: grid;
  text-align: center;
  place-content: center;
`;

const SatelliteConnectionNoDetectedGrayWrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
  margin-bottom: 16px;
`;

const BannerWrapper = styled.div`
  margin-bottom: 24px;
`;

const banners = {
  noBrands: {
    title: 'Unable to find your Proxima account',
    body: 'We couldn’t detect your Proxima account. Please refresh and try again.',
  },
  alreadyInUse: {
    title: 'Proxima account already connected to Shopify',
    body: 'The Proxima account you selected is already integrated with another Shopify store. Please connect a different Proxima account.',
  },
};

const renderBanner = (type: keyof typeof banners) => (
  <BannerWrapper>
    <Banner type="error" {...banners[type]} />
  </BannerWrapper>
);

const ChooseBrandForShopifyView = (props: ChooseBrandForShopifyIntegrationProps) => {
  const { brands, loading, error, selectedBrandId, onBrandSelect } = props;

  if (loading) return null;

  const noBrandsFound = !brands.length && !loading && !error;

  return (
    <Container>
      {noBrandsFound && renderBanner('noBrands')}
      {error && renderBanner('alreadyInUse')}
      <Text variant="h5"> Select Proxima account </Text>
      <SpaceDivider />
      <Text variant="body1">
        Choose the Proxima account you would like to integrate Shopify with
      </Text>
      <SpaceDivider y={8} />
      <div style={{ height: 376 }}>
        <SearchBox
          items={brands}
          searchCriteria="name"
          renderItem={(brand: Brand) => (
            <ChooseBrandForShopifyBrandItem
              key={brand.brandId}
              brand={brand}
              onClick={onBrandSelect}
              selected={selectedBrandId === brand.brandId}
            />
          )}
          renderEmpty={() => (
            <NoBrandsFoundContainer>
              <SatelliteConnectionNoDetectedGrayWrapper>
                <SatelliteConnectionNoDetectedGray />
              </SatelliteConnectionNoDetectedGrayWrapper>
              <Text variant="body2" color="neutral80">
                {`No Proxima accounts found. ${
                  brands.length ? 'Try a different search term' : 'Try refreshing'
                }.`}
              </Text>
            </NoBrandsFoundContainer>
          )}
        />
      </div>
      <ChooseBrandForShopifyFooter {...props} />
    </Container>
  );
};

export default ChooseBrandForShopifyView;
