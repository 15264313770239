export enum IntegrationStatus {
  Active = 'active',
  Expired = 'expired',
  Unauthorized = 'unauthorized',
  Deleted = 'deleted',
}

export type Integration = {
  integrationId: string;
  brandId: string;
  type: string;
  status: IntegrationStatus;
  createdByUserId: string;
  metaData: Record<string, string>;
  lastUpdateAt: string;
  /* klaviyo props */
  syncEnabled?: boolean;
  token?: string;
  integrationKey?: string;
};

export type IntegrationStore = {
  integrations: Integration[];
  clear: () => void;
  deleteIntegration: (integrationId: string) => void;
  addIntegration: (integration: Integration | Integration[]) => void;
};
