import { Route, Routes } from 'react-router-dom';

import BrandSetup from './BrandSetup';
import UserSetup from './UserSetup';

import Setup from './Setup.Container';
import SetupState from './Setup.State';

const SetupRouter = () => (
  <Routes>
    <Route path="" element={<Setup />}>
      <Route path="user" element={<UserSetup />} />
      <Route path="brand" element={<BrandSetup />} />
      <Route index element={<SetupState />} />
    </Route>
  </Routes>
);

export default SetupRouter;
