import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as date from 'date-fns';
import { useBillingApi } from 'api';
import * as analytics from 'lib/analytics';
import { useBrandStore, useUserStore } from 'stores';
import TrialEndedBannerView from './TrialEnded.Banner.View';
import TrialEndedPillView from './TrialEnded.Pill.View';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';
import { BillingPlanSubscription, Plan, PlanStatus } from 'types/billing';
import { debounce } from 'lodash';
import { BrandStatus } from 'types/stores/brand-store';

const debouncer = debounce((cb) => cb(), 500);

const TrialEndedContainer = () => {
  const { brand } = useBrandStore();
  const [manageSubscriptionModalOpen, setManageSubscriptionModalOpen] = useState(false);
  const location = useLocation();
  const { user } = useUserStore();
  const { getBrandSubscription } = useBillingApi();
  const [subscription, setSubscription] = useState<BillingPlanSubscription>();

  const ROUTES_TO_INCLUDE = [
    `/brand/${brand?.brandId}/audiences`,
    `/brand/${brand?.brandId}/integrations`,
  ];

  const fetchSubscription = async () => {
    if (brand?.brandId) {
      const response = await getBrandSubscription(brand?.brandId);

      if (response.error) return;

      setSubscription({
        ...response.data,
        plan: response.data.metadata.plan,
      });
    }
  };

  const onOpenManageSubscriptionModal = () => {
    analytics.trackActionEvent({
      action: 'Clicked',
      userId: user!.userId,
      brandId: brand?.brandId,
      location: 'TrialEndedContainerChoosePlan',
      element: 'ChoosePlanButton',
    });

    setManageSubscriptionModalOpen(true);
  };

  useEffect(() => {
    debouncer(fetchSubscription);
  }, [brand]);

  const trialEndDate = subscription?.trialEndDate;

  const planIsCanceled = subscription?.status === PlanStatus.Canceled;
  const brandIsLocked = brand.status === BrandStatus.Locked;
  const planIsNotTrialing =
    subscription?.status !== PlanStatus.Trialing || subscription?.metadata?.plan !== Plan.FreeTrial;
  const shouldShowBanner = ROUTES_TO_INCLUDE.some((path) => location.pathname === path);

  let trialEndedComponent: ReactNode = null;

  // don't show on routes that aren't /audiences or /integrations
  if (!shouldShowBanner) return null;

  // don't render banner if brand is locked
  if (brandIsLocked) return null;

  if (planIsCanceled) {
    trialEndedComponent = (
      <TrialEndedBannerView onOpenManageSubscriptionModal={onOpenManageSubscriptionModal} />
    );
  } else {
    // if the brand trial is null, then we don't need to show the banner.
    // If we are no longer in a free trial or our plan is not active do not show anything
    if (planIsNotTrialing || !trialEndDate) return null;

    // Convert unix timestamp into a date
    const trialEndDateConverted = new Date(trialEndDate);

    const trialExpired = date.isAfter(new Date(), trialEndDateConverted);

    // This is to account for the time included in the trial end date passed from Stripe
    const differenceInHours = date.differenceInHours(trialEndDateConverted, new Date());

    const daysRemaining = Math.ceil(differenceInHours / 24);

    // if today's date is after the brand trial end date and the brand is not locked, then we need to show the banner.
    if (trialExpired)
      trialEndedComponent = (
        <TrialEndedBannerView onOpenManageSubscriptionModal={onOpenManageSubscriptionModal} />
      );

    // if today's date is within 30 days of the trial end date and the trial end date hasn't passed, then we need to show the pill.
    if (daysRemaining <= 30 && daysRemaining > 0)
      trialEndedComponent = (
        <TrialEndedPillView
          daysRemaining={daysRemaining}
          onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
        />
      );
  }

  return (
    <>
      {trialEndedComponent}
      {brand && (
        <BuildPlanModal
          brandId={brand.brandId}
          open={manageSubscriptionModalOpen}
          onClose={() => setManageSubscriptionModalOpen(false)}
        />
      )}
    </>
  );
};

export default TrialEndedContainer;
