import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import IncludedSection from './Layout/IncludedSection.View';
import PlanTypeBox from './Layout/PlanTypeBox.View';

type YearlyPlanViewProps = {
  loading: boolean;
  onSubmit: () => void;
};

const PlanInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PriceWrapper = styled.div`
  display: flex;
  gap: 4px;

  h3 {
    line-height: 36px;
  }

  span {
    margin-top: auto;
  }
`;

const options = [
  'Flat fee, no matter how much you spend on Proxima Audiences',
  'Category audiences',
  'Custom audiences',
  'Premium support',
  'Unlimited seats',
];

const YearlyPlanView = ({ loading, onSubmit }: YearlyPlanViewProps) => (
  <>
    <PlanTypeBox text="Starter" />
    <PlanInformationWrapper>
      <PriceWrapper>
        <Text variant="h3">$199</Text>
        <Text variant="body1Semibold">/mo</Text>
      </PriceWrapper>
      <Text variant="body2" color="neutral50">
        Billed annually
      </Text>
    </PlanInformationWrapper>
    <IncludedSection
      onSubmit={onSubmit}
      buttonDisabled={loading}
      buttonText={loading ? 'Loading...' : 'Continue'}
      options={options}
    />
  </>
);

export default YearlyPlanView;
