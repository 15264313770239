import { useQuery, useCheckMetaCustomTos } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

import AcceptFacebookCustomTermsOfServiceView from './AcceptFacebookCustomTermsOfService.View';

const ANALYTICS_LOCATION = 'AcceptFacebookCustomTermsOfService';

const AcceptFacebookCustomTermsOfService = () => {
  const { brandId } = useParams<'brandId'>();
  const { getQueryParams } = useQuery();
  const navigate = useNavigate();
  const { adAccount, redirect, origin } = getQueryParams<'adAccount' | 'redirect' | 'origin'>();

  const backToUrl = redirect ?? `/brand/${brandId}/audiences`;

  const { loading, onAcceptTermsClick, onSkipClick, onTermsAlreadyAcceptedClick, step } =
    useCheckMetaCustomTos({
      type: 'page',
      analytics: { location: ANALYTICS_LOCATION, area: 'CustomTermsOfServiceNotYetAccepted' },
      redirectTo: backToUrl,
      adAccountId: adAccount,
    });

  const onClose = origin === 'integrations' ? () => navigate(-1) : undefined;

  return (
    <AcceptFacebookCustomTermsOfServiceView
      step={step}
      loading={loading}
      backToUrl={backToUrl}
      onClose={onClose}
      onSkipClick={onSkipClick}
      onAcceptTermsClick={onAcceptTermsClick}
      onTermsAlreadyAcceptedClick={onTermsAlreadyAcceptedClick}
    />
  );
};

export default AcceptFacebookCustomTermsOfService;
