import styled from '@emotion/styled';
import { ModalStep } from 'types/components/audiences';
import ContentSection from 'ui/ContentSection';
import GeneratedAudiencesStatusHeader from 'ui/Modals/CreateNewAudiencesModal/GeneratedAudiencesStatus/GeneratedAudiencesStatus.Header';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 12px;
`;

const LookalikeSizeSubmitting = () => (
  <ContentSection>
    <ContentWrapper>
      <GeneratedAudiencesStatusHeader isProcessing stepType={ModalStep.Audience} />
    </ContentWrapper>
  </ContentSection>
);

export default LookalikeSizeSubmitting;
