import styled from '@emotion/styled';
import {
  GenerateAudiences,
  IterateAndScale,
  LaunchProspectingCampaigns,
  Text,
  colors,
} from '@innovationdepartment/proxima-ui';
import React from 'react';

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 52px 0;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  max-width: 300px;

  & > :first-child {
    margin-bottom: 32px;
  }

  & > :last-child {
    margin-top: 12px;
  }
`;

const StepTitleWrapper = styled.div<{ index: number }>`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const IndexWrapper = styled.div`
  text-align: center;
  white-space: pre;
  margin-top: 2px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${colors.neutral10};
`;

const STEPS = [
  {
    icon: <GenerateAudiences />,
    title: 'Generate audiences',
    description: 'Select lookalike size, pick the ad platform, and generate your audiences.',
  },
  {
    icon: <LaunchProspectingCampaigns />,
    title: 'Launch prospecting campaigns',
    description: 'We’ll deliver the audiences directly to your ad account.',
  },
  {
    icon: <IterateAndScale />,
    title: 'Iterate and scale',
    description: 'Try new lookalike sizes and refreshed audiences, available weekly.',
  },
];

type StepWrapperProps = {
  index: number;
  icon: React.ReactNode;
  title: string;
  description: string;
};

const Step = ({ index, icon, title, description }: StepWrapperProps) => (
  <StepWrapper>
    {icon}
    <StepTitleWrapper index={index}>
      <IndexWrapper>
        <Text variant="body2Semibold" color="neutral80">
          {index}
        </Text>
      </IndexWrapper>
      <Text variant="subhead1" color="neutral80">
        {title}
      </Text>
    </StepTitleWrapper>
    <Text variant="body2">{description}</Text>
  </StepWrapper>
);

const SeedAudiencesTableEmptyContentSteps = () => (
  <StepsWrapper>
    {STEPS.map((step, index) => (
      <Step
        key={step.title}
        index={index + 1}
        icon={step.icon}
        title={step.title}
        description={step.description}
      />
    ))}
  </StepsWrapper>
);

export default React.memo(SeedAudiencesTableEmptyContentSteps);
