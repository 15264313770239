import { IntegrationType } from 'constants/integrations';
import { useIntegrations, useShopify, useShowSpinner } from 'hooks';
import { ShopifyIntegrationModalProps } from 'types/components/integrations';
import ShopifyIntegrationModalView from './ShopifyIntegrationModal.View';
import { useLocation } from 'react-router-dom';
import { ShopifyAppRedirect } from 'types/components/shopify';

const ShopifyIntegrationModal = (props: Pick<ShopifyIntegrationModalProps, 'open' | 'onClose'>) => {
  const { onClose, open } = props;
  const { getActiveIntegrations, deleteIntegration, loading } = useIntegrations();

  const { getShopifyStatus, setShopifyURLRedirect } = useShopify();
  const { pathname } = useLocation();

  useShowSpinner({ show: loading });

  const [shopifyIntegration] = getActiveIntegrations(IntegrationType.Shopify);
  const shop = shopifyIntegration?.metaData?.shop?.replace('.myshopify.com', '');

  const onDisconnectClick = () => {
    if (shopifyIntegration) {
      deleteIntegration(shopifyIntegration.integrationId);
    }
  };

  const onGetStartedClick = () => {
    setShopifyURLRedirect({ path: pathname, key: ShopifyAppRedirect.Integrations });
    window.open(
      import.meta.env.VITE_SHOPIFY_APP_LISTING_URL || 'https://apps.shopify.com/',
      '_self'
    );
  };

  const shopifyStatus = getShopifyStatus();

  return (
    <ShopifyIntegrationModalView
      status={shopifyStatus}
      loading={loading}
      onDisconnectClick={onDisconnectClick}
      onGetStartedClick={onGetStartedClick}
      storeName={shop}
      open={open}
      onClose={onClose}
    />
  );
};

export default ShopifyIntegrationModal;
