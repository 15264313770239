import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as analytics from 'lib/analytics';
import { AxiosResponse } from 'axios';
import { UserBrandAccess } from '@innovationdepartment/proxima-sdk-axios';
import { Form, useToaster } from '@innovationdepartment/proxima-ui';

import { useProximaSDK, useShowSpinner } from 'hooks';
import { validatePhoneNumber } from 'utils/validations';
import { useUserStore } from 'stores';
import UserSetupView from './UserSetup.View';

import { ToasterSuccess } from '../constants';

const Keys = ['firstName', 'lastName', 'phoneNumber'] as const;

const cleanFormDataToSubmit = (formData: { [key in (typeof Keys)[number]]: string }) => {
  const { phoneNumber, ...cleanedData } = formData;
  const data = cleanedData as typeof formData & { phoneNumber?: string };
  if (phoneNumber) {
    data.phoneNumber = `+1${phoneNumber.replace(/\D/g, '')}`;
  }

  return data;
};

const UserSetup = () => {
  const usersApi = useProximaSDK('UsersApi');

  const navigate = useNavigate();
  const { showToaster } = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserStore();
  const [hasBrands, setHasBrands] = useState(false);

  useShowSpinner({ show: isLoading });

  const initialValues: { [key in (typeof Keys)[number]]: string } = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const validate = async (formValues: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formValues.firstName?.length) errors.firstName = 'Required';
    if (!formValues.lastName?.length) errors.lastName = 'Required';

    // phone number validations
    const cleanPhoneNumber = formValues.phoneNumber?.replace(/\D/g, '');
    if (cleanPhoneNumber) {
      if (cleanPhoneNumber.startsWith('1')) errors.phoneNumber = 'Not a valid phone number';
      if (validatePhoneNumber(cleanPhoneNumber)) errors.phoneNumber = 'Not a valid phone number';
    }

    return errors;
  };

  const onSubmit = async (formData: typeof initialValues) => {
    if (!user) return;
    setIsLoading(true);
    let response: AxiosResponse<void, any>;

    try {
      response = await usersApi.updateProfile({
        userId: user.userId,
        updateProfileRequest: cleanFormDataToSubmit(formData),
      });
    } catch (e) {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    if (response.status !== 200) return;

    showToaster(ToasterSuccess);

    analytics.trackActionEvent({
      action: 'Clicked',
      userId: user.userId,
      area: 'SignupFlow',
      location: 'UserSetupModal',
      element: 'ContinueButton',
    });

    if (!user.emailVerified) {
      /* lets block their access until they verify their email */
      navigate('/email/verify');
    } else if (hasBrands) {
      /* if user is not new, lets select a brand to start on */
      navigate('/brands');
    } else {
      /* otherwise lets create a new brand */
      navigate('/setup/brand');
    }
  };

  useEffect(() => {
    // there is no way to get here without a user
    if (user) {
      const getBrands = async () => {
        let brandsResponse: AxiosResponse<UserBrandAccess[], any>;
        try {
          brandsResponse = await usersApi.getAllBrandsForUser({ userId: user.userId });
        } catch (e) {
          return;
        }

        if (brandsResponse.status === 200) {
          setHasBrands(brandsResponse.data.length > 0);
        }
      };
      getBrands();
    }
  }, []);

  if (isLoading) return null;

  return (
    <Form validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
      <UserSetupView />
    </Form>
  );
};

export default UserSetup;
