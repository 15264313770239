import { useState } from 'react';
import BuildPlanModalContentView from './BuildPlanModalContent.View';

type BuildPlanModalContentProps = {
  brandId: string;
};

const BuildPlanModalContent = ({ brandId }: BuildPlanModalContentProps) => {
  const [spendAmount, setSpendAmount] = useState<string | undefined>('none');

  return (
    <BuildPlanModalContentView
      brandId={brandId}
      spendAmount={spendAmount}
      setSpendAmount={setSpendAmount}
    />
  );
};

export default BuildPlanModalContent;
