import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserStore, useBrandStore } from 'stores';

// determins state of setup wizard and navigates to correct step
// temporary component, will be replaced by upcoming routing improvements
// TBD on unit tests for that reason

const SetupState = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const { brand } = useBrandStore();

  useEffect(() => {
    // to do: revisit how we init these state stores
    if (user && !user.firstName) {
      navigate('/setup/user');
      return;
    }
    if (brand && !brand.brandId) {
      navigate('/setup/brand');
      return;
    }
    if (brand && user) {
      navigate('/');
    }
  }, [user, brand]);

  return <></>;
};

export default SetupState;
