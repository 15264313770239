import styled from '@emotion/styled';
import { Banner, SearchBox, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import { AdAccount } from 'types/stores/ad-account-store';
import NoAds from './NoAds';
import AdSetupFooter from './Footer/AdAccountSelectionFooter.Container';
import AdsSetupSearchItem from './AdAccountSelection.SearchItem';

type AdAccountSelectionViewProps = {
  brandId: string;
  selectedAdAccount: AdAccount | undefined;
  adAccounts: AdAccount[];
  setSelectedAdAccount: (adAccount: AdAccount) => void;
  saveAdAccount: () => void;
  isLoading: boolean;
  isSaving: boolean;
  isError: boolean;
  redirectTo: string;
  refreshAdAccounts: () => void;
};

const BannerWrapper = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  max-width: 640px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`;

const NoAdsFoundInSearch = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
`;

const SupportEmailLink = <a href="mailto:hello@proxima.ai">hello@proxima.ai</a>;

const banners = {
  error: {
    title: 'Ad account already in use',
    body: (
      <span>
        The Meta ad account you selected is already in use. Please select another or request an
        invite from the account owner. Contact {SupportEmailLink} for support.
      </span>
    ),
  },
  noAds: {
    title: 'There are no ad accounts connected to your Meta account',
    body: 'Please refresh or try a different Meta account.',
  },
};

const renderBanner = (type: 'error' | 'noAds') => (
  <BannerWrapper>
    <Banner type="error" {...banners[type]} />
  </BannerWrapper>
);

const AdAccountSelectionView = ({
  brandId,
  selectedAdAccount,
  adAccounts,
  isLoading,
  isSaving,
  isError,
  saveAdAccount,
  refreshAdAccounts,
  setSelectedAdAccount,
  redirectTo,
}: AdAccountSelectionViewProps) => {
  const noAds = adAccounts.length === 0 && !isLoading;

  let content = (
    <div style={{ height: 376 }}>
      <SearchBox
        items={adAccounts}
        searchCriteria="name"
        renderItem={(adAccount: AdAccount) => (
          <AdsSetupSearchItem
            selected={adAccount.accountId === selectedAdAccount?.accountId}
            key={adAccount.accountId}
            onClick={() => setSelectedAdAccount(adAccount)}
            adAccount={adAccount}
          />
        )}
        renderEmpty={() => (
          <NoAdsFoundInSearch>
            <Text variant="body1">
              {isLoading
                ? 'Fetching ad accounts...'
                : 'No ad accounts found. Try a different search.'}
            </Text>
          </NoAdsFoundInSearch>
        )}
      />
    </div>
  );

  if (noAds) content = <NoAds />;

  return (
    <Container>
      {isError && renderBanner('error')}
      {noAds && renderBanner('noAds')}
      <Text variant="h5"> Select Meta ad account </Text>
      <SpaceDivider />
      <Text variant="body1"> Choose the ad account you would like to use with Proxima </Text>
      <SpaceDivider y={8} />
      {content}
      <AdSetupFooter
        redirectTo={redirectTo}
        brandId={brandId}
        isLoading={isLoading}
        isSaving={isSaving}
        selectedAdAccount={selectedAdAccount}
        saveAdAccount={saveAdAccount}
        refreshAdAccounts={refreshAdAccounts}
      />
    </Container>
  );
};
export default AdAccountSelectionView;
