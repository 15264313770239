import { useBrandStore, useUserStore } from 'stores';
import { useBackgroundConditions, useProximaSDK } from 'hooks';
import { useEffect, useState, useRef } from 'react';
import { Brand } from 'types/stores/brand-store';

/* there's a delay in events before they get to dynamo, this simulates the wait time until it's there */
const WAIT_TIME_UNTIL_SEGMENT_UPDATES_AWS_IN_SECS = 3;

const useBrandHistory = () => {
  const timeoutRef = useRef<number>(-1);
  const { user } = useUserStore();
  const { brand } = useBrandStore();
  const [loading, setLoading] = useState(true);
  const usersApi = useProximaSDK('UsersApi', { brandId: brand?.brandId });
  const [brandsHistory, setBrandsHistory] = useState<Brand[]>([]);
  const { skipNavbar } = useBackgroundConditions();

  const skipFetch = !brand.brandId || skipNavbar;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await usersApi.getBrandHistory({ userId: user!.userId });
        setBrandsHistory(response.data as Brand[]);
      } catch (error) {
        /* do nothing */
      }
      setLoading(false);
    };

    if (!skipFetch) {
      setLoading(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(
        fetchUserData,
        WAIT_TIME_UNTIL_SEGMENT_UPDATES_AWS_IN_SECS * 1000
      );
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [skipFetch]);

  return { loading, brandsHistory };
};

export default useBrandHistory;
