import { AudiencesIcon } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { CreateNewAudiencesModalViewProps } from 'types/components/audiences';
import GenerateNewAudiences from './GenerateNewAudiences';
import GeneratedAudiencesStatus from './GeneratedAudiencesStatus';
import GenerateNewCampaign from '../CreateNewCampaignModal/GenerateNewCampaign';
import SkipCampaignModal from '../SkipCampaignModal';

const CreateNewAudiencesModalView: React.FC<CreateNewAudiencesModalViewProps> = (props) => {
  const {
    show,
    status,
    onCreateNewSeedAudiences,
    requestAdAccount,
    onCreateNewCampaign,
    onCampaignStatusChange,
    onStatusChange,
    audiencesCampaign,
    onFormSkipClick,
    showSkipCampaignModal,
    onSkipModalClose,
    title,
    subtitle,
    currentFeedbackStep,
    showAudienceStep,
    showCampaignStep,
    showFeedbackStep,
    onHandleClose,
    onSkipModal,
    campaignStatus,
  } = props;

  const showTitle = !showFeedbackStep ? title : undefined;
  const showIcon = !showFeedbackStep ? <AudiencesIcon /> : undefined;
  return (
    <>
      <ModalBase
        header={{ padding: 'small' }}
        open={show}
        title={showTitle}
        icon={showIcon}
        onClose={onHandleClose}
        subtitle={subtitle}
      >
        {showAudienceStep && (
          <GenerateNewAudiences
            requestAdAccount={requestAdAccount}
            onCreateNewSeedAudiences={onCreateNewSeedAudiences}
            onStatusChange={onStatusChange}
          />
        )}
        {showFeedbackStep && (
          <GeneratedAudiencesStatus
            onClose={onHandleClose}
            campaignStatus={campaignStatus}
            status={status}
            stepType={currentFeedbackStep}
          />
        )}
        {showCampaignStep && (
          <GenerateNewCampaign
            onCreateNewCampaign={onCreateNewCampaign}
            audiencesCampaign={audiencesCampaign!}
            onStatusChange={onCampaignStatusChange}
            onFormSkipClick={onFormSkipClick}
          />
        )}
      </ModalBase>

      <SkipCampaignModal
        onSkipModal={onSkipModal}
        showSkipCampaignModal={showSkipCampaignModal}
        onSkipModalClose={onSkipModalClose}
      />
    </>
  );
};

export default CreateNewAudiencesModalView;
