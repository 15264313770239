import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  border: 1px solid ${colors.neutral20};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 16px 32px 16px;
  width: 100%;
`;

const Header = styled.div`
  & > span {
    display: block;
    padding-bottom: 8px;
    width: 100%;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.neutral10};
`;

const TextWrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: row;
  gap: 120px;

  & > span:first-of-type {
    width: 120px;
  }
`;

interface IBrandInfoProps {
  brandName: string;
  brandWebsite: string;
}

const BrandInfoView = ({ brandName, brandWebsite }: IBrandInfoProps) => (
  <Container>
    <Header>
      <Text variant="body1Semibold">Brand info</Text>
      <Divider />
    </Header>
    <TextWrapper>
      <Text color="neutral60" variant="body2Semibold">
        Brand name
      </Text>
      <Text variant="body2">{brandName}</Text>
    </TextWrapper>
    <TextWrapper>
      <Text color="neutral60" variant="body2Semibold">
        Brand URL
      </Text>
      <Text variant="tag2Medium">{brandWebsite}</Text>
    </TextWrapper>
  </Container>
);

export default BrandInfoView;
