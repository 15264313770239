import styled from '@emotion/styled';
import { ProximaLogoWithTextBlack, colors } from '@innovationdepartment/proxima-ui';

type NavBarProps = {
  backgroundColor?: string;
};

type LogoNavbarProps = NavBarProps;

const Navbar = styled.div<NavBarProps>`
  padding: 48px 72px 0;
  align-self: stretch;
  text-align: center;
  & path {
    fill: ${colors.neutral100};
  }
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'inherit')};
`;

const LogoNavbar = (props: LogoNavbarProps) => (
  <Navbar {...props}>
    <ProximaLogoWithTextBlack />
  </Navbar>
);

export default LogoNavbar;
