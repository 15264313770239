import { useAuth0 } from '@auth0/auth0-react';

import SetupView from './Setup.View';
import { useShowSpinner } from 'hooks';

const Setup = () => {
  const { isLoading } = useAuth0();

  useShowSpinner({ show: isLoading });

  if (isLoading) return null;

  return <SetupView />;
};

export default Setup;
