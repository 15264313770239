import { useAuth0 } from '@auth0/auth0-react';
import Auth0ProviderWrapper from 'components/App/Auth0Provider';

type AuthProps =
  | { action?: never; isLogout: true }
  | {
      action: 'sign-up' | 'login';
      isLogout?: never;
    };

const AuthRedirect = ({ action, isLogout }: AuthProps) => {
  const { loginWithRedirect, logout } = useAuth0();

  if (isLogout) {
    logout({ returnTo: window.location.origin });
  } else {
    loginWithRedirect({ action, prompt: 'login' });
  }

  return <></>;
};

/**
 *
 * @param action { 'sign-up' | 'login' } determines the action to take when redirecting to the Auth0 login page
 * @param isLogout { boolean } determines if the user is logging out
 */
const WrappedAuthRedirect = (props: AuthProps) => (
  <Auth0ProviderWrapper>
    <AuthRedirect {...props} />
  </Auth0ProviderWrapper>
);

export default WrappedAuthRedirect;
