import { Text, colors } from '@innovationdepartment/proxima-ui';
import IncludedSection from './Layout/IncludedSection.View';
import styled from '@emotion/styled';
import PlanTypeBox from './Layout/PlanTypeBox.View';

type ProPlanViewProps = {
  onSubmit: () => void;
};

const BillingFrequencyContainer = styled.div`
  border: 1px solid ${colors.neutral10};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;

  h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const options = [
  'Preferred pricing',
  'Custom audiences',
  'Audience Insights',
  'Beta features',
  'White-glove support',
];

const ProPlanView = ({ onSubmit }: ProPlanViewProps) => (
  <BillingFrequencyContainer>
    <PlanTypeBox text="Pro" />
    <Text variant="h4">Custom pricing</Text>
    <IncludedSection onSubmit={onSubmit} buttonText="Contact our team" options={options} />
  </BillingFrequencyContainer>
);

export default ProPlanView;
