import { Text } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';

type PlanTypeBoxProps = {
  text: string;
};

const PlanTypeBoxWrapper = styled.div`
  position: relative;
  width: 80px;
  padding: 4px 8px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 2px;
    background: linear-gradient(45deg, #58ebc3, #5dade9);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  p {
    background: linear-gradient(45deg, #58ebc3, #5dade9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const PlanTypeBox = ({ text }: PlanTypeBoxProps) => (
  <PlanTypeBoxWrapper>
    <Text variant="body1">{text}</Text>
  </PlanTypeBoxWrapper>
);

export default PlanTypeBox;
