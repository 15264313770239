import styled from '@emotion/styled';
import { Card, Text, SpaceDivider, Button } from '@innovationdepartment/proxima-ui';
import { EmailVerifyProps } from './types';

const LogoutWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 24px 72px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const EmailVerifyView = ({ onSendEmailAgainClick, email, onLogout }: EmailVerifyProps) => (
  <Card>
    <LogoutWrapper>
      <Button variant="outlined" label="Log out" onClick={onLogout} />
    </LogoutWrapper>
    <Text variant="h4">Verify your email</Text>
    <SpaceDivider y={4} />
    <Text variant="body1">
      We just sent a verification email to&nbsp;
      <strong>{email}</strong>. Follow the link in that email to continue.
    </Text>
    <ButtonWrapper>
      <Button variant="text" label="Send again" color="secondary" onClick={onSendEmailAgainClick} />
    </ButtonWrapper>
  </Card>
);

export default EmailVerifyView;
