import { Outlet } from 'react-router-dom';
import LogoNavbar from 'ui/SetupLayout/LogoNavbar';

const AuthView = () => (
  <>
    <LogoNavbar />
    <Outlet />
  </>
);
export default AuthView;
