import { Form, SelectProps } from '@innovationdepartment/proxima-ui';
import { useState } from 'react';
import ModalBase from 'ui/ModalBase';
import EditBrandProfileModalView from './EditBrandProfileModal.View';
import { FlavorCategory } from 'types/components/audiences';

type EditBrandProfileModalProps = {
  open: boolean;
  currentFlavorCategory: FlavorCategory;
  flavorCategoryOptions: SelectProps['options'];
  onClose: () => void;
  onUpdateBrandCategory: (category: FlavorCategory) => void;
};

const EditBrandProfileModal = (props: EditBrandProfileModalProps) => {
  const { open, currentFlavorCategory, flavorCategoryOptions, onClose, onUpdateBrandCategory } =
    props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formData: { category: string }) => {
    const { category } = formData;

    const flavorCategory = flavorCategoryOptions?.find(
      (flavorCategoryOption) => flavorCategoryOption.value === category
    );
    const mappedFlavorCategory = {
      categoryId: flavorCategory!.value,
      categoryName: flavorCategory!.label,
      id: flavorCategory!.value,
      name: flavorCategory!.label,
    };

    setIsSubmitting(true);
    await onUpdateBrandCategory(mappedFlavorCategory!);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ModalBase open={open} title="Edit brand profile" onClose={onClose} allowCloseOnClickOutside>
      <Form onSubmit={handleSubmit} initialValues={{ category: currentFlavorCategory?.id }}>
        <EditBrandProfileModalView
          isSubmitting={isSubmitting}
          onClose={onClose}
          flavorCategoryOptions={flavorCategoryOptions}
        />
      </Form>
    </ModalBase>
  );
};

export default EditBrandProfileModal;
