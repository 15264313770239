import type { UserBrandAccess, PartialProfile } from '@innovationdepartment/proxima-sdk-axios';
import { Integration, IntegrationStatus } from 'types/stores/integration-store';
import { formatDateFromIso, formatUserFullName } from 'utils';

const USER_ROLES: { [key: string]: string } = {
  admin: 'Admin',
  readOnly: 'Read only',
};

type User = {
  isCurrentUser: boolean;
  isFBOwner: boolean;
  isFBPreviousOwner: boolean;
  menuProps: {
    disabled: boolean;
    setLoading: (loading: boolean) => void;
    fetchBrandUsers: () => Promise<void>;
    showToaster: (message: string, variant: string) => void;
  };
} & UserBrandAccess &
  PartialProfile;

export const formatUserData = (user: User) => {
  const {
    userId,
    email,
    firstName,
    lastName,
    roleId,
    lastLoginAt,
    isFBOwner,
    isFBPreviousOwner,
    menuProps,
    isCurrentUser,
  } = user;
  const role = USER_ROLES[roleId];

  const getMetaOwnership = () => {
    if (isFBOwner) return ' (Meta Owner)';
    if (isFBPreviousOwner) return ' (Previous Meta Owner)';
    return '';
  };

  const formattedUser = {
    userId,
    name: formatUserFullName({ firstName, lastName }),
    email: email || '[pending]',
    role: `${role}${getMetaOwnership()}`,
    status: lastLoginAt ? 'Active' : 'Invited',
    lastLoginAt: lastLoginAt ? formatDateFromIso(lastLoginAt) : '-',
    // menuProps allow us to pass functions to userRowRender to handle user actions
    // from inside Users.ActionMenu.tsx
    menu: userId ? { ...menuProps, disabled: isCurrentUser } : null,
  };

  return formattedUser;
};

export const getCurrentAndPreviousMetaOwner = (
  integrations: Integration[],
  users: PartialProfile[]
): [string?, string?] => {
  const userIds = users.map((user) => user.userId);
  const integrationMap = new Map<string, Integration>();
  integrations.forEach((integration) => {
    const addIntoMap =
      /* integration was previously added by current users */
      userIds.includes(integration.createdByUserId) &&
      /* integration is not in the map */
      !integrationMap.has(integration.createdByUserId);

    if (addIntoMap) {
      integrationMap.set(integration.createdByUserId!, integration);
    }
  });

  const [currentIntegration, previousIntegration] = integrationMap.values();

  let fbOwner = currentIntegration?.createdByUserId ?? '';
  let fbPreviousOwner = previousIntegration?.createdByUserId ?? '';

  const isConnected = currentIntegration?.status === IntegrationStatus.Active;

  if (!isConnected) {
    fbPreviousOwner = fbOwner;
    fbOwner = '';
  }

  return [fbOwner, fbPreviousOwner];
};
