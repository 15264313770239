import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import AudiencesGettingStartedLinks from 'ui/AudiencesGettingStartedLinks';
import ContentSection from 'ui/ContentSection';

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const AudiencesMoreInfo = () => (
  <ContentSection>
    <ContentWrapper>
      <Text variant="body1Semibold" color="neutral100">
        Get more from your prospecting spend with predictive AI
      </Text>
      <Text variant="body2" color="neutral100">
        Reach high-intent shoppers with a set of AI audiences powered by purchase activity across
        the eCom ecosystem. Simply select the lookalike size and we’ll build five audiences for
        prospecting, delivered right to your ad account.
      </Text>
      <Text variant="body2" color="neutral100">
        Underlying shopper data is analyzed and refreshed weekly. Return here to generate fresh
        audiences.
      </Text>
      <AudiencesGettingStartedLinks />
    </ContentWrapper>
  </ContentSection>
);

export default AudiencesMoreInfo;
