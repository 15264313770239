import { IntegrationType } from 'constants/integrations';
import { useEffect, useState } from 'react';
import { useAudiencesApi } from 'api';
import { FlavorCategory } from 'types/components/audiences';
import { useIntegrations } from 'hooks';
import LookalikeSourcesDataSourceInfoView from './LookalikeSourcesDataSourceInfo.View';

const LookalikeSourcesDataSourceInfo = () => {
  const { loading, getBrandFlavorCategory } = useAudiencesApi();
  const { getActiveIntegrations } = useIntegrations();

  const [shopifyIntegration] = getActiveIntegrations(IntegrationType.Shopify);
  const shop = shopifyIntegration?.metaData?.shop?.replace('.myshopify.com', '');

  const [flavorCategory, setFlavorCategory] = useState<FlavorCategory>();

  useEffect(() => {
    const fetchBrandAudienceSettings = async () => {
      const flavor = await getBrandFlavorCategory();
      if (!flavor) return;

      setFlavorCategory(flavor);
    };

    fetchBrandAudienceSettings();
  }, []);

  return (
    <LookalikeSourcesDataSourceInfoView
      shopName={shop}
      flavorCategory={flavorCategory}
      loading={loading}
    />
  );
};

export default LookalikeSourcesDataSourceInfo;
