import { Table } from '@innovationdepartment/proxima-ui';
import { LookalikeAudiencesTableViewProps } from 'types/components/audiences';

const LookalikeAudiencesTableView = (
  props: Omit<LookalikeAudiencesTableViewProps, 'showCreateLookalikeModal'>
) => {
  const { config, lookalikeAudiences } = props;

  return <Table data={lookalikeAudiences} config={config} />;
};

export default LookalikeAudiencesTableView;
