import { Navigate, Route, Routes } from 'react-router-dom';

import Email from './Email.Container';
import Verify from './Verify';

const EmailRouter = () => (
  <Routes>
    <Route element={<Email />}>
      <Route path="verify" element={<Verify />} />
      <Route index element={<Navigate to="verify" />} />
    </Route>
  </Routes>
);

export default EmailRouter;
