import styled from '@emotion/styled';
import { ButtonBase, Text, colors } from '@innovationdepartment/proxima-ui';
import { ChooseBrandForShopifyBrandItemProps } from 'types/components/integrations';

const ChooseBrandForShopifyBrandItemWrapper = styled(ButtonBase)<{ selected: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 8px;
  background-color: ${({ selected }) => (selected ? colors.neutral0 : 'transparent')};
`;

const ChooseBrandForShopifyBrandItem = (props: ChooseBrandForShopifyBrandItemProps) => {
  const { brand, onClick, selected } = props;

  const onItemClick = () => {
    onClick(brand.brandId);
  };

  return (
    <ChooseBrandForShopifyBrandItemWrapper disableRipple onClick={onItemClick} selected={selected}>
      <Text variant="body2" color="neutral100">
        {brand.name}
      </Text>
    </ChooseBrandForShopifyBrandItemWrapper>
  );
};

export default ChooseBrandForShopifyBrandItem;
